import { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
} from '@ezetech/swag-space-x';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { ICustomerInventorySignContractPopupProps } from 'interfaces/popup.interface';
import { useGenerateInventorySignContractLinkMutation } from 'redux/api/user.api';
import { clipboardLinkSelector } from 'redux/selectors/invoices.selector';
import { storeTermsOfServiceHTMLSelector } from 'redux/selectors/settings.selectors';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';
import { usePrevious } from 'hooks/use-previous.hook';
import { useClipboard } from 'hooks/use-clipboard.hook';

import css from './customer-inventory-sign-contract.popup.module.scss';

export const CustomerInventorySignContractPopup = ({
  invoiceId,
  isOpen,
}: ICustomerInventorySignContractPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { copy } = useClipboard();
  const clipboardLink = useAppSelector(clipboardLinkSelector);
  const termsOfServiceHTML = useAppSelector(storeTermsOfServiceHTMLSelector);
  const prevClipboardLink = usePrevious({
    value: clipboardLink,
    shouldUpdate: true,
    initValue: null,
  });
  const [generateInventorySignContractLink, { isLoading }] =
    useGenerateInventorySignContractLinkMutation();

  const [isCopied, setCopied] = useState(false);

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  const onRegularBtnClick = () => {
    generateInventorySignContractLink({ invoiceId: invoiceId || '' });
  };

  const copyOnLinkArrivedEvent = useCallback(async () => {
    if (clipboardLink && prevClipboardLink !== clipboardLink) {
      const response = await copy(clipboardLink);
      setCopied(!!response);
    }
  }, [clipboardLink]);

  useEffect(() => {
    if (isOpen) {
      copyOnLinkArrivedEvent();
    }
  }, [copyOnLinkArrivedEvent]);

  useEffect(() => {
    if (isCopied && isOpen) {
      dispatch(
        pushNotification(makeSuccessToastNotification('Link copied to clipboard')),
      );

      dispatch(closePopup());
    }
  }, [isCopied, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Inventory Terms of Service
        </DialogTitle>
        <DialogDescription className={css.contentWrapper}>
          <div dangerouslySetInnerHTML={{ __html: termsOfServiceHTML || '' }} />
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onBtnCloseClick}>
            Close
          </SwagButton>
          <SwagButton type="primary" onClick={onRegularBtnClick} loading={isLoading}>
            Copy Contract Link
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
