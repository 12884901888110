import { MultiValue } from 'react-select';
import { OptionType } from '@ezetech/swag-space-x';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath } from 'interfaces';
import {
  ICBSListResponse,
  ICuratedBrandSite,
  IUpdateBrandSiteStatus,
} from 'interfaces/curated-brand-site.interface';
import { IGetPresentationsRequest as IGetRequest } from 'interfaces/presentations.interface';
import { closePopup } from 'redux/slices/modals.slice';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { CuratedBrandStoreStatus } from 'constants/curated-brand-store';
import {
  setList,
  setLogoUrl,
  setStatusToBrandSite,
} from 'redux/slices/curated-brand-sites.slice';
import { setFilter } from 'redux/slices/filter.slice';
import { setMyOrdersCount } from 'redux/slices/carts.slice';
import { baseQueryParams } from './helpers';

enum BrandSiteTags {
  BrandSite = 'BrandSite',
  BrandSites = 'BrandSites',
}

export const curatedBrandSitesApi = createApi({
  reducerPath: ReducerPath.curatedBrandSites,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  tagTypes: Object.values(BrandSiteTags),
  endpoints: (builder) => ({
    getBrandSites: builder.query<ICBSListResponse, IGetRequest>({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if (
            value === CuratedBrandStoreStatus.ACTIVE ||
            value === CuratedBrandStoreStatus.DRAFT
          ) {
            filter.status = [...(filter.status || []), value] || [];
          }
        });

        return {
          url: '/curated-brand-store',
          method: 'GET',
          params: {
            ...rest,
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: [BrandSiteTags.BrandSites],
      async onQueryStarted(
        { dropdown, search, sorting, sortingOrder },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const { list, total, page, perPage } = data;

            dispatch(setList({ list, total }));
            dispatch(setMyOrdersCount({ curatedBrandStores: total }));
            dispatch(
              setFilter({
                page,
                perPage,
                dropdown,
                search: search || '',
                sorting,
                sortingOrder,
                totalCount: total,
              }),
            );
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    getBrandSite: builder.query<ICuratedBrandSite, string>({
      query: (id: string) => ({ url: `/curated-brand-store/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: BrandSiteTags.BrandSite, id }],
    }),
    updateBrandSite: builder.mutation<
      void,
      { id: string; data: Partial<ICuratedBrandSite> }
    >({
      query: ({ id, data }) => ({
        url: `/curated-brand-store/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        BrandSiteTags.BrandSites,
        { type: BrandSiteTags.BrandSite, id },
      ],
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Brand site has been successfully updated'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    deleteBrandSite: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/curated-brand-store/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        BrandSiteTags.BrandSites,
        { type: BrandSiteTags.BrandSite, id },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(closePopup());
          dispatch(
            pushNotification(
              makeSuccessToastNotification('Brand site has been successfully deleted'),
            ),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    updateBrandSiteStatus: builder.mutation<void, IUpdateBrandSiteStatus>({
      query: ({ id, ...rest }) => ({
        url: `/curated-brand-store/${id}`,
        method: 'PATCH',
        body: { ...rest },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setStatusToBrandSite(args));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
    uploadLogo: builder.mutation<Record<string, Record<string, string>>, FormData>({
      query: (data) => ({
        url: '/company-settings/logo',
        method: 'POST',
        body: data,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.logo) {
            dispatch(setLogoUrl({ logo: data.logo, favicon: data.favicon }));
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useLazyGetBrandSitesQuery,
  useGetBrandSiteQuery,
  useUpdateBrandSiteMutation,
  useDeleteBrandSiteMutation,
  useUpdateBrandSiteStatusMutation,
  useUploadLogoMutation,
} = curatedBrandSitesApi;
