import { ICell } from '@ezetech/swag-space-x';
import { IFilterRequest } from 'interfaces/filters.interface';
import { IProductionMockup } from 'interfaces/orders.interface';

export enum CustomerCompanyStatisticFields {
  avgOrderValue = 'avgOrderValue',
  lastOrderAt = 'lastOrderAt',
  totalSpent = 'totalSpent',
  totalOrders = 'totalOrders',
}

export type CustomerCompanyStatisticFieldsType = {
  [K in keyof typeof CustomerCompanyStatisticFields]: string | number;
};

export interface ICustomerCompanyStatisticLabel {
  id: CustomerCompanyStatisticFields;
  label: string;
}

export interface ICustomerCompanyProfileResponse {
  statistic: CustomerCompanyStatisticFieldsType;
  name: string;
  id: string;
}

export interface ICustomerCompanyRequest {
  id: string;
}

export interface ICustomerCompanyOrdersRequest extends ICustomerCompanyRequest {
  page: IFilterRequest['page'];
  perPage: IFilterRequest['perPage'];
}

export interface ICustomerCompanyUsersRequest extends ICustomerCompanyOrdersRequest {}

export interface ICustomerCompanyOrderItemVariant {
  color: string;
  name: string;
  quantities: number[];
}

export interface ICustomerCompanyOrderItem {
  id: string;
  name: string;
  total: number;
  quantity: number;
  status: string;
  statusCode: string;
  num: string;
  letterIndicator: string;
  itemTotalPrice: number;
  variants: ICustomerCompanyOrderItemVariant[];
  sizes?: string | null;
  boxItemsId: string;
  pricePerItem: number;
  productionTime: string;
  productPublicId: string;
  productionMockups: IProductionMockup[];
  estInHand: {
    from: string;
    to: string;
  };
}
export interface ICustomerCompanyOrder {
  id: string;
  paidAt: string;
  num: string;
  grandTotal: number;
  user: {
    isSpecificReviewMockupsByCustomerAllowed: boolean | null;
    isSpecificReviewPantonesByCustomerAllowed: boolean | null;
  };
  curatedBrandStoreId: string;
  item: ICustomerCompanyOrderItem;
}

export interface ICustomerCompanyOrdersList {
  list: Array<ICustomerCompanyOrder>;
  listInfinity: ICustomerCompanyOrder[];
  total: number;
  page: number;
  perPage: number;
}
export interface ICustomerCompanyUser {
  id: string;
  firstName: string;
  lastName: string;
  orders: number;
  totalSpent: number;
  deletedAt: Date | null;
  lastOrderAt: Date | null;
}

export interface ICustomerCompanyUsersList {
  list: ICustomerCompanyUser[];
  listInfinity: ICustomerCompanyUser[];
  perPage: number;
  page: number;
  total: number;
}

export interface ICustomerCompanyProfileState {
  statistic: CustomerCompanyStatisticFieldsType;
  name: string;
  users: ICustomerCompanyUsersList;
  orders: ICustomerCompanyOrdersList;
}
export interface IUsersTransformCell extends ICell {
  id: UsersFields;
}
export interface IUsersTransformRow {
  cells: IUsersTransformCell[];
  index: number;
  key: string;
}

export enum UsersFields {
  userName = 'userName',
  orders = 'orders',
  totalSpent = 'totalSpent',
  lastOrderAt = 'lastOrderAt',
}

export enum OrderFields {
  number = 'number',
  products = 'products',
  status = 'status',
  paidAt = 'paidAt',
  total = 'total',
}

export interface IOrdersTransformCell extends ICell {
  id: OrderFields;
  value: string | ICustomerCompanyOrder['item'];
  orderId?: string;
  itemId?: string;
  isReviewPantonesByCustomerAllowed?: boolean;
  isReviewMockupsByCustomerAllowed?: boolean;
}
export interface IOrdersTransformRow {
  cells: IOrdersTransformCell[];
  index: number;
  key: string;
  order: ICustomerCompanyOrder;
}
