import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogFooter,
  SwagButton,
  DialogDescription,
} from '@ezetech/swag-space-x';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup } from 'redux/slices/modals.slice';
import { selectedCartProductsSelector } from 'redux/selectors/carts.selector';
import { CartProductsPreviewPopupProps } from 'interfaces/popup.interface';
import { selectedPresentationProductsSelector } from 'redux/selectors/presentations.selector';
import { selectedBrandSiteProductsSelector } from 'redux/selectors/curated-brand-sites.selector';
import { API_URL } from 'redux/api/helpers';
import { ProductPreviewItem } from './product-preview-item';
import css from './cart-products-preview-popup.module.scss';

export const CartProductsPreviewPopup = ({
  cartId,
  presentationId,
  brandSiteId,
  isOpen,
}: CartProductsPreviewPopupProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  let products;
  switch (true) {
    case !!presentationId:
      products = useAppSelector(selectedPresentationProductsSelector(presentationId));
      break;
    case !!brandSiteId:
      products = useAppSelector(selectedBrandSiteProductsSelector(brandSiteId));
      break;
    default:
      products = useAppSelector(selectedCartProductsSelector(cartId));
  }

  const handleProductsUpdateClick = () => {
    setIsLoading(true);
    document.location.replace(`${API_URL}/curated-brand-store/${brandSiteId}/products`);
  };

  const onButtonClick = () => {
    if (isLoading) {
      return;
    }
    dispatch(closePopup());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onButtonClick}>
      <DialogContentWrapper
        className={css.dialogContent}
        overlayClassName={css.dialogOverlay}
      >
        <DialogTitle>Products</DialogTitle>
        <DialogDescription className={css.body}>
          <div className={css.row}>
            {products.map(
              ({ id, name, quantity: productQuantity, variants, image, fullName }, i) => (
                <ProductPreviewItem
                  key={`${id}-${i}`}
                  id={id}
                  name={name}
                  quantity={productQuantity}
                  variants={variants}
                  imageUrl={brandSiteId ? image : null}
                  showVariants={!brandSiteId}
                  fullName={fullName}
                />
              ),
            )}
          </div>
        </DialogDescription>
        <DialogFooter className={css.footer}>
          <SwagButton type={brandSiteId ? 'outlined' : 'primary'} onClick={onButtonClick}>
            Close
          </SwagButton>
          {brandSiteId && (
            <SwagButton
              loading={isLoading}
              type={'primary'}
              onClick={handleProductsUpdateClick}
            >
              Edit Products
            </SwagButton>
          )}
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
