// import { pushNotification } from 'redux/slices/notifications.slice';
// import { makeToastNotification } from 'utils/query.util';
// import { store } from '../../redux/store';
import { getPublicSid } from 'utils/auth.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';

export async function authLoader(): Promise<null> {
  if (!getPublicSid()) {
    return null;
  }

  try {
    await fetchStoreSettingsData();
  } catch (e) {
    // store.dispatch(pushNotification(makeToastNotification(e)));
  }

  return null;
}
