import { CellSize, CellTextColor, CellTextWeight, ICell } from '@ezetech/swag-space-x';
import { CuratedBrandStoreStatus, RestrictionLevel } from 'constants/curated-brand-store';

interface ICuratedBrandStoreProduct {
  id: string;
  product: { name: string; logo: string };
  variants: { colorName: string; colorValue: string }[];
}

export interface ICBSLogo {
  url: string;
  key?: string;
  etag?: string;
}

export interface ICuratedBrandSite {
  id: string;
  name: string;
  email: string;
  phone?: string;
  logo: ICBSLogo;
  primaryColor: string;
  secondaryColor: string;
  status: CuratedBrandStoreStatus;
  link?: string;
  restrictionLevel: RestrictionLevel;
  allowedDomains?: string[];
  updatedAt: Date;
  createdAt: Date;
  products: ICuratedBrandStoreProduct[];
}

export interface ICBSListResponse {
  list: ICuratedBrandSite[];
  total: number;
  page: number;
  perPage: number;
}

export interface IUpdateBrandSiteStatus {
  id: string;
  status: CuratedBrandStoreStatus;
}

export const CBS_FILTER_OPTIONS = [
  { label: 'Status: Active', value: CuratedBrandStoreStatus.ACTIVE },
  { label: 'Status: Draft', value: CuratedBrandStoreStatus.DRAFT },
];

export const STATUS_LABEL = {
  [CuratedBrandStoreStatus.ACTIVE]: 'Active',
  [CuratedBrandStoreStatus.DRAFT]: 'Draft',
};

export enum CBSFields {
  customerEmail = 'customerEmail',
  company = 'company',
  products = 'products',
  createdAt = 'createdAt',
  link = 'link',
  openClosed = 'openClosed',
  manage = 'manage',
  status = 'status',
}

export enum CBSActions {
  copy = 'copy',
  redirect = 'redirect',
}

export interface ITransformCell extends Omit<ICell, 'onClick' | 'id'> {
  id: CBSFields;
  link?: string;
  allowedDomains?: string[];
  customerEmail?: string;
  customerAddress?: string;
  isOpened?: boolean;
  status?: { value: string; label: string };
  products: ICuratedBrandSite['products'];
}

export const CBS_TABLE_HEADER = [
  { text: 'Company', id: CBSFields.company, size: CellSize.medium },
  { text: 'Customer', id: CBSFields.customerEmail, size: CellSize.large },
  { text: 'Products', id: CBSFields.products, size: CellSize.medium },
  {
    text: 'Created',
    id: CBSFields.createdAt,
    size: CellSize.medium,
    withSorting: true,
  },
  { text: 'Link', id: CBSFields.link, size: CellSize.medium },
  {
    text: 'Access',
    id: CBSFields.openClosed,
    size: CellSize.medium,
  },
  { text: 'Manage', id: CBSFields.manage, size: CellSize.extraLarge },
  { text: 'Status', id: CBSFields.status, size: CellSize.extraLarge },
];

export const CBS_TABLE_ROW = {
  [CBSFields.company]: {
    size: CellSize.medium,
    textColor: CellTextColor.primary,
  },
  [CBSFields.customerEmail]: {
    size: CellSize.large,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: CBSActions.copy,
    actionable: true,
  },
  [CBSFields.products]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [CBSFields.createdAt]: { size: CellSize.medium },
  [CBSFields.link]: {
    size: CellSize.medium,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [CBSFields.openClosed]: {
    size: CellSize.medium,
  },
  [CBSFields.manage]: { size: CellSize.extraLarge },
  [CBSFields.status]: { size: CellSize.extraLarge },
};
