import { cellPhoneRegexp } from 'constants/validation';

export enum FormField {
  CompanyName = 'name',
  Email = 'email',
  Phone = 'phone',
  Status = 'status',
  RestrictionLevel = 'restrictionLevel',
  AllowedDomains = 'allowedDomains',
  Logo = 'logo',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
  StoreLink = 'link',
}

export const editCBSFormConfig = {
  [FormField.CompanyName]: {
    name: FormField.CompanyName,
    label: 'Company Name',
    placeholder: 'Enter Company Name',
    dataField: 'name',
    rules: {
      required: 'Company name is required',
      maxLength: {
        value: 50,
        message: 'Maximum 50 symbols',
      },
      minLength: {
        value: 2,
        message: 'Minimum 2 symbols',
      },
    },
  },
  [FormField.Email]: {
    name: FormField.Email,
    label: 'Point of contact email',
    dataField: 'email',
    placeholder: 'Start typing email address',
    disabled: true,
  },
  [FormField.Phone]: {
    name: FormField.Phone,
    label: 'Point of contact cell phone',
    dataField: 'phone',
    placeholder: 'Enter Cell Phone',
    rules: {
      maxLength: {
        value: 30,
        message: 'Maximum 30 symbols',
      },
      pattern: {
        value: cellPhoneRegexp,
        message: 'Invalid phone number',
      },
    },
  },
};
