import { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  Checkbox,
  Loader,
  MultiOptionType,
  OptionType,
  Pagination,
  SwagButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Typography,
  WhiteBox,
  Divider,
  TooltipPlacement,
  Tooltip,
  Select,
} from '@ezetech/swag-space-x';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { TableToolbar } from 'components/table-toolbar';
import { useAppDispatch, useAppSelector } from 'redux/store';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { EmptyState } from 'components/empty-state';
import { useClipboard } from 'hooks/use-clipboard.hook';
import {
  getFormattedSentDate,
  isPaginationPresentationsVisibleSelector,
  presentationsRowsSelector,
  presentationsHeaderSelector,
  presentationsFilterOptionsSelector,
} from 'redux/selectors/presentations.selector';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';

import { usePrevious } from 'hooks/use-previous.hook';
import {
  CART_PRODUCTS_PREVIEW_POPUP,
  CREATE_A_CART_TYPE_POPUP,
  CONFIRMATION_POPUP,
  CREATE_A_PRESENTATION_EMAIL_POPUP,
  INVOICE_ACH_NET_TERMS_INFORMATION_POPUP,
} from 'components/popups/_logic/popups-list';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  useGetPresentationsMutation,
  useMarkAsSentMutation,
  useDownloadPDFMutation,
  useDeletePresentationMutation,
} from 'redux/api/presentation.api';
import { API_URL } from 'redux/api/helpers';
import { resetFilters, setFilter } from 'redux/slices/filter.slice';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import { getProductsNumber } from 'redux/selectors/carts.selector';
import { presentationsAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { useAssignPresentationToUserMutation } from 'redux/api/assignee.api';
import { MAX_PRODUCTS_IN_TOOLTIP } from 'constants/carts.constant';
import { ProductsListTooltip } from 'components/products-list-tooltip';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import {
  ITransformPresentationCell,
  PresentationFields,
  IPDFCellAdditional,
} from 'interfaces/pdf-presentation';
import { ROUTES } from 'constants/router';
import trashIcon from '../../assets/svg/trash-v1.svg';
import editIcon from '../../assets/svg/edit.svg';
import downloadIcon from '../../assets/svg/download.svg';
import css from './pdf-presentation.module.scss';

export const PDFPresentationComponent = () => {
  const { copy } = useClipboard();
  const dispatch = useAppDispatch();
  const [getPresentations, { isLoading }] = useGetPresentationsMutation();
  const [markAsSent] = useMarkAsSentMutation();
  const [downloadPDF, { isLoading: isDownloadLoading }] = useDownloadPDFMutation();
  const [assignPresentationToUserRequest] = useAssignPresentationToUserMutation();
  const rows = useAppSelector(presentationsRowsSelector);
  const headerCells = useAppSelector(presentationsHeaderSelector);
  const presentationsFilterOptions = useAppSelector(presentationsFilterOptionsSelector);
  const filters = useAppSelector(filtersRequestSelector);
  const presentationsAssignToOptions = useAppSelector(
    presentationsAssignToOptionsSelector,
  );
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isPaginationVisible = useAppSelector(isPaginationPresentationsVisibleSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const [linkPresentationId, setPresentationId] = useState<string>('');
  const [isDisabledTooltip, setDisabled] = useState<boolean>(false);
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [deletePresentation, { isLoading: isLoadingDelete }] =
    useDeletePresentationMutation();

  useEffect(() => {
    if (location?.search?.includes('presentationId')) {
      navigate(ROUTES.PDF_PRESENTATION);
      setPresentationId(location.search.replace('?presentationId=', ''));
    }
  }, []);

  useEffect(() => {
    if (!linkPresentationId) {
      return;
    }
    downloadPDF({ presentationId: linkPresentationId });
  }, [linkPresentationId]);

  const editPdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.EDIT_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const deletePdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.DELETE_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const createPdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.CREATE_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const createCartLinkFromPdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.CREATE_CART_LINK_FROM_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );
  const createInvoiceFromPdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.CREATE_INVOICE_FROM_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );
  const downloadPdfAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.DOWNLOAD_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const markAsSentAccess = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.MARK_PDF_PRESENTATION_AS_SENT,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const viewInvoicesAccess = can(
    SUBJECTS.VIEW_INVOICE.actions.VIEW_INVOICE_VIEW,
    SUBJECTS.VIEW_INVOICE.value,
  );

  const viewCartsAccess = can(
    SUBJECTS.VIEW_BUILD_A_CART.actions.VIEW_BUILD_A_CART_VIEW,
    SUBJECTS.VIEW_BUILD_A_CART.value,
  );

  const isAbleToAssignPresentation = can(
    SUBJECTS.TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT.actions
      .ASSIGN_TEAM_MEMBER_TO_PDF_PRESENTATION,
    SUBJECTS.TEAM_MEMBER_PDF_PRESENTATION_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForPresentation = can(
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.ASSIGN_PDF_PRESENTATION,
    SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
  );

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const action = urlParams.get('action');
  const customerEmailParam = urlParams.get('customerEmail');
  const isActionSearchByCustomerEmail =
    action === ACTION_TOKEN_TYPES.GET_PRESENTATION_BY_EMAIL;

  useEffect(() => {
    if (isActionSearchByCustomerEmail) {
      dispatch(setFilter({ search: customerEmailParam ?? '' }));
      setSearchValue(customerEmailParam ?? '');
    }
  }, [isActionSearchByCustomerEmail]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getPresentations({ ...filters, search: searchValue, page: 1 });
    }
  }, [searchValue]);

  const handlePageClick = (selectedPage: number) => {
    getPresentations({ ...filters, page: selectedPage });
  };

  const handleSearchChange = (searchVal: string): void => {
    setSearchValue(searchVal);
  };

  const onCreateAPresentationClick = () => {
    if (!createPdfAccess) {
      return;
    }
    dispatch(openPopup({ popupName: CREATE_A_PRESENTATION_EMAIL_POPUP, popupProps: {} }));
  };

  const handleEditPresentationClick = (presentationId: string) => () => {
    if (!editPdfAccess) {
      return;
    }
    document.location.replace(`${API_URL}/presentation/edit/${presentationId}`);
  };

  const onDeletePresentationClick = (presentationId: string) => {
    if (!deletePdfAccess) {
      return;
    }
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          isConfirmLoading: isLoadingDelete,
          descriptionComponent: 'Are you sure you want to delete the presentation?',
          onConfirm: () => {
            deletePresentation({ presentationId });
          },
          onCancel: () => {
            dispatch(closePopup());
          },
        },
      }),
    );
  };
  const getIsActionableCell = (cell: ITransformPresentationCell): boolean => {
    const { text, id } = cell;

    if (id !== PresentationFields.products) {
      return cell.actionable || false;
    }

    return getProductsNumber(text) > 0;
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getPresentations({ ...filters, dropdown: selected, page: 1 });
  };

  const onMarkAsSentClick = (presentationId: string) => {
    markAsSent({ presentationId });
  };

  const getSortingDirection = (id: string): 'up' | 'down' => {
    const list = headerCells;
    const cell = list.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  const shouldShowTooltipAlways = (cell: ITransformPresentationCell): boolean => {
    const { id, text } = cell;

    return id === PresentationFields.products && getProductsNumber(text) > 0;
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    getPresentations({
      ...filters,
      page: 1,
      sorting: field,
      sortingOrder: newSortingOrder,
    });
  };

  const shouldSkipTooltip = (cell: ITransformPresentationCell): boolean => {
    return [
      PresentationFields.emailStatus,
      PresentationFields.manage,
      PresentationFields.downloadPDF,
      PresentationFields.createCart,
      PresentationFields.invoice,
      PresentationFields.assignTo,
    ].includes(cell.id);
  };
  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      dispatch(
        pushNotification(makeSuccessToastNotification(`${text} has been copied.`)),
      );
    }
  };

  const handleCellClick =
    (cell: ITransformPresentationCell & { isOrdered?: boolean }) =>
    async (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
      if (id === PresentationFields.email) {
        await copyText(cell.text, 'Email');
        return;
      } else if (id === PresentationFields.products) {
        const number = getProductsNumber(cell.text);

        if (number <= MAX_PRODUCTS_IN_TOOLTIP) {
          return;
        }
        setDisabled(true);

        dispatch(
          openPopup({
            popupName: CART_PRODUCTS_PREVIEW_POPUP,
            popupProps: { presentationId: cell.rowId },
          }),
        );
        setTimeout(() => setDisabled(false), 100);

        return;
      } else {
        return;
      }
    };

  const onGoToInvoiceClick = (customerEmail: string) => {
    if (!viewInvoicesAccess) {
      return;
    }
    dispatch(resetFilters());
    navigate(
      `${ROUTES.INVOICE_GENERATION}?customerEmail=${customerEmail}&action=${ACTION_TOKEN_TYPES.GET_INVOICE_BY_EMAIL}`,
    );
  };

  const onGoToCartLinkClick = (customerEmail: string) => {
    if (!viewCartsAccess) {
      return;
    }
    dispatch(resetFilters());
    navigate(
      `${ROUTES.BUILD_A_CART}?customerEmail=${customerEmail}&action=${ACTION_TOKEN_TYPES.GET_CART_BY_EMAIL}`,
    );
  };

  const onCreateInvoiceClick = (customerEmail: string, cartId: string) => {
    if (!createInvoiceFromPdfAccess) {
      return;
    }
    dispatch(
      openPopup({
        popupName: INVOICE_ACH_NET_TERMS_INFORMATION_POPUP,
        popupProps: { presentationEmail: customerEmail },
        popupSharedProps: { cartId },
      }),
    );
  };

  const onCreateCartLinkClick = (
    cell: ITransformPresentationCell & IPDFCellAdditional,
  ) => {
    if (cell.isOrderPlaced || !createCartLinkFromPdfAccess) {
      return;
    }

    dispatch(
      openPopup({
        popupName: CREATE_A_CART_TYPE_POPUP,
        popupProps: { email: cell.customerEmail || '', presentationId: cell.rowId },
      }),
    );
  };

  const onAssignmentSelect =
    (cell: ITransformPresentationCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignPresentationToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  const renderCellContent = (
    cell: ITransformPresentationCell & IPDFCellAdditional,
  ): string | JSX.Element => {
    if (cell.id === PresentationFields.downloadPDF) {
      if (cell.isDownloadPDFDisabled) {
        return <></>;
      }
      return (
        <div
          className={css.manageWrapper}
          onClick={() =>
            downloadPdfAccess ? downloadPDF({ presentationId: cell.rowId || '' }) : {}
          }
        >
          <span
            className={cn(css.trashIcon, css.downloadIcon, {
              [css.disabled]: !downloadPdfAccess,
            })}
          >
            <img src={downloadIcon} />
          </span>
        </div>
      );
    }
    if (cell.id === PresentationFields.emailStatus) {
      const isSent = Boolean(cell.sentAt);
      return (
        <div className={css.manageWrapper}>
          <Checkbox
            name="isSent"
            checked={Boolean(cell.sentAt)}
            disabled={!markAsSentAccess}
            onCheckedChange={() => {
              onMarkAsSentClick(cell.rowId || '');
            }}
          />
          <span
            className={cn(css.sentAt, {
              [css.disabled]: !markAsSentAccess,
            })}
          >
            {isSent
              ? `Sent ${getFormattedSentDate(new Date(cell.sentAt || ''))}`
              : 'Mark as sent'}
          </span>
        </div>
      );
    }
    if (cell.id === PresentationFields.manage) {
      if (cell.orderNum) {
        return <></>;
      }
      return (
        <div className={css.manageWrapper}>
          <>
            <div
              className={cn(css.trashIcon, {
                [css.disabled]: !editPdfAccess,
              })}
              data-label={`edit-button-${cell.rowId}`}
              onClick={handleEditPresentationClick(cell.rowId || '')}
            >
              <img src={editIcon} alt="edit" />
            </div>
            <div
              className={cn(css.trashIcon, {
                [css.disabled]: !deletePdfAccess,
              })}
              data-label={`delete-button-${cell.rowId}`}
              onClick={() => onDeletePresentationClick(cell.rowId || '')}
            >
              <img src={trashIcon} />
            </div>
          </>
        </div>
      );
    }

    if (cell.id === PresentationFields.invoice) {
      if (cell.hasInvoice) {
        return (
          <Typography
            color={COLORS.colorPink}
            fontType="bodyMd"
            onClick={() => onGoToInvoiceClick(cell.customerEmail || '')}
            className={cn(css.goToBtn, {
              [css.disabled]: !viewInvoicesAccess,
            })}
          >
            Go to
          </Typography>
        );
      }

      return (
        <div className={css.centerAligned}>
          <Tooltip
            disabled={!cell.isCreateInvoiceDisabled}
            content={
              <Typography
                color={
                  cell.isCreateInvoiceDisabled
                    ? COLORS.colorTextDisabled
                    : COLORS.colorPink
                }
                data-label="createInvoice"
                fontType="bodyMd"
                onClick={() =>
                  !cell.isCreateInvoiceDisabled &&
                  onCreateInvoiceClick(cell.customerEmail || '', cell.cartId || '')
                }
                className={cn(css.goToBtn, {
                  [css.disabled]: !createInvoiceFromPdfAccess,
                })}
              >
                Create
              </Typography>
            }
          >
            <Typography color={COLORS.colorTextSubdued}>
              {cell.isOrderPlaced
                ? 'Order is already placed.'
                : 'The Cart Link has already been created, please delete it to proceed with Invoice creation.'}
            </Typography>
          </Tooltip>
        </div>
      );
    }

    if (cell.id === PresentationFields.createCart) {
      if (cell.hasCart) {
        return (
          <Typography
            color={COLORS.colorPink}
            fontType="bodyMd"
            onClick={() => onGoToCartLinkClick(cell.customerEmail || '')}
            className={cn(css.goToBtn, {
              [css.disabled]: !viewCartsAccess,
            })}
          >
            Go to
          </Typography>
        );
      }

      return (
        <div className={css.manageWrapper}>
          <Tooltip
            disabled={!cell.isCreateCartLinkDisabled}
            triggerClassName={css.rightAligned}
            placement={TooltipPlacement.bottom}
            content={
              <SwagButton
                size="small"
                type="primary"
                dataLabel="createCartLink"
                className={css.createCartLinkButton}
                onClick={() => onCreateCartLinkClick(cell)}
                disabled={cell.isCreateCartLinkDisabled || !createCartLinkFromPdfAccess}
              >
                Create Cart Link
              </SwagButton>
            }
          >
            <Typography color={COLORS.colorTextSubdued}>
              {cell.isOrderPlaced
                ? 'Order is already placed.'
                : 'The Invoice has already been created, please delete it to proceed with Cart Link creation.'}
            </Typography>
          </Tooltip>
        </div>
      );
    }

    if (cell.id === PresentationFields.assignTo) {
      if (
        isAbleToAssignPresentation ||
        (isAbleToBeAssignedForPresentation && !cell.assignedToUser)
      ) {
        return (
          <div className={css.assignToWrapper}>
            <Select
              options={presentationsAssignToOptions}
              value={cell.assignedToUser}
              isSearchable={true}
              onChange={onAssignmentSelect(cell)}
              name="assignTo"
              skipError
              menuPlacement="auto"
              size="small"
              type="outlined"
              menuPosition="fixed"
              menuShouldBlockScroll
            />
          </div>
        );
      }

      return (
        <div className={css.assignToWrapper}>
          <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
            {cell.assignedToUser?.label}
          </Typography>
        </div>
      );
    }

    return cell.text;
  };

  const renderEmptyState = () => {
    const { dropdown } = filters;

    if (searchValue.length || !!(dropdown as MultiValue<OptionType>).length) {
      return (
        <div className={css.containerWrapper}>
          <EmptyState
            title="No results found"
            description="Try adjusting your search or filter to find what you are looking for."
          />
        </div>
      );
    }

    return (
      <div className={css.containerWrapperEmpty}>
        <EmptyState
          title="No presentations to display yet"
          description={createPdfAccess ? 'Make your first presentation.' : ''}
          button={createPdfAccess ? 'Create PDF Presentation' : ''}
          buttonClassName={css.emptyStateButton}
          onClick={onCreateAPresentationClick}
          isSmallerTitleForMobile={true}
        />
      </div>
    );
  };

  const renderNonDataComponents = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <div className={css.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    if (!rows.length) {
      return renderEmptyState();
    }

    return null;
  };

  const renderTooltipCellContent = (
    cell: ITransformPresentationCell,
  ): null | React.ReactNode => {
    const { id, text } = cell;

    if (id !== PresentationFields.products) {
      return null;
    }

    const number = getProductsNumber(text);

    if (number > MAX_PRODUCTS_IN_TOOLTIP) {
      return (
        <Typography
          fontType="bodySm"
          lineHeight="143%"
          color={COLORS.colorTypographyHeadings}
        >
          Click to view
        </Typography>
      );
    }

    return <ProductsListTooltip presentationId={cell.rowId} />;
  };

  return (
    <>
      {isDownloadLoading && (
        <div className={css.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={css.containerWrapper}>
        <TableToolbar
          onNextPageClick={handlePageClick}
          onFilterClick={handleFilterClick}
          onSearchChange={handleSearchChange}
          filterOptions={presentationsFilterOptions}
          isFilterMultiSelect
          exportTooltipText="Export orders list"
          className={css.toolbar}
        >
          {createPdfAccess && (
            <div className={css.pdfBtnWrapper}>
              <SwagButton
                type="primary"
                size="large"
                className={css.pdfBtn}
                onClick={onCreateAPresentationClick}
              >
                Create PDF Presentation
              </SwagButton>
              <Divider className={css.pdfBtnDivider} />
            </div>
          )}
        </TableToolbar>

        {rows.length ? (
          <>
            <WhiteBox className={css.root}>
              <Table className={css.tableWrapper}>
                <TableHeader>
                  {headerCells.map((cell, index) => (
                    <TableHeaderCell
                      id={cell.id}
                      dataLabel={cell.text}
                      className={cn({ [css.number]: index === 0 })}
                      size={cell.size}
                      key={`customers-header-${cell.id}`}
                      withSorting={cell.withSorting}
                      iconDirection={getSortingDirection(cell.id)}
                      onClick={cell.withSorting ? handleSortingClick : undefined}
                    >
                      {cell.text}
                    </TableHeaderCell>
                  ))}
                </TableHeader>
                <TableBody>
                  {rows.map((row, position) => (
                    <TableRow
                      key={`customers-${row.index}`}
                      primaryDataLabel={row.cells[0].rowId}
                    >
                      {row.cells.map((cell, index) => (
                        <TableRowCell
                          className={cn({ [css.number]: index === 0 })}
                          key={`customers-${position}-${cell.id}-${index}`}
                          textColor={cell.textColor}
                          textWeight={cell.textWeight}
                          size={cell.size}
                          id={cell.id}
                          rowId={cell.rowId}
                          tooltipBody={renderTooltipCellContent(cell)}
                          forceShow={shouldShowTooltipAlways(cell)}
                          skipTooltip={isDisabledTooltip || shouldSkipTooltip(cell)}
                          actionable={getIsActionableCell(cell)}
                          onClick={handleCellClick(cell)}
                          dataLabel={`${cell.id + '-' + cell.rowId}`}
                        >
                          {renderCellContent(cell)}
                        </TableRowCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </WhiteBox>

            {isPaginationVisible && (
              <Pagination
                paginationClassName={css.pagination}
                goToPage={handlePageClick}
                page={page}
                lastPage={lastPage}
              />
            )}
          </>
        ) : (
          renderNonDataComponents()
        )}
      </div>
    </>
  );
};
