import { OptionType, Select, Typography } from '@ezetech/swag-space-x';
import { Control, FieldValues } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { useEffect } from 'react';

import css from 'components/popups/open-closed-site/open-closed-site.popup.module.scss';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InputForm } from 'components/input-form';
import { RestrictionLevel } from 'constants/curated-brand-store';
import { MenuPortalStyles, useMenuPortalStyles } from 'hooks/use-menu-portal-styles.hook';
import { FormField } from '../../form.config';
import {
  SITE_TYPE_LABEL,
  SITE_TYPES_OPTIONS,
  storeRestrictionLevelFormConfig as formConfig,
} from './form.config';

interface IProps {
  showDescription?: boolean;
  control: Control<FieldValues>;
  watch: (name: string) => string;
  setValue: (name: string, value: string) => void;
  trigger: (name?: string | string[]) => Promise<boolean>;
  rectId: string;
}

export function StoreRestrictionLevelForm({
  showDescription = true,
  control,
  watch,
  setValue,
  trigger,
  rectId,
}: IProps) {
  const restrictionLevel =
    (watch(FormField.RestrictionLevel) as RestrictionLevel) || RestrictionLevel.PUBLIC;

  const onChange = async (option: OptionType | MultiValue<OptionType | null> | null) => {
    const value = (option as OptionType)?.value as RestrictionLevel;
    setValue(FormField.RestrictionLevel, value);
  };

  const isRestricted: boolean = restrictionLevel === RestrictionLevel.RESTRICTED;
  const isTouched = control.getFieldState(FormField.AllowedDomains)?.isTouched;
  const selectStyles: MenuPortalStyles = useMenuPortalStyles(rectId);

  const allowedDomainsRules = isRestricted ? formConfig?.allowedDomains?.rules : {};

  useEffect(() => {
    const shouldTriggerValidation = !isRestricted || (isRestricted && isTouched);

    if (shouldTriggerValidation) {
      trigger();
    }
  }, [isRestricted, isTouched, trigger]);

  return (
    <>
      {showDescription && (
        <Typography color={COLORS.colorTextSubdued} fontType="bodyMd" lineHeight="162.5%">
          There are two different options when creating a curated brand site, open or
          closed. An open site means that anybody that has access to the link can order
          swag. A closed site means that you need to have an approved email domain in
          order to get access to the site.
        </Typography>
      )}
      <div className={css.inputForm}>
        <Select
          options={SITE_TYPES_OPTIONS}
          className={css.input}
          label={formConfig.restrictionLevel?.label}
          name={formConfig.restrictionLevel?.name || ''}
          value={{ label: SITE_TYPE_LABEL[restrictionLevel], value: restrictionLevel }}
          onChange={onChange}
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          styles={selectStyles}
        />

        {isRestricted && (
          <InputForm
            autoComplete="off"
            type="text"
            label={formConfig.allowedDomains?.label}
            errorClassName={css.errorClassName}
            labelClassName={css.labelClassName}
            name={formConfig.allowedDomains?.name || ''}
            placeholder={formConfig.allowedDomains?.placeholder || ''}
            rules={allowedDomainsRules}
            control={control}
          />
        )}
      </div>
    </>
  );
}
