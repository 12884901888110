import React from 'react';
import { CellSize, CellTextColor, CellTextWeight, ICell } from '@ezetech/swag-space-x';
import { IFile } from '@ezetech/swag-space-x';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { IPrebuiltItem } from './cart.interfaces';
import { IAssignedForReseller } from './assignee.interface';

export enum PresentationStatusEnum {
  SENT = 'SENT',
  NOT_SENT = 'NOT SENT',
}

export interface ITransformPresentationCell extends Omit<ICell, 'onClick' | 'id'> {
  id: PresentationFields;
  onClick?: PresentationActions;
  assignedToUser?: { value: string; label: string } | null;
}

export const PRESENTATION_FILTER_OPTIONS = [
  { label: 'My Presentations', value: ASSIGNED_TO_ME_ENTITY },
  { label: 'Email status: Sent', value: PresentationStatusEnum.SENT },
  { label: 'Email status: Not sent', value: PresentationStatusEnum.NOT_SENT },
];

export enum PresentationFields {
  id = 'id',
  email = 'email',
  products = 'products',
  createdAt = 'createdAt',
  emailStatus = 'emailStatus',
  invoice = 'invoice',
  downloadPDF = 'downloadPDF',
  assignTo = 'assignTo',
  manage = 'manage',
  createCart = 'createCart',
}

export enum PresentationActions {
  copy = 'copy',
  redirect = 'redirect',
}

export const PRESENTATION_TABLE_HEADER = [
  { text: '#', id: PresentationFields.id, size: CellSize.small },
  { text: 'Email', id: PresentationFields.email, size: CellSize.extraLarge },
  { text: 'Products', id: PresentationFields.products, size: CellSize.large },
  {
    text: 'Created',
    id: PresentationFields.createdAt,
    size: CellSize.large,
    withSorting: true,
  },
  {
    text: 'Email status',
    id: PresentationFields.emailStatus,
    size: CellSize.extraLarge,
  },
  {
    text: 'Invoice',
    id: PresentationFields.invoice,
    size: CellSize.large,
  },
  {
    text: 'PDF',
    id: PresentationFields.downloadPDF,
    size: CellSize.medium,
  },
  { text: 'Assign to', id: PresentationFields.assignTo, size: CellSize.extraLarge },
  { text: 'Manage', id: PresentationFields.manage, size: CellSize.medium },
  {
    text: 'Cart',
    id: PresentationFields.createCart,
    size: CellSize.extraLarge,
  },
];

export const PRESENTATIONS_TABLE_ROW = {
  [PresentationFields.id]: { size: CellSize.small, textWeight: CellTextWeight.extraBold },
  [PresentationFields.email]: {
    size: CellSize.extraLarge,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: PresentationActions.copy,
    actionable: true,
  },
  [PresentationFields.products]: {
    size: CellSize.large,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [PresentationFields.createdAt]: { size: CellSize.large },
  [PresentationFields.emailStatus]: {
    size: CellSize.extraLarge,
  },
  [PresentationFields.invoice]: {
    size: CellSize.large,
  },
  [PresentationFields.downloadPDF]: {
    size: CellSize.medium,
  },
  [PresentationFields.assignTo]: { size: CellSize.extraLarge },
  [PresentationFields.manage]: { size: CellSize.medium },
  [PresentationFields.createCart]: { size: CellSize.extraLarge },
};

export interface IPDFCellAdditional {
  sentAt?: Date | null;
  customerEmail?: string;
  cartId?: string;
  orderNum?: string | null;
  hasInvoice?: boolean;
  hasCart?: boolean;
  isDownloadPDFDisabled?: boolean;
  isCreateInvoiceDisabled?: boolean;
  isCreateCartLinkDisabled?: boolean;
  isOrderPlaced?: boolean;
}

export interface IPresentation {
  id: string;
  email: string;
  createdAt: Date;
  sentAt: Date | null;
  items: IPrebuiltItem[];
  orderNum: string | null;
  cartId: string;
  invoiceId: string | null;
  prebuiltCartId: string;
  assignee?: IAssignedForReseller | null;
}

export enum PdfPresentationEditorTemplate {
  box = 'template-box',
}

export enum PdfPresentationEditorFilter {
  ALL = 'all',
  EDITABLE = 'editable',
  NON_EDITABLE = 'non-editable',
}

export interface PdfPresentationEditorReview {
  id: string | null;
  logo?: IFile | null;
  text?: string;
  settingsId?: string | null;
  deletedAt?: Date | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface PdfPresentationEditor {
  id: string | null;
  template: PdfPresentationEditorTemplate | null;
  homepageHeading?: string;
  homepageBody?: string;
  whatWeDoHeading?: string;
  whatWeDoBody?: string;
  brandLogos?: Array<IFile | null>;
  portfolioImage?: IFile | null;
  boxOverviewImage?: IFile | null;
  productShowcaseImage?: IFile | null;
  contactPhone?: string;
  contactEmail?: string;
  contactAddress?: string;
  isCoverHidden?: boolean;
  isWhatWeDoHidden?: boolean;
  isWhoWeWorkWithHidden?: boolean;
  isPastWorksHidden?: boolean;
  isReviewsHidden?: boolean;
  isThankYouHidden?: boolean;
  isSoMuchMoreHidden?: boolean;
  isTonsOfBenefitsHidden?: boolean;
  isShowcaseHidden?: boolean;
  reviews?: Array<PdfPresentationEditorReview | null>;
  deletedAt?: Date | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface PdfPresentationEditorReviewBody
  extends Omit<
    PdfPresentationEditorReview,
    'deletedAt' | 'createdAt' | 'updatedAt' | 'settingsId'
  > {}

export interface PdfPresentationEditorUpdateBody
  extends Omit<
    PdfPresentationEditor,
    'deletedAt' | 'createdAt' | 'updatedAt' | 'reviews'
  > {
  reviews?: Array<PdfPresentationEditorReviewBody | null>;
}

export enum PdfPresentationEditorPageId {
  COVER = 'cover',
  WHAT_WE_DO = 'what-we-do',
  WHO_WE_WORK_WITH = 'who-we-work-with',
  PAST_WORKS = 'past-works',
  BOX_OVERVIEW = 'box-overview',
  BOX_PAGE = 'box-page',
  PRODUCT_PAGE = 'product-page',
  SUMMARY = 'summary',
  REVIEWS = 'reviews',
  SO_MUCH_MORE = 'so-much-more',
  BENEFITS = 'benefits',
  SHOWCASE = 'product-showcase',
  THANK_YOU = 'thank-you',
}

export interface PdfPresentationEditorPageFieldDefinitionComponentFuncProps {
  values: PdfPresentationEditorUpdateBody;
  onChangeValueCreator: (
    key: keyof PdfPresentationEditorUpdateBody,
  ) => (value: unknown) => void;
  onProcess?: (inProcess: boolean) => void;
  setIsExitBlocked?: (isBlocked: boolean) => void;
}

export interface PdfPresentationEditorPageFieldDefinition {
  component: (
    props: PdfPresentationEditorPageFieldDefinitionComponentFuncProps,
  ) => React.ReactNode;
}

export interface AdditionalMapProps {
  [key: string]: string | number | undefined | null;
}

export interface PdfPresentationEditorPage {
  id: PdfPresentationEditorPageId;
  name: string;
  tooltip?: React.ReactNode;
  isCanBeHidden: boolean;
  isHiddenKeyName?: keyof PdfPresentationEditorUpdateBody;
}
export interface PdfPresentationEditorSettingsPage extends PdfPresentationEditorPage {
  fieldsDefinition: PdfPresentationEditorPageFieldDefinition[];
  additionalComponentBefore?: () => React.ReactNode;
  additionalComponentAfter?: () => React.ReactNode;
}

export interface PdfPresentationEditorRenderPage extends PdfPresentationEditorPage {
  // eslint-disable-next-line
  presentationComponent: (props: any) => React.ReactNode;
  mapValues: (
    values: PdfPresentationEditorUpdateBody,
    additional?: AdditionalMapProps,
  ) => never;
}

export type PdfPresentationImagesOfPages = {
  dataUrl: string;
  pageId: PdfPresentationEditorPageId | string;
  pageNumber: number;
};
