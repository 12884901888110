import { makeToastNotification } from 'utils/query.util';
import { pushNotification } from 'redux/slices/notifications.slice';

import { IAction } from 'interfaces/loaders.interface';
import { fetchUserData } from 'utils/user.util';
import { fetchStoreSettingsData } from 'utils/store-settings.util';
import { curatedBrandSitesApi } from 'redux/api/curated-brand-sites.api';
import { DEFAULT_PAGE, DEFAULT_SORTING_ORDER } from 'constants/common';
import { PER_PAGE_CARTS } from 'constants/carts.constant';
import { store } from '../../redux/store';

export async function curatedBrandSiteLoader(): Promise<null> {
  await fetchUserData();
  await fetchStoreSettingsData();

  const requests: Record<string, unknown>[] = [
    {
      endpoint: curatedBrandSitesApi.endpoints.getBrandSites.initiate({
        page: DEFAULT_PAGE,
        perPage: PER_PAGE_CARTS,
        search: '',
        sorting: '',
        sortingOrder: DEFAULT_SORTING_ORDER,
        dropdown: [],
      }),
    },
  ];

  try {
    const results = await Promise.all(
      requests.map(async (request) => {
        if (request?.condition === false) {
          return;
        }

        return store.dispatch(request.endpoint as IAction).unwrap();
      }),
    );

    if (results.some((result) => result && 'error' in result)) {
      return null;
    }
  } catch (error) {
    store.dispatch(pushNotification(makeToastNotification(error)));
  }

  return null;
}
