import { ReactElement, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import {
  Dialog,
  DialogContentWrapper,
  DialogFooter,
  DialogTitle,
  SwagButton,
} from '@ezetech/swag-space-x';

import { IEditCuratedBrandSitePopupProps } from 'interfaces/popup.interface';
import { closePopup } from 'redux/slices/modals.slice';
import { AppDispatch, useAppDispatch } from 'redux/store';
import {
  useGetBrandSiteQuery,
  useUpdateBrandSiteMutation,
} from 'redux/api/curated-brand-sites.api';

import { resetStoreCreation } from 'redux/slices/curated-brand-sites.slice';
import { EditCBSForm } from './components/edit-cbs-form';

import css from './edit-curated-brand-site.popup.module.scss';

export function EditCuratedBrandSitePopup({
  isOpen,
  id,
}: IEditCuratedBrandSitePopupProps): ReactElement | null {
  const POPUP_CONTAINER_ID = 'edit-curated-brand-site-popup';
  const dispatch: AppDispatch = useAppDispatch();

  const [update, { isLoading }] = useUpdateBrandSiteMutation();
  const { data, refetch } = useGetBrandSiteQuery(id || '');

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => void refetch(), [isOpen, id, refetch]);

  if (!data || !id) {
    return null;
  }

  const onClose = (): void => {
    dispatch(resetStoreCreation());
    dispatch(closePopup());
  };

  const onSubmit: SubmitHandler<FieldValues> = async (state: FieldValues) => {
    await update({ id, data: state });
    dispatch(resetStoreCreation());
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
        id={POPUP_CONTAINER_ID}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>
          Edit {data.email} Curated brand site
        </DialogTitle>

        <div className={css.dialogBody}>
          <EditCBSForm
            rectId={POPUP_CONTAINER_ID}
            data={data}
            setIsValid={setIsValid}
            onSubmit={onSubmit}
          />
        </div>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onClose} className={css.button}>
            Cancel
          </SwagButton>
          <SwagButton
            type="primary"
            form="form"
            htmlType="submit"
            loading={isLoading}
            className={css.button}
            disabled={!isValid || isLoading}
          >
            Save Changes
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
}
