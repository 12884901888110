import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import cn from 'classnames';
import {
  Divider,
  Loader,
  MultiOptionType,
  OptionType,
  Pagination,
  SwagButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Typography,
  WhiteBox,
  Select,
} from '@ezetech/swag-space-x';

import { TableToolbar } from 'components/table-toolbar';
import { ROUTES } from 'constants/router';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { CartsFields, ITransformCartCell } from 'interfaces/cart.interfaces';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  CART_PRODUCTS_PREVIEW_POPUP,
  CONFIRMATION_POPUP,
  CREATE_A_CART_EMAIL_POPUP,
} from 'components/popups/_logic/popups-list';
import {
  useDeleteCartMutation,
  useGetCartNotesMutation,
  useGetCartsMutation,
} from 'redux/api/cart.api';
import { can, SUBJECTS } from 'boot/ability';

import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  cartsRowsSelector,
  getProductsNumber,
  isPaginationCartVisibleSelector,
  cartsHeaderSelector,
  cartsFilterOptionsSelector,
} from 'redux/selectors/carts.selector';
import { cartsAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { useAssignCartToUserMutation } from 'redux/api/assignee.api';
import { usePrevious } from 'hooks/use-previous.hook';
import { EmptyState } from 'components/empty-state';
import { ProductsListTooltip } from 'components/products-list-tooltip';
import { resetFilters, setFilter } from 'redux/slices/filter.slice';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { API_URL } from 'redux/api/helpers';
import { MAX_PRODUCTS_IN_TOOLTIP } from 'constants/carts.constant';
import trashIcon from '../../assets/svg/trash-v1.svg';
import editIcon from '../../assets/svg/edit.svg';
import css from './build-a-cart.module.scss';

export const BuildACartComponent = () => {
  const { copy } = useClipboard();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCarts, { isLoading }] = useGetCartsMutation();
  const rows = useAppSelector(cartsRowsSelector);
  const headerCells = useAppSelector(cartsHeaderSelector);
  const cartsFilterOptions = useAppSelector(cartsFilterOptionsSelector);
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isPaginationVisible = useAppSelector(isPaginationCartVisibleSelector);
  const cartsAssignToOptions = useAppSelector(cartsAssignToOptionsSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const [useGetCarts] = useGetCartNotesMutation();
  const [assignCartToUserRequest] = useAssignCartToUserMutation();
  const [isDisabledTooltip, setDisabled] = useState<boolean>(false);
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });
  const [deletePrebuiltCart, { isLoading: isLoadingDelete }] = useDeleteCartMutation();

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchAction = urlParams.get('action');
  const customerEmailParam = urlParams.get('customerEmail');
  const isActionSearchByCustomerEmail =
    searchAction === ACTION_TOKEN_TYPES.GET_CART_BY_EMAIL;

  const editCartAccess = can(
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.EDIT_BUILD_A_CART,
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
  );

  const deleteCartAccess = can(
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.DELETE_BUILD_A_CART,
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
  );

  const createCartAccess = can(
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.CREATE_BUILD_A_CART,
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
  );

  const viewOrdersAccess = can(
    SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
    SUBJECTS.VIEW_ORDERS.value,
  );

  const isAbleToAssignCart = can(
    SUBJECTS.TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT.actions
      .ASSIGN_TEAM_MEMBER_TO_BUILD_A_CART,
    SUBJECTS.TEAM_MEMBER_BUILD_A_CART_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForCart = can(
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.actions.ASSIGN_BUILD_A_CART,
    SUBJECTS.CREATE_EDIT_BUILD_A_CART.value,
  );

  useEffect(() => {
    if (isActionSearchByCustomerEmail) {
      dispatch(setFilter({ search: customerEmailParam ?? '' }));
      setSearchValue(customerEmailParam ?? '');
    }
  }, [isActionSearchByCustomerEmail]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getCarts({ ...filters, search: searchValue, page: 1 });
    }
  }, [searchValue]);

  const handlePageClick = (selectedPage: number) => {
    getCarts({ ...filters, page: selectedPage });
  };

  const handleSearchChange = (searchVal: string): void => {
    setSearchValue(searchVal);
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getCarts({ ...filters, dropdown: selected, page: 1 });
  };

  const onBuildACartClick = () => {
    dispatch(openPopup({ popupName: CREATE_A_CART_EMAIL_POPUP, popupProps: {} }));
  };

  const getSortingDirection = (id: string): 'up' | 'down' => {
    const list = headerCells;
    const cell = list.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    getCarts({ ...filters, page: 1, sorting: field, sortingOrder: newSortingOrder });
  };

  const handleEditCartClick = (cartId: string) => () => {
    if (!editCartAccess) {
      return;
    }
    document.location.replace(`${API_URL}/cart/edit/${cartId}`);
  };

  const shouldSkipTooltip = (cell: ITransformCartCell): boolean => {
    return [CartsFields.link, CartsFields.manage, CartsFields.assignTo].includes(cell.id);
  };

  const getIsActionableCell = (
    cell: ITransformCartCell & { isNonClickable?: boolean },
  ): boolean => {
    const { text, id, isNonClickable } = cell;

    if (isNonClickable) {
      return false;
    }

    if (id !== CartsFields.products) {
      return cell.actionable || false;
    }

    return getProductsNumber(text) > 0;
  };

  const shouldShowTooltipAlways = (cell: ITransformCartCell): boolean => {
    const { id, text } = cell;

    return id === CartsFields.products && getProductsNumber(text) > 0;
  };

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      dispatch(
        pushNotification(makeSuccessToastNotification(`${text} has been copied.`)),
      );
    }
  };

  const onDeleteCartClick = (cartId: string) => () => {
    if (!deleteCartAccess) {
      return;
    }
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent:
            'If you confirm the link will no longer be active. Are you sure you want to delete the cart?',
          onConfirm: () => {
            deletePrebuiltCart({ cartId });
          },
          onCancel: () => {
            dispatch(closePopup());
          },
          isConfirmLoading: isLoadingDelete,
        },
      }),
    );
  };

  const handleCellClick =
    (cell: ITransformCartCell & { isOrdered?: boolean; email?: string }) =>
    async (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
      switch (id) {
        case CartsFields.customerEmail: {
          await copyText(cell.text, 'Email');

          return;
        }
        case CartsFields.link: {
          if (cell.isOrdered) {
            if (!viewOrdersAccess) {
              return;
            }
            dispatch(resetFilters());
            navigate(
              `${ROUTES.MY_STORE_ORDERS}?orderNum=${cell.text}&action=${ACTION_TOKEN_TYPES.GET_ORDER_BY_NUM_ACTION}`,
            );
            return;
          }

          await copyText(cell.text, 'Link');
          return;
        }
        case CartsFields.notes: {
          if (cell.isNonClickable) {
            return;
          }

          useGetCarts({ cartId: String(cell.rowId), email: String(cell.email) });

          return;
        }
        case CartsFields.products: {
          const number = getProductsNumber(cell.text);

          if (number <= MAX_PRODUCTS_IN_TOOLTIP) {
            return;
          }
          setDisabled(true);

          dispatch(
            openPopup({
              popupName: CART_PRODUCTS_PREVIEW_POPUP,
              popupProps: { cartId: cell.rowId },
            }),
          );
          setTimeout(() => setDisabled(false), 100);

          return;
        }

        default: {
          return;
        }
      }
    };

  const onAssignmentSelect =
    (cell: ITransformCartCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignCartToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  const renderCellContent = (
    cell: ITransformCartCell & { isOrdered?: boolean },
  ): string | JSX.Element => {
    if (cell.id === CartsFields.link) {
      if (cell.isOrdered) {
        return (
          <span
            className={cn(css.goToBtn, {
              [css.disabled]: !viewOrdersAccess,
            })}
          >
            See order
          </span>
        );
      }
      return <span>Copy</span>;
    }

    if (cell.id === CartsFields.manage) {
      return (
        <div className={css.manageWrapper}>
          {cell.isOrdered ? null : (
            <>
              <div
                className={cn(css.trashIcon, {
                  [css.disabled]: !editCartAccess,
                })}
                onClick={handleEditCartClick(cell.rowId || '')}
                data-label={`edit-button-${cell.rowId || ''}`}
              >
                <img src={editIcon} />
              </div>
              <div
                className={cn(css.trashIcon, {
                  [css.disabled]: !deleteCartAccess,
                })}
                onClick={onDeleteCartClick(cell.rowId || '')}
                data-label={`delete-button-${cell.rowId || ''}`}
              >
                <img src={trashIcon} />
              </div>
            </>
          )}
          <SwagButton
            type="primary"
            size="medium"
            dataLabel={`status-button-${cell.rowId}`}
            className={cn(css.smallBtn, {
              [css.yellow]: !cell.isOrdered,
              [css.green]: cell.isOrdered,
            })}
          >
            {cell.text}
          </SwagButton>
        </div>
      );
    }

    if (cell.id === CartsFields.assignTo) {
      if (isAbleToAssignCart || (isAbleToBeAssignedForCart && !cell.assignedToUser)) {
        return (
          <div className={css.assignToWrapper}>
            <Select
              options={cartsAssignToOptions}
              value={cell.assignedToUser}
              isSearchable={true}
              onChange={onAssignmentSelect(cell)}
              name="assignTo"
              skipError
              menuPlacement="auto"
              size="small"
              type="outlined"
              menuPosition="fixed"
              menuShouldBlockScroll
            />
          </div>
        );
      }

      return (
        <div className={css.assignToWrapper}>
          <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
            {cell.assignedToUser?.label}
          </Typography>
        </div>
      );
    }

    return cell.text;
  };

  const renderEmptyState = () => {
    const { dropdown } = filters;

    if (searchValue.length || !!(dropdown as MultiValue<OptionType>).length) {
      return (
        <div className={css.containerWrapper}>
          <EmptyState
            title="No results found"
            description="Try adjusting your search or filter to find what you are looking for."
          />
        </div>
      );
    }

    return (
      <div className={css.containerWrapper}>
        <EmptyState
          title="No carts to display yet"
          description={createCartAccess ? 'Make your first Cart.' : ''}
          button={createCartAccess ? 'Build a Cart' : ''}
          buttonClassName={css.emptyStateButton}
          onClick={onBuildACartClick}
        />
      </div>
    );
  };

  const renderNonDataComponents = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <div className={css.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    if (!rows.length) {
      return renderEmptyState();
    }

    return null;
  };

  const renderTooltipCellContent = (cell: ITransformCartCell): null | React.ReactNode => {
    const { id, text } = cell;

    if (id !== CartsFields.products) {
      return null;
    }

    const number = getProductsNumber(text);

    if (number > MAX_PRODUCTS_IN_TOOLTIP) {
      return (
        <Typography
          fontType="bodySm"
          lineHeight="143%"
          color={COLORS.colorTypographyHeadings}
        >
          Click to view
        </Typography>
      );
    }

    return <ProductsListTooltip cartId={cell.rowId} />;
  };

  return (
    <div className={css.containerWrapper}>
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        filterOptions={cartsFilterOptions}
        isFilterMultiSelect
        exportTooltipText="Export orders list"
        className={css.toolbar}
      >
        {createCartAccess && (
          <div className={css.buildBtnWrapper}>
            <SwagButton
              type="primary"
              size="large"
              className={css.buildBtn}
              onClick={onBuildACartClick}
            >
              Build a Cart
            </SwagButton>

            <Divider className={css.buildBtnDivider} />
          </div>
        )}
      </TableToolbar>

      {rows.length ? (
        <>
          <WhiteBox className={css.root}>
            <Table className={css.tableWrapper}>
              <TableHeader>
                {headerCells.map((cell, index) => (
                  <TableHeaderCell
                    dataLabel={cell.text}
                    id={cell.id}
                    className={cn(css.headerCell, {
                      [css.number]: index === 0,
                      [css.managementColumn]: cell.id === CartsFields.manage,
                    })}
                    size={cell.size}
                    key={`customers-header-${cell.id}`}
                    withSorting={cell.withSorting}
                    iconDirection={getSortingDirection(cell.id)}
                    onClick={cell.withSorting ? handleSortingClick : undefined}
                  >
                    {cell.text}
                  </TableHeaderCell>
                ))}
              </TableHeader>
              <TableBody>
                {rows.map((row, position) => (
                  <TableRow
                    key={`customers-${row.index}`}
                    primaryDataLabel={row.cells[0].rowId}
                    secondaryDataLabel={`${API_URL}/cart/edit/${row.cells[0].rowId}`}
                  >
                    {row.cells.map((cell, index) => (
                      <TableRowCell
                        className={cn(css.cell, { [css.number]: index === 0 })}
                        key={`customers-${position}-${cell.id}-${index}`}
                        textColor={cell.isNonClickable ? undefined : cell.textColor}
                        textWeight={cell.textWeight}
                        size={cell.size}
                        id={cell.id}
                        rowId={cell.rowId}
                        tooltipBody={renderTooltipCellContent(cell)}
                        forceShow={shouldShowTooltipAlways(cell)}
                        skipTooltip={isDisabledTooltip || shouldSkipTooltip(cell)}
                        actionable={getIsActionableCell(cell)}
                        onClick={handleCellClick(cell)}
                        dataLabel={`${cell.id + '-' + cell.rowId}`}
                      >
                        {renderCellContent(cell)}
                      </TableRowCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </WhiteBox>

          {isPaginationVisible && (
            <Pagination
              paginationClassName={css.pagination}
              goToPage={handlePageClick}
              page={page}
              lastPage={lastPage}
            />
          )}
        </>
      ) : (
        renderNonDataComponents()
      )}
    </div>
  );
};
