import { Loader } from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import { TableToolbar } from 'components/table-toolbar';
import { API_URL, handleCSVFileUpload } from 'redux/api/helpers';
import { ordersIdsListSelector } from 'redux/selectors/orders.selectors';
import {
  IOrderCSVExportRequestParams,
  IOrderTypeParam,
} from 'interfaces/orders.interface';

import { useMyOrders } from './hooks/use-my-orders.hook';
import { OrderEmptyState } from './components/order-empty-state';
import { OrdersList } from './components/order-list';

import css from './my-orders.module.scss';

export const CBSOrdersComponent = () => {
  const ordersIds = useAppSelector(ordersIdsListSelector);

  const csvFileUpload = (params: IOrderCSVExportRequestParams) => {
    handleCSVFileUpload({
      baseUrl: `${API_URL}/order/export/${IOrderTypeParam.curatedBrandStore}`,
      params,
    });
  };

  const {
    handlePageClick,
    handleSearchChange,
    handleFilterClick,
    handleExportCSV,
    ORDER_FILTERS,
    isLoading,
  } = useMyOrders({
    type: IOrderTypeParam.curatedBrandStore,
    csvFileUpload,
  });

  return (
    <div className={css.container}>
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        onExportClick={handleExportCSV}
        filterOptions={ORDER_FILTERS}
        isFilterMultiSelect
        showExportButton
        exportTooltipText="Export orders list"
        className={css.toolbar}
      />
      {ordersIds.length ? (
        <OrdersList ordersIds={ordersIds} handlePageClick={handlePageClick} />
      ) : (
        <OrderEmptyState />
      )}
      {isLoading && <Loader />}
    </div>
  );
};
