import { Pagination } from '@ezetech/swag-space-x';
import { useAppSelector } from 'redux/store';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import {
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { ordersPublicIdsListSelector } from 'redux/selectors/orders.selectors';
import { OrderCard } from '../order-card';

import css from './order-list.module.scss';

type OrdersListProps = { ordersIds: string[]; handlePageClick: (page: number) => void };

export const OrdersList = ({ ordersIds, handlePageClick }: OrdersListProps) => {
  const ordersPublicIds = useAppSelector(ordersPublicIdsListSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isOrderAssignmentEnabled = useAppSelector(assignmentFlowEnabledSelector);
  return (
    <>
      {ordersIds.map((id, i) => (
        <OrderCard
          orderPublicId={String(ordersPublicIds[i])}
          orderId={String(id)}
          key={String(id)}
          isOrderAssignmentEnabled={isOrderAssignmentEnabled}
        />
      ))}
      <Pagination
        paginationClassName={css.pagination}
        goToPage={handlePageClick}
        page={page}
        lastPage={lastPage}
      />
    </>
  );
};
