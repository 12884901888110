import { GroupBase } from 'react-select';
import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';

import { OptionType } from '@ezetech/swag-space-x';

export type MenuPortalStyles = StylesConfig<
  OptionType | null,
  boolean,
  GroupBase<OptionType | null>
>;

export function useMenuPortalStyles(id: string): MenuPortalStyles {
  const calculatePosition = (style: CSSObjectWithLabel) => {
    const rect: DOMRect | null =
      document.getElementById(id)?.getBoundingClientRect() || null;

    const { top, left, ...rest } = style;

    return {
      ...rest,
      left: (left as number) - (rect?.x || 0),
      top: (top as number) - (rect?.y || 0),
    };
  };

  return {
    menuPortal: (style: CSSObjectWithLabel) => ({
      ...style,
      ...calculatePosition(style),
    }),
  };
}
