import { Typography, WhiteBox, List, ListHeader, Divider } from '@ezetech/swag-space-x';
import cn from 'classnames';

import { useNavigate } from 'react-router-dom';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { ROUTES } from 'constants/router';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { useAppDispatch } from 'redux/store';
import { setFilter } from 'redux/slices/filter.slice';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';

import css from './orders-list-card.module.scss';
import { IOrdersListCardProps } from './orders-list-card.type';
import { OrderItemsList } from './order-items-list';

export function OrdersListCard({
  className,
  orders,
}: IOrdersListCardProps): JSX.Element | null {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  if (!orders.length) {
    return null;
  }

  const subjectOrdersName = SUBJECTS.VIEW_ORDERS.value;
  const subjectOrdersActions = SUBJECTS.VIEW_ORDERS.actions;

  const onClickSeeAll = () => {
    const options = [
      {
        label: CLIENT_STATUSES.REQUIRES_ATTENTION,
        value: CLIENT_STATUSES.REQUIRES_ATTENTION,
      },
    ];
    dispatch(setFilter({ dropdown: options }));
    navigate(ROUTES.MY_STORE_ORDERS);
  };
  return (
    <WhiteBox className={cn(css.root, className)}>
      <List>
        <ListHeader className={css.header}>
          <Typography
            lineHeight="162%"
            fontType="bodyMdBold"
            color={COLORS.colorTextSubdued}
          >
            Orders that require your attention
          </Typography>
          {can(subjectOrdersActions.VIEW_ALL_ORDERS, subjectOrdersName) && (
            <Typography
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorPink}
              link
              className={css.link}
            >
              <div onClick={onClickSeeAll}>See All</div>
            </Typography>
          )}
        </ListHeader>
        <Divider />
        {orders.map((order) => (
          <OrderItemsList order={order} key={order.id} />
        ))}
      </List>
    </WhiteBox>
  );
}
