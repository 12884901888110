import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';

import { PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT } from 'constants/common';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { resetStoreCreation } from 'redux/slices/curated-brand-sites.slice';
import { ICommonPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { useAppDispatch } from 'redux/store';
import { ReactComponent as CBSImage } from 'assets/svg/create-cbs.svg';
import {
  CBS_CUSTOMER_INFORMATION_POPUP,
  CONFIRMATION_POPUP,
  CREATE_CURATED_BRAND_SITE_POPUP,
} from '../_logic/popups-list';

import css from './create-curated-brand-site.popup.module.scss';

const title = 'Curated Brand Site';

export const CreateCuratedBrandSitePopup = ({ isOpen }: ICommonPopupProps) => {
  const dispatch = useAppDispatch();

  const onConfirm = () =>
    dispatch(openPopup({ popupName: CBS_CUSTOMER_INFORMATION_POPUP, popupProps: {} }));

  const onClose = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent: PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT,
          onConfirm: () => {
            dispatch(closePopup());
            dispatch(resetStoreCreation());
          },
          onCancel: () => {
            dispatch(
              openPopup({ popupName: CREATE_CURATED_BRAND_SITE_POPUP, popupProps: {} }),
            );
          },
        },
      }),
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>{title}</DialogTitle>
        <div className={css.dialogBody}>
          <DialogDescription>
            <div className={css.imgWrapper}>
              <CBSImage className={css.img} />
            </div>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Amazing, you are now able to create a curated brand site for your customers.
              You will be able to choose the products that you want to offer on the site,
              and your customers will be able to easily showcase the curated offering to
              their audience for purchase.
            </Typography>
          </DialogDescription>
        </div>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onClose} className={css.button}>
            Cancel
          </SwagButton>
          <SwagButton type="primary" onClick={onConfirm} className={css.button}>
            Let's Go!
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
