import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import {
  Loader,
  MultiOptionType,
  OptionType,
  Pagination,
  Select,
  SwagButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Typography,
  WhiteBox,
} from '@ezetech/swag-space-x';
import cn from 'classnames';

import { TableToolbar } from 'components/table-toolbar';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { usePrevious } from 'hooks/use-previous.hook';
import { EmptyState } from 'components/empty-state';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  CART_PRODUCTS_PREVIEW_POPUP,
  CONFIRMATION_POPUP,
  CREATE_CURATED_BRAND_SITE_POPUP,
  EDIT_CURATED_BRAND_SITE_POPUP,
} from 'components/popups/_logic/popups-list';
import { setFilter } from 'redux/slices/filter.slice';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { API_URL } from 'redux/api/helpers';
import {
  useDeleteBrandSiteMutation,
  useLazyGetBrandSitesQuery,
  useUpdateBrandSiteStatusMutation,
} from 'redux/api/curated-brand-sites.api';
import {
  isPaginationVisibleSelector,
  rowsSelector,
} from 'redux/selectors/curated-brand-sites.selector';
import {
  CBSFields,
  CBS_FILTER_OPTIONS,
  CBS_TABLE_HEADER,
  ITransformCell,
} from 'interfaces/curated-brand-site.interface';
import { CuratedBrandStoreStatus } from 'constants/curated-brand-store';
import trashIcon from '../../assets/svg/trash-v1.svg';
import editIcon from '../../assets/svg/edit.svg';
import { can, SUBJECTS } from '../../boot/ability';
import css from './curated-brand-site.module.scss';

export const CuratedBrandSiteComponent = () => {
  const { copy } = useClipboard();
  const dispatch = useAppDispatch();
  const [triggerGetBrandSites, { isLoading }] = useLazyGetBrandSitesQuery();
  const [updateBrandSiteStatus] = useUpdateBrandSiteStatusMutation();
  const [deleteBrandSite, { isLoading: isLoadingDelete }] = useDeleteBrandSiteMutation();
  const [isDisabledTooltip, setDisabled] = useState<boolean>(false);
  const rows = useAppSelector(rowsSelector);
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isPaginationVisible = useAppSelector(isPaginationVisibleSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      triggerGetBrandSites({ ...filters, search: searchValue, page: 1 });
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isLoadingDelete) {
      triggerGetBrandSites({ ...filters, search: searchValue, page: 1 });
    }
  }, [isLoadingDelete]);

  const canCreateBrandSite: boolean = can(
    SUBJECTS.CURATED_BRAND_SITE_CREATE.actions.CURATED_BRAND_SITE_CREATE,
    SUBJECTS.CURATED_BRAND_SITE_CREATE.value,
  );

  const canManageBrandSite: boolean = can(
    SUBJECTS.CURATED_BRAND_SITE_MANAGE.actions.CURATED_BRAND_SITE_MANAGE,
    SUBJECTS.CURATED_BRAND_SITE_MANAGE.value,
  );

  const handlePageClick = (selectedPage: number) => {
    triggerGetBrandSites({ ...filters, page: selectedPage });
  };

  const handleSearchChange = (searchVal: string): void => {
    setSearchValue(searchVal);
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    triggerGetBrandSites({ ...filters, dropdown: selected, page: 1 });
  };

  const getSortingDirection = (id: string): 'up' | 'down' => {
    const cell = CBS_TABLE_HEADER.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    triggerGetBrandSites({
      ...filters,
      page: 1,
      sorting: field,
      sortingOrder: newSortingOrder,
    });
  };

  const shouldSkipTooltip = (cell: ITransformCell): boolean => {
    const cellIsNotSupported = [
      CBSFields.createdAt,
      CBSFields.link,
      CBSFields.manage,
      CBSFields.status,
    ].includes(cell.id);

    if (cellIsNotSupported) {
      return true;
    }

    return false;
  };

  const getIsActionableCell = (cell: ITransformCell): boolean => {
    const { id } = cell;

    if (id !== CBSFields.products) {
      return cell.actionable || false;
    }

    return true;
  };

  const shouldShowTooltipAlways = (cell: ITransformCell): boolean => {
    const { id } = cell;
    if (id === CBSFields.products) {
      return true;
    }

    if (id === CBSFields.openClosed && !cell.isOpened) {
      return true;
    }

    return false;
  };

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      dispatch(
        pushNotification(makeSuccessToastNotification(`${text} has been copied.`)),
      );
    }
  };

  const handleCellClick =
    (cell: ITransformCell) =>
    async (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
      switch (id) {
        case CBSFields.customerEmail: {
          await copyText(cell.text, 'Email');

          return;
        }
        case CBSFields.link: {
          if (cell.link) {
            await copyText(cell.link, 'Link');
          }

          return;
        }
        case CBSFields.products: {
          setDisabled(true);

          dispatch(
            openPopup({
              popupName: CART_PRODUCTS_PREVIEW_POPUP,
              popupProps: { brandSiteId: cell.rowId },
            }),
          );
          setTimeout(() => setDisabled(false), 100);

          return;
        }

        default: {
          return;
        }
      }
    };

  const onStatusSelect =
    (cell: ITransformCell, id: string) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.text) {
        return;
      }

      updateBrandSiteStatus({
        id,
        status: (option as OptionType).value.toUpperCase() as CuratedBrandStoreStatus,
      });
    };

  const onCreateBrandSite = () => {
    dispatch(
      openPopup({
        popupName: CREATE_CURATED_BRAND_SITE_POPUP,
        popupProps: {},
      }),
    );
  };

  const onEditBrandSite = (id: string) => () => {
    if (!canManageBrandSite || !id) {
      return;
    }

    dispatch(openPopup({ popupName: EDIT_CURATED_BRAND_SITE_POPUP, popupProps: { id } }));
  };

  const onDeleteBrandSite = (id: string) => () => {
    if (!canManageBrandSite) {
      return;
    }

    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          isConfirmLoading: isLoadingDelete,
          onConfirm: () => {
            deleteBrandSite({ id });
          },
          onCancel: () => {
            dispatch(closePopup());
          },
          descriptionComponent: 'Are you sure you want to delete the brand site?',
        },
      }),
    );
  };

  const renderCellContent = (cell: ITransformCell): string | JSX.Element => {
    if (cell.id === CBSFields.manage) {
      return (
        <div className={css.manageWrapper}>
          <div
            className={cn(css.trashIcon, {
              [css.disabled]: !canManageBrandSite,
            })}
            onClick={onEditBrandSite(cell.rowId || '')}
            data-label={`edit-button-${cell.rowId || ''}`}
          >
            <img alt="edit" src={editIcon} />
          </div>
          <div
            data-label={`delete-button-${cell.rowId || ''}`}
            className={cn(css.trashIcon, {
              [css.disabled]: !canManageBrandSite,
            })}
            onClick={onDeleteBrandSite(cell.rowId || '')}
          >
            <img alt="delete" src={trashIcon} />
          </div>
        </div>
      );
    }

    if (cell.id === CBSFields.status) {
      return (
        <Select
          dataLabel={`${cell.id + '-' + cell.rowId}`}
          value={cell.status}
          options={CBS_FILTER_OPTIONS}
          isDisabled={!canManageBrandSite}
          onChange={onStatusSelect(cell, cell.rowId ?? '')}
          className={cn(css.statusCell, {
            [css.green]: cell.status?.value === CuratedBrandStoreStatus.ACTIVE,
          })}
          isSearchable
          name="status"
          skipError
          menuPlacement="auto"
          size="small"
          type="outlined"
          menuPosition="fixed"
          menuShouldBlockScroll
        />
      );
    }

    if (cell.id === CBSFields.products) {
      return <div className={css.productsCell}>{cell.text}</div>;
    }

    return cell.text;
  };

  const renderEmptyState = () => {
    const { dropdown } = filters;

    if (searchValue.length || !!(dropdown as MultiValue<OptionType>).length) {
      return (
        <div className={css.containerWrapper}>
          <EmptyState
            title="No results found"
            description="Try adjusting your search or filter to find what you are looking for."
          />
        </div>
      );
    }

    return (
      <div className={css.containerWrapper}>
        <EmptyState
          title="No brand sites to display yet"
          description="Make your first Brand site."
          button="Create New Brand Site"
          buttonClassName={css.emptyStateButton}
          onClick={onCreateBrandSite}
        />
      </div>
    );
  };

  const renderNonDataComponents = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <div className={css.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    if (!rows.length) {
      return renderEmptyState();
    }

    return null;
  };

  const renderTooltipCellContent = (cell: ITransformCell): null | React.ReactNode => {
    const { id } = cell;

    if (id === CBSFields.openClosed) {
      if (!cell.allowedDomains?.length) {
        return null;
      }

      return (
        <div className={css.openClosedTooltip}>
          <span className={css.subTitle}>Allowed Domains</span>
          {cell.allowedDomains.map((domain) => (
            <span key={domain}>{domain}</span>
          ))}
        </div>
      );
    }

    if (id !== CBSFields.products) {
      return null;
    }

    return (
      <Typography
        fontType="bodySm"
        lineHeight="143%"
        color={COLORS.colorTypographyHeadings}
      >
        Click to view and edit
      </Typography>
    );
  };

  return (
    <div className={css.containerWrapper}>
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        filterOptions={CBS_FILTER_OPTIONS}
        isFilterMultiSelect
        showExportButton={false}
        className={css.toolbar}
        searchClassName={css.searchClassName}
        toolbarClassName={css.toolbarClassName}
        searchMobileClassName={css.mobileInputClassName}
        toolbarMobileClassName={css.mobileSearchRowClassName}
      >
        {canCreateBrandSite && (
          <SwagButton
            type="primary"
            size="large"
            className={css.buildBtn}
            onClick={onCreateBrandSite}
          >
            Create Brand Site
          </SwagButton>
        )}
      </TableToolbar>

      {rows.length ? (
        <>
          <WhiteBox className={css.root}>
            <Table className={css.tableWrapper}>
              <TableHeader>
                {CBS_TABLE_HEADER.map((cell) => (
                  <TableHeaderCell
                    id={cell.id}
                    dataLabel={cell.text}
                    size={cell.size}
                    key={`sites-header-${cell.id}`}
                    withSorting={cell.withSorting}
                    iconDirection={getSortingDirection(cell.id)}
                    onClick={cell.withSorting ? handleSortingClick : undefined}
                    className={cn(css.headerCell, {
                      [css.fixedWidthCell]: cell.id === CBSFields.status,
                    })}
                  >
                    {cell.text}
                  </TableHeaderCell>
                ))}
              </TableHeader>
              <TableBody>
                {rows.map((row, position) => (
                  <TableRow
                    key={`sites-${row.index}`}
                    primaryDataLabel={row.cells[0].rowId}
                    secondaryDataLabel={`${API_URL}/curated-brand-site/edit/${row.cells[0].rowId}`}
                  >
                    {row.cells.map((cell, index) => (
                      <TableRowCell
                        key={`sites-${position}-${cell.id}-${index}`}
                        textColor={cell.textColor}
                        textWeight={cell.textWeight}
                        size={cell.size}
                        id={cell.id}
                        rowId={cell.rowId}
                        tooltipBody={renderTooltipCellContent(cell)}
                        forceShow={shouldShowTooltipAlways(cell)}
                        skipTooltip={isDisabledTooltip || shouldSkipTooltip(cell)}
                        actionable={getIsActionableCell(cell)}
                        onClick={handleCellClick(cell)}
                        dataLabel={`${cell.id + '-' + cell.rowId}`}
                        className={cn(css.cell, {
                          [css.productsCell]: cell.id === CBSFields.products,
                          [css.openClosedCell]: cell.id === CBSFields.openClosed,
                          [css.fixedWidthCell]: cell.id === CBSFields.status,
                        })}
                        tooltipTriggerClassName={cn({
                          [css.visibleOverflow]: cell.id === CBSFields.status,
                        })}
                      >
                        {renderCellContent(cell)}
                      </TableRowCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </WhiteBox>

          {isPaginationVisible && (
            <Pagination
              paginationClassName={css.pagination}
              goToPage={handlePageClick}
              page={page}
              lastPage={lastPage}
            />
          )}
        </>
      ) : (
        renderNonDataComponents()
      )}
    </div>
  );
};
