import { CellTextColor } from '@ezetech/swag-space-x';
import { createSelector } from 'reselect';

import { RootState } from 'redux/store';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { pluralize, truncateString } from 'utils/string.util';
import {
  CBSFields,
  CBS_TABLE_HEADER,
  CBS_TABLE_ROW,
  ITransformCell,
  STATUS_LABEL,
} from 'interfaces/curated-brand-site.interface';
import { RestrictionLevel } from 'constants/curated-brand-store';

export const cbsSelector = (state: RootState) => state.curatedBrandSites;

export const listSelector = createSelector(cbsSelector, (state) => state.list);

export const rowsSelector = createSelector(listSelector, (list) => {
  const rows: {
    cells: ITransformCell[];
    index: number;
  }[] = [];

  list.forEach((item, position) => {
    const cells: ITransformCell[] & {
      sentAt?: Date | null;
    } = [];

    CBS_TABLE_HEADER.forEach((cell) => {
      const common = CBS_TABLE_ROW[cell.id] || {};
      const cellParams = { ...common, products: item.products, rowId: item.id };

      if (cell.id === CBSFields.customerEmail) {
        cells.push({
          ...cellParams,
          text: item.email,
          id: CBSFields.customerEmail,
        });
      }
      if (cell.id === CBSFields.company) {
        cells.push({
          ...cellParams,
          text: item.name || 'n/a',
          id: CBSFields.company,
        });
      }
      if (cell.id === CBSFields.products) {
        cells.push({
          ...cellParams,
          text: `${item.products?.length ?? 0} ${pluralize(
            item.products?.length ?? 0,
            'Product',
            's',
            true,
          )}`,
          id: CBSFields.products,
        });
      }
      if (cell.id === CBSFields.createdAt) {
        cells.push({
          ...cellParams,
          text: item.createdAt
            ? getTimeMmmDdYyyy(new Date(item.createdAt), {
                day: 'numeric',
                month: 'short',
              })
            : 'n/a',
          id: CBSFields.createdAt,
        });
      }
      if (cell.id === CBSFields.link) {
        cells.push({
          ...cellParams,
          text: item.link ? 'Copy Link' : 'n/a',
          textColor: item.link ? CellTextColor.pink : CellTextColor.primary,
          id: CBSFields.link,
          link: item.link,
          actionable: Boolean(item.link),
        });
      }
      if (cell.id === CBSFields.openClosed) {
        cells.push({
          ...cellParams,
          text: item.restrictionLevel === RestrictionLevel.PUBLIC ? 'Open' : 'Closed',
          textColor:
            item.restrictionLevel === RestrictionLevel.PUBLIC
              ? CellTextColor.primary
              : CellTextColor.pink,
          id: CBSFields.openClosed,
          isOpened: item.restrictionLevel === RestrictionLevel.PUBLIC,
          allowedDomains: item.allowedDomains,
        });
      }
      if (cell.id === CBSFields.manage) {
        cells.push({
          ...cellParams,
          text: '',
          id: CBSFields.manage,
        });
      }
      if (cell.id === CBSFields.status) {
        cells.push({
          ...cellParams,
          text: '',
          id: CBSFields.status,
          status: {
            label: STATUS_LABEL[item.status],
            value: item.status,
          },
        });
      }
    });

    rows.push({
      cells,
      index: position + 1,
    });
  });

  return rows;
});

export const selectedBrandSiteProductsSelector = (id?: string) =>
  createSelector(listSelector, (list) => {
    const brandSite = list.find((item) => item.id === id);

    if (!brandSite) {
      return [];
    }

    return brandSite.products.map(({ product, ...rest }) => ({
      name: truncateString(product.name, 40),
      image: product.logo,
      quantity: 0,
      fullName: product.name,
      ...rest,
    }));
  });

export const isPaginationVisibleSelector = createSelector(
  listSelector,
  (list) => list.length > 0,
);

export const storeCreationSelector = createSelector(
  cbsSelector,
  (state) => state.storeCreation,
);

export const logoSelector = createSelector(
  storeCreationSelector,
  (state) => state.logo?.url ?? undefined,
);

export const logoObjectSelector = createSelector(
  storeCreationSelector,
  (state) => state.logo,
);

export const faviconObjectSelector = createSelector(
  storeCreationSelector,
  (state) => state.favicon,
);

export const emailSelector = createSelector(
  storeCreationSelector,
  (state) => state.email,
);
export const companyNameSelector = createSelector(
  storeCreationSelector,
  (state) => state.companyName,
);
export const selectPhone = createSelector(storeCreationSelector, ({ phone }) => phone);
export const typeSelector = createSelector(storeCreationSelector, (state) => state.type);
export const allowedDomainsSelector = createSelector(
  storeCreationSelector,
  (state) => state.allowedDomains,
);
export const primaryColorSelector = createSelector(
  storeCreationSelector,
  (state) => state.primaryColor,
);
export const secondaryColorSelector = createSelector(
  storeCreationSelector,
  (state) => state.secondaryColor,
);
