import { useEffect } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { FieldValues, useForm } from 'react-hook-form';

import { InputForm } from 'components/input-form';
import { PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT } from 'constants/common';
import { cellPhoneRegexp, emailRegexp } from 'constants/validation';
import { useAppDispatch } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { ICommonPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  resetStoreCreation,
  setCompanyName,
  setEmail,
  setPhone,
} from 'redux/slices/curated-brand-sites.slice';
import {
  CBS_CUSTOMER_INFORMATION_POPUP,
  CONFIRMATION_POPUP,
  CREATE_CURATED_BRAND_SITE_POPUP,
  OPEN_CLOSED_SITE_POPUP,
} from '../_logic/popups-list';

import css from './customer-information.popup.module.scss';

const title = 'Customer information';

const FIELDS = {
  email: {
    label: 'Point of contact email',
    name: 'email',
    placeholder: 'Start typing email address',
    rules: {
      required: 'Email is required',
      pattern: {
        value: emailRegexp,
        message: "Email you entered doesn't seem to be valid",
      },
      maxLength: {
        value: 254,
        message: 'Maximum 254 symbols',
      },
    },
  },
  companyName: {
    label: 'Company Name',
    name: 'companyName',
    placeholder: 'Enter Company Name',
    rules: {
      required: 'Company name is required',
      maxLength: {
        value: 50,
        message: 'Maximum 50 symbols',
      },
      minLength: {
        value: 2,
        message: 'Minimum 2 symbols',
      },
    },
  },
  phone: {
    label: 'Point of contact cell phone',
    name: 'phone',
    placeholder: 'Enter Cell Phone',
    rules: {
      maxLength: {
        value: 30,
        message: 'Maximum 30 symbols',
      },
      pattern: {
        value: cellPhoneRegexp,
        message: 'Invalid phone number',
      },
    },
  },
};

export const CustomerInformationPopup = ({ isOpen }: ICommonPopupProps) => {
  const dispatch = useAppDispatch();
  const { control, formState, getValues, watch, clearErrors } = useForm<FieldValues>({
    mode: 'onBlur',
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === FIELDS.email.name) {
        dispatch(setEmail(value.email));
      }
      if (name === FIELDS.companyName.name) {
        dispatch(setCompanyName(value.companyName));
      }
      if (name === FIELDS.phone.name) {
        dispatch(setPhone(value.phone));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onConfirm = () => {
    dispatch(
      openPopup({
        popupName: OPEN_CLOSED_SITE_POPUP,
        popupProps: {},
      }),
    );
  };

  const onClose = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent: PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT,
          onConfirm: () => {
            dispatch(closePopup());
            dispatch(resetStoreCreation());
          },
          onCancel: () => {
            dispatch(
              openPopup({
                popupName: CBS_CUSTOMER_INFORMATION_POPUP,
                popupProps: {},
              }),
            );
          },
        },
      }),
    );
  };

  const onGoBack = () =>
    dispatch(openPopup({ popupName: CREATE_CURATED_BRAND_SITE_POPUP, popupProps: {} }));

  const handleClearErrors = (path: string) => () => {
    clearErrors(path);
  };

  const { isValid } = formState;
  const emailEntered = Boolean(getValues(FIELDS.email.name)?.length);

  const isDisabled = !isValid || !emailEntered;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>{title}</DialogTitle>
        <div className={css.dialogBody}>
          <DialogDescription>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Please enter the company who you are creating the curated brand site for,
              and the person who should be approving all virtual production mockups when
              products go into production for the first time.
            </Typography>
            <div className={css.inputForm}>
              <InputForm
                autoComplete="off"
                type="text"
                label={FIELDS.companyName.label}
                errorClassName={css.errorClassName}
                labelClassName={css.labelClassName}
                name={FIELDS.companyName.name}
                placeholder={FIELDS.companyName.placeholder}
                rules={{
                  ...FIELDS.companyName.rules,
                  onChange: handleClearErrors(FIELDS.companyName.name),
                }}
                control={control}
                className={css.companyNameInput}
              />
              <InputForm
                autoComplete="off"
                type="text"
                label={FIELDS.email.label}
                errorClassName={css.errorClassName}
                labelClassName={css.labelClassName}
                shouldTrimInputValue
                name={FIELDS.email.name}
                placeholder={FIELDS.email.placeholder}
                rules={{
                  ...FIELDS.email.rules,
                  onChange: handleClearErrors(FIELDS.email.name),
                }}
                control={control}
              />
              <InputForm
                autoComplete="off"
                type="text"
                label={FIELDS.phone.label}
                errorClassName={css.errorClassName}
                labelClassName={css.labelClassName}
                shouldTrimInputValue
                name={FIELDS.phone.name}
                placeholder={FIELDS.phone.placeholder}
                rules={{
                  ...FIELDS.phone.rules,
                  onChange: handleClearErrors(FIELDS.phone.name),
                }}
                control={control}
              />
            </div>
          </DialogDescription>
        </div>

        <DialogFooter className={css.footer}>
          <span onClick={onGoBack} className={css.goBack}>
            <Typography
              fontType="bodyMdBold"
              lineHeight="162.5%"
              color={COLORS.colorPink}
              link
            >
              Go Back
            </Typography>
          </span>
          <SwagButton type="outlined" onClick={onClose} className={css.button}>
            Cancel
          </SwagButton>
          <SwagButton
            type="primary"
            onClick={onConfirm}
            disabled={isDisabled}
            className={css.button}
          >
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
