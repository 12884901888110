import { MultiOptionType } from '@ezetech/swag-space-x';
import { IAssignedForReseller } from './assignee.interface';

export enum ORDER_PAYMENT_STATUS_CODE {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export interface ICommonOrderData {
  publicId: string;
  userId: string;
  firstName: string;
  lastName: string;
  shippingAddress: {
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    address: string;
    unit: string;
  };
  email: string;
  num: string;
  paidAt: string;
  invoiceUrl: string;
  paymentMethod: string;
  paymentPrices: {
    subTotal: number;
    taxes: number;
    shipment: number;
    grandTotal: number;
  };
  paymentStatusCode: ORDER_PAYMENT_STATUS_CODE;
  isTaxExempt: boolean;
  discounts: {
    promocode: number;
    setupFee: number;
    shipment: number;
    sampleDeduction: number;
  };
}

export interface IItemData {
  asRelatedItemId: string;
  boxItemsId: string;
  inventoryPublicId: string;
  inventoryName: string;
  name: string;
  num: string;
  category: string;
  productType: string; // regular, box, box special item
  productPublicId: string;
  imageUrl: string;
  sizes: string | null;
  publicId: string;
  quantity: number; // total quantity
  variants: {
    quantities: number[];
    name: string;
    color: string;
  }[];
  status: string;
  statusCode: string;
  estInHand: {
    from: string;
    to: string;
  };
  pricePerItem: number;
  itemProductionCost: number;
  itemTotalPrice: number;
  setupFee: number;
  kittingFee: number | null;
  shipping: number;
  tax: number;
  trackingNumberLink: string;
  pantones?: IPantone[];
  productionMockups: IProductionMockup[];
  productionMockupLink: string;
  letterIndicator: string;
}

export interface IItem extends IItemData {
  id: string;
  orderId?: string;
}

export interface IOrder extends ICommonOrderData {
  id: string;
  orderCompanyName: string;
  items: IItem[];
  companyId: string;
  publicId: string;
  resellerProfit: number | null;
  assignee?: IAssignedForReseller | null;
  isSpecificReviewMockupsByCustomerAllowed?: boolean | null;
  isSpecificReviewPantonesByCustomerAllowed?: boolean | null;
  curatedBrandStoreId?: string | null;
}

export interface ICustomerProfileOrder extends ICommonOrderData {
  id: string;
  orderCompanyName: string;
  companyId: string;
  products: IItem[];
  number?: string;
}
export interface IOrderRequest {
  search: string | null;
  page: number;
  perPage: number;
  sorting: string;
  sortingOrder: 'ASC' | 'DESC';
  dropdown: MultiOptionType;
  type?: IOrderTypeParam;
}

export interface IApproveColorsRequest {
  orderId: string;
  itemId: string;
  itemPantones: {
    colorsToPrint: string[];
    id: string;
    mockupPreview: string | null;
    logoPreview: string;
    location: string;
    isConfirmedByCustomer: boolean;
    isConfirmedByReseller: boolean;
  }[];
}

export interface IActionOrderRequest {
  action: string | null;
  token: string | null;
  orderId: string | null;
  itemId: string | null;
}

export interface IOrderResponse {
  list: IOrder[];
  page: number;
  ordersTotal: number;
  selectionTotal: number;
  perPage: number;
}

export interface IOrderCSVExportRequestParams {
  search: string | null;
  startDate: string | null;
  endDate: string | null;
  sorting: string;
  sortingOrder: 'ASC' | 'DESC';
  dropdown: MultiOptionType;
}

export interface IMockupRequest {
  orderId: string;
  itemId: string;
  mockupId: string;
}

export interface IApproveMockupRequest extends IMockupRequest {
  isReviewMockupsByCustomerAllowed: boolean;
}

export interface IRejectMockupRequest extends IMockupRequest {
  changeRequest: string;
}

export interface IProductionMockup {
  fileName: string;
  id: string;
  status: string;
  updatedAt: string;
}

export interface IPantone {
  colorsToPrint: string[];
  id: string;
  mockupPreview: string | null;
  logoPreview: string;
  location: string;
  isConfirmedByCustomer: boolean;
  isConfirmedByReseller: boolean;
}

export enum IOrderTypeParam {
  curatedBrandStore = 'curatedBrandStore',
  myStore = 'myStore',
}
