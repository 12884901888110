/* eslint-disable spellcheck/spell-checker */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CBSType } from 'constants/curated-brand-store';
import {
  ICuratedBrandSite,
  IUpdateBrandSiteStatus,
} from 'interfaces/curated-brand-site.interface';

interface ICBSState {
  list: ICuratedBrandSite[];
  total: number;
  storeCreation: {
    logo: Record<string, string> | undefined;
    favicon: Record<string, string> | undefined;
    companyName: string;
    email: string;
    phone: string;
    type: CBSType;
    allowedDomains: string;
    primaryColor: string;
    secondaryColor: string;
  };
}

const defaultStoreCreationData = {
  logo: undefined,
  favicon: undefined,
  companyName: '',
  email: '',
  phone: '',
  type: CBSType.CLOSED,
  allowedDomains: '',
  primaryColor: '',
  secondaryColor: '',
};

const initialState: ICBSState = {
  list: [],
  total: 0,
  storeCreation: defaultStoreCreationData,
};

export const curatedBrandSitesSlice = createSlice({
  initialState,
  name: 'curatedBrandSitesSlice',
  reducers: {
    reset: () => initialState,
    setList: (
      state,
      {
        payload,
      }: PayloadAction<{
        list: ICBSState['list'];
        total: ICBSState['total'];
      }>,
    ) => {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        list: payload.list,
        total: payload.total,
      };
    },
    setStatusToBrandSite: (state, action: PayloadAction<IUpdateBrandSiteStatus>) => {
      const { list } = state;
      const {
        payload: { id, status },
      } = action;

      const brandSiteIdx = list.findIndex((user) => user.id === id);

      if (brandSiteIdx === -1) {
        return state;
      }

      const brandSite = list[brandSiteIdx];

      return {
        ...state,
        list: [
          ...list.slice(0, brandSiteIdx),
          { ...brandSite, status: status },
          ...list.slice(brandSiteIdx + 1),
        ],
      };
    },
    setLogoUrl: (
      state,
      action: PayloadAction<{
        logo: Record<string, string>;
        favicon: Record<string, string>;
      }>,
    ) => {
      state.storeCreation.logo = action.payload.logo;
      state.storeCreation.favicon = action.payload.favicon;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.storeCreation.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.storeCreation.phone = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.storeCreation.companyName = action.payload;
    },
    setType: (state, action: PayloadAction<CBSType>) => {
      state.storeCreation.type = action.payload;
    },
    setAllowedDomains: (state, action: PayloadAction<string>) => {
      state.storeCreation.allowedDomains = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.storeCreation.primaryColor = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<string>) => {
      state.storeCreation.secondaryColor = action.payload;
    },
    resetStoreCreation: (state) => {
      state.storeCreation = defaultStoreCreationData;
    },
  },
});

export const curatedBrandSitesReducer = curatedBrandSitesSlice.reducer;
export const {
  reset,
  setList,
  setStatusToBrandSite,
  setLogoUrl,
  setEmail,
  setPhone,
  setCompanyName,
  setAllowedDomains,
  setType,
  setPrimaryColor,
  setSecondaryColor,
  resetStoreCreation,
} = curatedBrandSitesSlice.actions;
