import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import cn from 'classnames';
import {
  Checkbox,
  Loader,
  MultiOptionType,
  OptionType,
  Pagination,
  Select,
  SwagButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  Tooltip,
  TooltipPlacement,
  Typography,
  WhiteBox,
} from '@ezetech/swag-space-x';

import { useNavigate } from 'react-router-dom';

import { TableToolbar } from 'components/table-toolbar';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  filtersRequestSelector,
  lastPageFiltersSelector,
  pageFiltersSelector,
} from 'redux/selectors/filters.selectors';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  CART_PRODUCTS_PREVIEW_POPUP,
  CONFIRMATION_POPUP,
  CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP,
  INVOICE_ACH_NET_TERMS_INFORMATION_POPUP,
} from 'components/popups/_logic/popups-list';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { usePrevious } from 'hooks/use-previous.hook';
import { EmptyState } from 'components/empty-state';
import { ProductsListTooltip } from 'components/products-list-tooltip';
import { resetFilters, setFilter } from 'redux/slices/filter.slice';
import { pushNotification } from 'redux/slices/notifications.slice';
import { makeSuccessToastNotification } from 'utils/query.util';
import { useClipboard } from 'hooks/use-clipboard.hook';
import { API_URL } from 'redux/api/helpers';
import {
  useDeleteInvoiceMutation,
  useGetInvoicesMutation,
  useMarkAsSentMutation,
} from 'redux/api/invoice.api';
import { InvoicesFields } from 'interfaces/invoice';
import {
  invoicesHeaderSelector,
  invoicesRowsSelector,
  isPaginationInvoiceVisibleSelector,
  invoicesFilterOptionsSelector,
} from 'redux/selectors/invoices.selector';
import {
  InvoiceMarkActionEnum,
  ITransformInvoiceCell,
} from 'interfaces/invoice.interface';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { getFormattedSentDate } from 'redux/selectors/presentations.selector';
import { useGetCompanySettingsMutation } from 'redux/api/settings.api';
import { usePrePlaceInvoiceOrderMutation } from 'redux/api/invoice.api';
import { invoicesAssignToOptionsSelector } from 'redux/selectors/assignee.selector';
import { useAssignInvoiceToUserMutation } from 'redux/api/assignee.api';
import { ROUTES } from 'constants/router';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import { AssignmentEntityType, IProposalToReAssign } from 'constants/assignment';
import trashIcon from '../../assets/svg/trash-v1.svg';
import editIcon from '../../assets/svg/edit.svg';
import downloadIcon from '../../assets/svg/download.svg';
import css from './invoice-generation.module.scss';

const MAX_PRODUCTS_IN_TOOLTIP = 8;

export const InvoicesComponent = () => {
  const { copy } = useClipboard();
  const dispatch = useAppDispatch();
  const [getInvoices, { isLoading }] = useGetInvoicesMutation();
  const [mark] = useMarkAsSentMutation();
  const [getSettings] = useGetCompanySettingsMutation();
  const [assignInvoiceToUserRequest] = useAssignInvoiceToUserMutation();
  const rows = useAppSelector(invoicesRowsSelector);
  const headerCells = useAppSelector(invoicesHeaderSelector);
  const invoicesFilterOptions = useAppSelector(invoicesFilterOptionsSelector);
  const filters = useAppSelector(filtersRequestSelector);
  const page = useAppSelector(pageFiltersSelector);
  const lastPage = useAppSelector(lastPageFiltersSelector);
  const isPaginationVisible = useAppSelector(isPaginationInvoiceVisibleSelector);
  const invoicesAssignToOptions = useAppSelector(invoicesAssignToOptionsSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isDisabledTooltip, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });
  const [deleteInvoice, { isLoading: isLoadingDelete }] = useDeleteInvoiceMutation();
  const [prePlaceInvoiceOrder, { isLoading: isLoadingPrePlaceOrder }] =
    usePrePlaceInvoiceOrderMutation();

  const [selectedRowId, setSelectedRowId] = useState('');

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchAction = urlParams.get('action');
  const customerEmailParam = urlParams.get('customerEmail');
  const isActionSearchByCustomerEmail =
    searchAction === ACTION_TOKEN_TYPES.GET_INVOICE_BY_EMAIL;

  const editInvoiceAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.EDIT_INVOICE,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const deleteInvoiceAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.DELETE_INVOICE,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const createInvoiceAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.CREATE_INVOICE,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const downloadInvoiceAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.DOWNLOAD_INVOICE,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const markAsPaidAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.MARK_AS_PAID,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const markAsSentAccess = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.MARK_AS_SENT,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const orderFromInvoiceAccess = can(
    SUBJECTS.MAKE_ORDER_FROM_INVOICE.actions.ORDER_INVOICE,
    SUBJECTS.MAKE_ORDER_FROM_INVOICE.value,
  );

  const viewPdfPresentationsAccess = can(
    SUBJECTS.VIEW_PDF_PRESENTATION.actions.VIEW_PDF_PRESENTATION_VIEW,
    SUBJECTS.VIEW_PDF_PRESENTATION.value,
  );

  const isAbleToAssignInvoices = can(
    SUBJECTS.TEAM_MEMBER_INVOICE_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_INVOICE,
    SUBJECTS.TEAM_MEMBER_INVOICE_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForInvoice = can(
    SUBJECTS.CREATE_EDIT_INVOICE.actions.ASSIGN_INVOICE,
    SUBJECTS.CREATE_EDIT_INVOICE.value,
  );

  const isAbleToAssignOrders = can(
    SUBJECTS.TEAM_MEMBER_ORDER_ASSIGNMENT.actions.ASSIGN_TEAM_MEMBER_TO_ORDER,
    SUBJECTS.TEAM_MEMBER_ORDER_ASSIGNMENT.value,
  );

  const isAbleToBeAssignedForOrders = can(
    SUBJECTS.ORDER_ASSIGNMENT.actions.ASSIGN_TO_ORDERS,
    SUBJECTS.ORDER_ASSIGNMENT.value,
  );

  useEffect(() => {
    if (isActionSearchByCustomerEmail) {
      dispatch(setFilter({ search: customerEmailParam ?? '' }));
      setSearchValue(customerEmailParam ?? '');
    }
  }, [isActionSearchByCustomerEmail]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getInvoices({ ...filters, search: searchValue, page: 1 });
    }
  }, [searchValue]);

  const handlePageClick = (selectedPage: number) => {
    getInvoices({ ...filters, page: selectedPage });
  };

  const handleSearchChange = (searchVal: string): void => {
    setSearchValue(searchVal);
  };

  const handleFilterClick = (selected: MultiOptionType) => {
    getInvoices({ ...filters, dropdown: selected, page: 1 });
  };

  const onCreateInvoiceClick = () => {
    dispatch(
      openPopup({ popupName: INVOICE_ACH_NET_TERMS_INFORMATION_POPUP, popupProps: {} }),
    );
  };

  const getProductsNumber = (text: string): number => {
    const numbers = text.match(/\d+/);

    if (!numbers) {
      return 0;
    }

    return parseFloat(numbers[0]);
  };

  const getSortingDirection = (id: string): 'up' | 'down' => {
    const list = headerCells;
    const cell = list.find((c) => c.id === id);

    if (!cell?.withSorting || cell.id !== filters.sorting) {
      return 'down';
    }

    return filters.sortingOrder === 'DESC' ? 'down' : 'up';
  };

  const handleSortingClick = (field: string) => {
    const { sorting, sortingOrder } = filters;
    const isSortingNew = sorting !== field;
    const oppositeSortingForAppliedField = sortingOrder === 'ASC' ? 'DESC' : 'ASC';
    const newSortingOrder = isSortingNew ? 'ASC' : oppositeSortingForAppliedField;

    dispatch(setFilter({ sortingOrder: newSortingOrder, sorting: field }));
    getInvoices({ ...filters, page: 1, sorting: field, sortingOrder: newSortingOrder });
  };

  const handleEditInvoiceClick = (invoiceId: string) => () => {
    if (!editInvoiceAccess) {
      return;
    }
    document.location.replace(`${API_URL}/invoice/edit/${invoiceId}`);
  };

  const shouldSkipTooltip = (cell: ITransformInvoiceCell): boolean => {
    return [
      InvoicesFields.orderType,
      InvoicesFields.manage,
      InvoicesFields.downloadInvoice,
      InvoicesFields.emailStatus,
      InvoicesFields.payment,
      InvoicesFields.presentation,
      InvoicesFields.assignTo,
    ].includes(cell.id);
  };

  const getIsActionableCell = (cell: ITransformInvoiceCell): boolean => {
    const { text, id } = cell;

    if (id !== InvoicesFields.products) {
      return cell.actionable || false;
    }

    return getProductsNumber(text) > 0;
  };

  const shouldShowTooltipAlways = (cell: ITransformInvoiceCell): boolean => {
    const { id, text } = cell;
    if (id === InvoicesFields.products && getProductsNumber(text) > 0) {
      return true;
    }
    return id === InvoicesFields.company;
  };

  const copyText = async (value?: string, field?: string) => {
    const text = field ? `${field}` : 'Text';
    const isSuccess = await copy(value);

    if (isSuccess) {
      dispatch(
        pushNotification(makeSuccessToastNotification(`${text} has been copied.`)),
      );
    }
  };

  const onDeleteInvoiceClick = (invoiceId: string) => () => {
    if (!deleteInvoiceAccess) {
      return;
    }
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          isConfirmLoading: isLoadingDelete,
          onConfirm: () => {
            deleteInvoice({ invoiceId });
          },
          onCancel: () => {
            dispatch(closePopup());
          },
          descriptionComponent: 'Are you sure you want to delete the invoice?',
        },
      }),
    );
  };

  const onPlaceInvoiceOrderClick = (invoiceId: string) => async () => {
    setSelectedRowId(invoiceId);
    const result = await prePlaceInvoiceOrder({
      invoiceId,
    });

    const proposals: IProposalToReAssign[] =
      'data' in result ? result.data.proposals : [];
    const isInvoiceAssigned: boolean | null =
      'data' in result ? result.data.isInvoiceAssigned : null;
    const isInvoiceAssignedToHimself: boolean | null =
      'data' in result ? result.data.isInvoiceAssignedToHimself : null;
    const isInvoiceOwnerCanBeAssignedToOrder: boolean | null =
      'data' in result ? result.data.isInvoiceOwnerCanBeAssignedToOrder : null;

    const shouldSkipAssignPopup =
      ('data' in result && !result.data.proposals.length) ||
      (!proposals.find((p) => p.entityType === AssignmentEntityType.ORDER) &&
        !isAbleToAssignOrders) ||
      (isInvoiceAssigned === true &&
        isInvoiceAssignedToHimself === false &&
        (isAbleToAssignOrders || isAbleToBeAssignedForOrders) &&
        !isInvoiceOwnerCanBeAssignedToOrder);

    if (shouldSkipAssignPopup) {
      document.location.replace(`${API_URL}/invoice/place-order/${invoiceId}`);
      return null;
    }

    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          title: 'Assignment confirmation',
          descriptionComponent: 'Would you like to assign created order to you?',
          onCancel: () => {
            dispatch(closePopup());
            setSelectedRowId('');
            document.location.replace(`${API_URL}/invoice/place-order/${invoiceId}`);
          },
          onConfirm: () => {
            dispatch(closePopup());
            setSelectedRowId('');
            const queryParams = new URLSearchParams({
              reassign: 'true',
            }).toString();
            document.location.replace(
              `${API_URL}/invoice/place-order/${invoiceId}?${queryParams}`,
            );
          },
          confirmText: 'Yes, assign',
          cancelText: 'No',
        },
      }),
    );
  };

  const handleCellClick =
    (cell: ITransformInvoiceCell) =>
    async (e?: React.SyntheticEvent<HTMLSpanElement>, id?: string) => {
      switch (id) {
        case InvoicesFields.customerEmail: {
          await copyText(cell.text, 'Email');

          return;
        }
        case InvoicesFields.products: {
          const number = getProductsNumber(cell.text);

          if (number <= MAX_PRODUCTS_IN_TOOLTIP) {
            return;
          }
          setDisabled(true);

          dispatch(
            openPopup({
              popupName: CART_PRODUCTS_PREVIEW_POPUP,
              popupProps: { cartId: cell.rowId },
            }),
          );
          setTimeout(() => setDisabled(false), 100);

          return;
        }

        default: {
          return;
        }
      }
    };

  const onMark = (action: string, invoiceId: string) => {
    mark({ invoiceId, action });
  };

  const onGoToPresentationClick = (customerEmail: string) => {
    if (!viewPdfPresentationsAccess) {
      return;
    }
    dispatch(resetFilters());
    navigate(
      `${ROUTES.PDF_PRESENTATION}?customerEmail=${customerEmail}&action=${ACTION_TOKEN_TYPES.GET_PRESENTATION_BY_EMAIL}`,
    );
  };

  const openContractPopup = (invoiceId: string) => {
    getSettings();
    dispatch(
      openPopup({
        popupName: CUSTOMER_INVENTORY_SIGN_CONTRACT_POPUP,
        popupProps: { invoiceId },
      }),
    );
  };

  const onAssignmentSelect =
    (cell: ITransformInvoiceCell) =>
    (option: OptionType | MultiValue<OptionType | null> | null) => {
      if ((option as OptionType).value === cell.assignedToUser?.value) {
        return;
      }

      assignInvoiceToUserRequest({
        entityId: cell.rowId || '',
        userId: (option as OptionType).value || '',
      });
    };

  const renderCellContent = (
    cell: ITransformInvoiceCell & { sentAt?: Date | null; paidAt?: Date | null },
  ): string | JSX.Element => {
    if (cell.id === InvoicesFields.emailStatus) {
      const isSent = Boolean(cell.sentAt);
      return (
        <div className={css.manageWrapper}>
          <Checkbox
            name="isSent"
            checked={Boolean(cell.sentAt)}
            disabled={!markAsSentAccess}
            onCheckedChange={() => {
              onMark(InvoiceMarkActionEnum.SENT, cell.rowId || '');
            }}
          />
          <span
            className={cn(css.sentAt, {
              [css.disabled]: !markAsSentAccess,
            })}
          >
            {isSent
              ? `Sent ${getFormattedSentDate(new Date(cell.sentAt || ''))}`
              : 'Mark as sent'}
          </span>
        </div>
      );
    }

    if (cell.id === InvoicesFields.payment) {
      const isPaid = Boolean(cell.paidAt);
      return (
        <div className={css.manageWrapper}>
          <Checkbox
            name="isPaid"
            checked={Boolean(cell.paidAt)}
            disabled={!markAsPaidAccess}
            onCheckedChange={() => {
              onMark(InvoiceMarkActionEnum.PAID, cell.rowId || '');
            }}
          />
          <span
            className={cn(css.sentAt, {
              [css.disabled]: !markAsPaidAccess,
            })}
          >
            {isPaid
              ? `Paid ${getFormattedSentDate(new Date(cell.paidAt || ''))}`
              : 'Mark as paid'}
          </span>
        </div>
      );
    }

    if (cell.id === InvoicesFields.presentation) {
      if (cell.hasPresentation) {
        return (
          <Typography
            color={COLORS.colorPink}
            fontType="bodyMd"
            onClick={() => onGoToPresentationClick(cell.customerEmail || '')}
            className={cn(css.goToBtn, {
              [css.disabled]: !viewPdfPresentationsAccess,
            })}
          >
            Go to
          </Typography>
        );
      } else {
        return <></>;
      }
    }

    if (cell.id === InvoicesFields.manage) {
      const isNotContractSigned = cell.isInventory && !cell.isContractSigned;
      return (
        <div className={css.manageWrapper}>
          {cell.isOrdered ? null : (
            <>
              <div
                className={cn(css.trashIcon, {
                  [css.disabled]: !editInvoiceAccess,
                })}
                onClick={handleEditInvoiceClick(cell.rowId || '')}
                data-label={`edit-button-${cell.rowId}`}
              >
                <img alt="edit" src={editIcon} />
              </div>
              <div
                onClick={onDeleteInvoiceClick(cell.rowId || '')}
                data-label={`delete-button-${cell.rowId || ''}`}
                className={cn(css.trashIcon, {
                  [css.disabled]: !deleteInvoiceAccess,
                })}
              >
                <img alt="delete" src={trashIcon} />
              </div>
            </>
          )}
          <Tooltip
            disabled={!cell.isOrdered && !isNotContractSigned}
            triggerClassName={css.rightAligned}
            placement={TooltipPlacement.bottom}
            content={
              <SwagButton
                onClick={onPlaceInvoiceOrderClick(cell.rowId || '')}
                type="primary"
                size="medium"
                dataLabel={`status-button-${cell.rowId || ''}`}
                disabled={
                  cell.isOrdered || isNotContractSigned || !orderFromInvoiceAccess
                }
                className={cn(css.smallBtn, {
                  [css.purple]: !cell.isOrdered,
                  [css.grey]: cell.isOrdered || isNotContractSigned,
                })}
                loading={selectedRowId === cell.rowId && isLoadingPrePlaceOrder}
              >
                Order
              </SwagButton>
            }
          >
            <Typography color={COLORS.colorTextSubdued}>
              {cell.isInventory && !cell.isContractSigned
                ? 'For your customers first inventory order, they need to first sign an inventory contract before you’re able to place the order.'
                : 'Order is already placed.'}
            </Typography>
          </Tooltip>
        </div>
      );
    }

    if (cell.id === InvoicesFields.downloadInvoice) {
      return (
        <div className={css.manageWrapper}>
          <a
            className={cn(css.trashIcon, {
              [css.disabled]: !downloadInvoiceAccess,
            })}
            target="_blank"
            // todo update for new flow when marge main
            // todo do not open in new window, use example on presentation download
            href={`${API_URL}/invoice/download/${cell.rowId || ''}`}
          >
            <img alt="download" src={downloadIcon} />
          </a>
        </div>
      );
    }

    if (cell.id === InvoicesFields.orderType) {
      if (!cell.isInventory) {
        return cell.text;
      }

      return (
        <div className={css.inventoryOrderTypeWrapper}>
          <div>{cell.text}</div>
          {getInventoryContractSignatureStatus(
            String(cell.inventoryContract),
            Boolean(cell.isContractSigned),
            cell.rowId || '',
          )}
        </div>
      );
    }

    if (cell.id === InvoicesFields.assignTo) {
      if (
        isAbleToAssignInvoices ||
        (isAbleToBeAssignedForInvoice && !cell.assignedToUser)
      ) {
        return (
          <div className={css.assignToWrapper}>
            <Select
              options={invoicesAssignToOptions}
              value={cell.assignedToUser}
              isSearchable={true}
              onChange={onAssignmentSelect(cell)}
              name="assignTo"
              skipError
              menuPlacement="auto"
              size="small"
              type="outlined"
              menuPosition="fixed"
              menuShouldBlockScroll
            />
          </div>
        );
      }

      return (
        <div className={css.assignToWrapper}>
          <Typography color={COLORS.colorPrimaryText} fontType="bodyMd">
            {cell.assignedToUser?.label}
          </Typography>
        </div>
      );
    }

    return cell.text;
  };

  const copyExistedContractLink = async (inventoryContract: string) => {
    const isSuccess = await copy(inventoryContract);

    if (isSuccess) {
      dispatch(pushNotification(makeSuccessToastNotification('Link has been copied.')));
    }
  };

  const getInventoryContractSignatureStatus = (
    inventoryContract: string,
    isContractSigned: boolean,
    invoiceId: string,
  ): JSX.Element => {
    if (isContractSigned) {
      return <div className={css.contractSigned}>Contract signed</div>;
    }

    if (!inventoryContract) {
      return (
        <div className={css.viewContract} onClick={() => openContractPopup(invoiceId)}>
          View contract
        </div>
      );
    }

    return (
      <div
        className={css.viewContract}
        onClick={() => copyExistedContractLink(inventoryContract)}
      >
        Copy link
      </div>
    );
  };

  const renderEmptyState = () => {
    const { dropdown } = filters;

    if (searchValue.length || !!(dropdown as MultiValue<OptionType>).length) {
      return (
        <div className={css.containerWrapper}>
          <EmptyState
            title="No results found"
            description="Try adjusting your search or filter to find what you are looking for."
          />
        </div>
      );
    }

    return (
      <div className={css.containerWrapper}>
        <EmptyState
          title="No invoices to display yet"
          description={createInvoiceAccess ? 'Make your first Invoice.' : ''}
          button={createInvoiceAccess ? 'Create New Invoice' : ''}
          buttonClassName={css.emptyStateButton}
          onClick={onCreateInvoiceClick}
        />
      </div>
    );
  };

  const renderNonDataComponents = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <div className={css.loaderWrapper}>
          <Loader />
        </div>
      );
    }

    if (!rows.length) {
      return renderEmptyState();
    }

    return null;
  };

  const renderTooltipCellContent = (
    cell: ITransformInvoiceCell,
  ): null | React.ReactNode => {
    const { id, text } = cell;

    if (id === InvoicesFields.company) {
      return (
        <div className={css.companyTooltip}>
          <span>Company name: {cell.text}</span>
          <span>Address: {cell.customerAddress} </span>
        </div>
      );
    }

    if (id !== InvoicesFields.products) {
      return null;
    }

    const number = getProductsNumber(text);

    if (number > MAX_PRODUCTS_IN_TOOLTIP) {
      return (
        <Typography
          fontType="bodySm"
          lineHeight="143%"
          color={COLORS.colorTypographyHeadings}
        >
          Click to view
        </Typography>
      );
    }

    return <ProductsListTooltip invoiceId={cell.rowId} />;
  };

  return (
    <div className={css.containerWrapper}>
      <TableToolbar
        onNextPageClick={handlePageClick}
        onSearchChange={handleSearchChange}
        onFilterClick={handleFilterClick}
        filterOptions={invoicesFilterOptions}
        isFilterMultiSelect
        exportTooltipText="Export orders list"
        className={css.toolbar}
        searchClassName={css.searchClassName}
        toolbarClassName={css.toolbarClassName}
        searchMobileClassName={css.mobileInputClassName}
        toolbarMobileClassName={css.mobileSearchRowClassName}
      >
        {createInvoiceAccess && (
          <SwagButton
            type="primary"
            size="large"
            className={css.buildBtn}
            onClick={onCreateInvoiceClick}
          >
            Create New Invoice
          </SwagButton>
        )}
      </TableToolbar>

      {rows.length ? (
        <>
          <WhiteBox className={css.root}>
            <Table className={css.tableWrapper}>
              <TableHeader>
                {headerCells.map((cell, index) => (
                  <TableHeaderCell
                    id={cell.id}
                    dataLabel={cell.text}
                    className={cn({
                      [css.orderTypeCell]: index === 2,
                      [css.checkboxCell]: [5, 6].includes(index),
                    })}
                    size={cell.size}
                    key={`customers-header-${cell.id}`}
                    withSorting={cell.withSorting}
                    iconDirection={getSortingDirection(cell.id)}
                    onClick={cell.withSorting ? handleSortingClick : undefined}
                  >
                    {cell.text}
                  </TableHeaderCell>
                ))}
              </TableHeader>
              <TableBody>
                {rows.map((row, position) => (
                  <TableRow
                    key={`customers-${row.index}`}
                    primaryDataLabel={row.cells[0].rowId}
                    secondaryDataLabel={`${API_URL}/invoice/edit/${row.cells[0].rowId}`}
                  >
                    {row.cells.map((cell, index) => (
                      <TableRowCell
                        className={cn({
                          [css.orderTypeCell]: index === 2,
                          [css.checkboxCell]: [5, 6].includes(index),
                        })}
                        key={`customers-${position}-${cell.id}-${index}`}
                        textColor={cell.textColor}
                        textWeight={cell.textWeight}
                        size={cell.size}
                        id={cell.id}
                        rowId={cell.rowId}
                        tooltipBody={renderTooltipCellContent(cell)}
                        forceShow={shouldShowTooltipAlways(cell)}
                        skipTooltip={isDisabledTooltip || shouldSkipTooltip(cell)}
                        actionable={getIsActionableCell(cell)}
                        onClick={handleCellClick(cell)}
                        dataLabel={`${cell.id + '-' + cell.rowId}`}
                      >
                        {renderCellContent(cell)}
                      </TableRowCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </WhiteBox>

          {isPaginationVisible && (
            <Pagination
              paginationClassName={css.pagination}
              goToPage={handlePageClick}
              page={page}
              lastPage={lastPage}
            />
          )}
        </>
      ) : (
        renderNonDataComponents()
      )}
    </div>
  );
};
