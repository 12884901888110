import { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  ColorsSelector,
  ImageUpload,
  ImageCropTools,
  useImageCropTools,
} from '@ezetech/swag-space-x';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { API_URL } from 'redux/api/helpers';
import { debounce } from 'utils/common.util';
import { PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT } from 'constants/common';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import {
  resetStoreCreation,
  setPrimaryColor,
  setSecondaryColor,
} from 'redux/slices/curated-brand-sites.slice';
import {
  allowedDomainsSelector,
  companyNameSelector,
  emailSelector,
  faviconObjectSelector,
  logoObjectSelector,
  logoSelector,
  primaryColorSelector,
  secondaryColorSelector,
  selectPhone,
  typeSelector,
} from 'redux/selectors/curated-brand-sites.selector';
import { useUploadLogoMutation } from 'redux/api/curated-brand-sites.api';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { CBSType, RestrictionLevel } from 'constants/curated-brand-store';
import { ICommonPopupProps } from 'interfaces/popup.interface';
import {
  CONFIRMATION_POPUP,
  OPEN_CLOSED_SITE_POPUP,
  UPLOAD_CBS_LOGO_POPUP,
} from '../_logic/popups-list';

import css from './upload-cbs-logo.popup.module.scss';

const title = 'Upload or import logo';

export const UploadCBSLogoPopup = ({ isOpen }: ICommonPopupProps) => {
  const email = useSelector(emailSelector);
  const companyName = useSelector(companyNameSelector);
  const phone = useSelector(selectPhone);
  const type = useSelector(typeSelector);
  const allowedDomains = useSelector(allowedDomainsSelector);
  const primaryColor = useSelector(primaryColorSelector);
  const secondaryColor = useSelector(secondaryColorSelector);
  const dispatch = useAppDispatch();
  const logoUrl = useAppSelector(logoSelector);
  const logoObject = useAppSelector(logoObjectSelector);
  const faviconObject = useAppSelector(faviconObjectSelector);
  const [loading, setLoading] = useState(false);
  const [uploadLogo] = useUploadLogoMutation();

  useEffect(() => () => setLoading(false), []);

  useEffect(() => {
    if (!logoUrl) {
      return;
    }
    imageCropSetSrc(logoUrl);
  }, [logoUrl]);

  const {
    toolsOnSaveHandler,
    toolsOnCloseHandler,
    toolsOnScaleHandler,
    toolsOnRevertHandler,

    imageCropOnCroppedHandler,
    imageCropOnLoadHandler,
    imageCropSetSrc,

    setIsVisibleImageCrop,
    onSaved,

    fitScale,
    scale,
    blobCropped,
    isShowImageCrop,
    src,
    isSaving,
  } = useImageCropTools(logoUrl);

  const handleUpload = (files: File[]): void => {
    if (!files.length) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const formData = new FormData();
      formData.set('file', files[0]);

      uploadLogo(formData);
    };
    fileReader.readAsDataURL(files[0]);
  };

  const toolsOnSave = async () => {
    if (blobCropped) {
      toolsOnSaveHandler();
      const formData = new FormData();
      formData.set('file', blobCropped);
      await uploadLogo(formData);
      onSaved();
      toolsOnCloseHandler();
    }
  };

  const toolsOnRevert = (newSrc: string) => {
    toolsOnRevertHandler(newSrc);
  };

  const onConfirm = () => {
    const restrictionLevel =
      type === CBSType.CLOSED ? RestrictionLevel.RESTRICTED : RestrictionLevel.PUBLIC;
    const queryParams = new URLSearchParams({
      email: email ?? '',
      name: companyName ?? '',
      phone: phone ?? '',
      restrictionLevel,
      logo: JSON.stringify(logoObject),
      favicon: JSON.stringify(faviconObject),
      primaryColor,
      secondaryColor,
    });

    if (allowedDomains && restrictionLevel === RestrictionLevel.RESTRICTED) {
      queryParams.set(
        'allowedDomains',
        JSON.stringify(allowedDomains.replace(/\s+/g, '').split(',')),
      );
    }

    document.location.replace(
      `${API_URL}/curated-brand-store/create?${queryParams.toString()}`,
    );
  };

  const onPrimaryColorChange = (color: string) => {
    dispatch(setPrimaryColor(color));
  };

  const onSecondaryColorChange = (color: string) => {
    dispatch(setSecondaryColor(color));
  };

  const debouncedHandleConfirm = useCallback(debounce(onConfirm, 200), [
    email,
    companyName,
    phone,
    type,
    logoObject,
    allowedDomains,
    primaryColor,
    secondaryColor,
  ]);

  const handleConfirm = () => {
    setLoading(true);
    debouncedHandleConfirm();
  };

  const onClose = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent: PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT,
          onConfirm: () => {
            dispatch(closePopup());
            dispatch(resetStoreCreation());
          },
          onCancel: () => {
            dispatch(
              openPopup({
                popupName: UPLOAD_CBS_LOGO_POPUP,
                popupProps: {},
              }),
            );
          },
        },
      }),
    );
  };

  const onGoBack = () =>
    dispatch(openPopup({ popupName: OPEN_CLOSED_SITE_POPUP, popupProps: {} }));

  const isDisabled = loading || !logoUrl || !primaryColor || !secondaryColor;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>{title}</DialogTitle>

        <div className={css.dialogBody}>
          <DialogDescription>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              Drag and drop your customers logo and add select their colors.
            </Typography>

            <ImageUpload
              wrapperClassName={css.imageUpload}
              previewClassName={css.previewImage}
              url={src}
              onUpload={handleUpload}
              buttonText={logoUrl ? 'Re-Upload' : 'Select File'}
              additionalText="JPG, PNG or JPEG, no more than 1MB"
              buttonClassName={css.uploadBtn}
              controlClassName={css.uploadControl}
              imageCropProps={{
                src: src || '',
                onLoad: imageCropOnLoadHandler,
                onImageCropped: imageCropOnCroppedHandler,
                scale,
              }}
              withImageCrop={true}
              isShowImageCrop={isShowImageCrop}
              onShowImageCrop={setIsVisibleImageCrop}
            />
            {isShowImageCrop && (
              <ImageCropTools
                wrapperClassName={css.imageUploadTools}
                src={src || ''}
                scale={scale}
                onRevert={toolsOnRevert}
                onScale={toolsOnScaleHandler}
                fitScale={fitScale}
                onSave={toolsOnSave}
                onClose={toolsOnCloseHandler}
                wrapInWhiteBox
                maxScalePercent={200}
                isSaving={isSaving}
              />
            )}

            {!isShowImageCrop && (
              <ColorsSelector
                primaryColorPickerWrapperClassName={
                  css.primaryColorPickerWrapperClassName
                }
                secondaryColorPickerWrapperClassName={
                  css.secondaryColorPickerWrapperClassName
                }
                className={css.colors}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryColorLabel="Select your primary color"
                secondaryColorLabel="Select your secondary color"
                onPrimaryChange={onPrimaryColorChange}
                onSecondaryChange={onSecondaryColorChange}
              />
            )}
          </DialogDescription>
        </div>

        <DialogFooter className={css.footer}>
          <span onClick={onGoBack} className={css.goBack}>
            <Typography
              fontType="bodyMdBold"
              lineHeight="162.5%"
              color={COLORS.colorPink}
              link
            >
              Go Back
            </Typography>
          </span>
          <SwagButton type="outlined" onClick={onClose} className={css.button}>
            Cancel
          </SwagButton>
          <SwagButton
            loading={loading}
            type="primary"
            disabled={isDisabled}
            onClick={handleConfirm}
            className={css.button}
          >
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
