import {
  Typography,
  SkeletonWrapper,
  SkeletonNotReady,
  SkeletonReady,
  Skeleton,
  Loader,
} from '@ezetech/swag-space-x';

import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { PageContent } from 'components/page-content';
import COLORS from 'constants/styles/colors-js.constant.module.scss';

import { ROUTES } from 'constants/router';
import { Price } from 'utils/price.util';
import { useGetDashboardQuery } from '../../redux/api/dashboard.api';
import { useAppSelector } from '../../redux/store';
import { dashboardSelector } from '../../redux/selectors/dashboard.selectors';
import { can, SUBJECTS } from '../../boot/ability';
import { SingleTrendCard } from '../../components/single-trend-card';
import { OrdersListCard } from './orders-list-card';
import { TierCard } from './tier-card';

import css from './dashboard.module.scss';

export function DashboardComponent(): JSX.Element {
  const { isLoading } = useGetDashboardQuery();
  const data = useAppSelector(dashboardSelector);
  const upFromYesterdayText = 'up from yesterday';
  const downFromYesterdayText = 'down from yesterday';

  const subjectOrdersName = SUBJECTS.VIEW_ORDERS.value;
  const subjectOrdersActions = SUBJECTS.VIEW_ORDERS.actions;

  const subjectCLName = SUBJECTS.LEADS.value;
  const subjectCLActions = SUBJECTS.LEADS.actions;

  return (
    <SkeletonWrapper isReady={!isLoading}>
      <SkeletonReady>
        <PageContent
          additionalComponent={
            <Typography
              className={css.smallText}
              lineHeight="162%"
              fontType="bodyMd"
              color={COLORS.colorGrey1}
            >
              What's happening today, {getTimeMmmDdYyyy(new Date())}...
            </Typography>
          }
        >
          <div className={css.grid}>
            <SingleTrendCard
              className={css.boxTotal}
              title="Total Sales"
              titleLink={
                can(subjectOrdersActions.VIEW_ALL_ORDERS, subjectOrdersName)
                  ? 'View Orders'
                  : ''
              }
              value={`${Price.formatPrice(data.sales.total)}`}
              valueDescription={`${data.sales.ordersCount} Orders`}
              percent={data.sales.percent}
              percentText={
                data.sales.percent >= 0 ? upFromYesterdayText : downFromYesterdayText
              }
              link={ROUTES.MY_STORE_ORDERS}
            />
            <SingleTrendCard
              className={css.boxLeads}
              title="New Leads"
              titleLink={
                can(subjectCLActions.VIEW_LEADS_LIST, subjectCLName) ? 'View Leads' : ''
              }
              value={data.leads.total}
              percent={data.leads.percent}
              percentText={
                data.leads.percent >= 0 ? upFromYesterdayText : downFromYesterdayText
              }
              link={ROUTES.LEADS}
            />
            <OrdersListCard className={css.boxOrders} orders={data.orders} />
            <TierCard className={css.boxTier} />
          </div>
        </PageContent>
      </SkeletonReady>
      <SkeletonNotReady>
        <Typography lineHeight="262%">
          <Skeleton width="70%" />
        </Typography>
        <div className={css.grid}>
          <Skeleton height={253} className={css.boxTotal} />
          <Skeleton variant="rectangular" height={253} className={css.boxLeads} />
          <Skeleton variant="rectangular" height={258} className={css.boxOrders} />
          <Skeleton variant="rectangular" height={504} className={css.boxTier} />
        </div>
        <Loader />
      </SkeletonNotReady>
    </SkeletonWrapper>
  );
}
