import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
  Select,
  OptionType,
} from '@ezetech/swag-space-x';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { InputForm } from 'components/input-form';
import { PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT } from 'constants/common';
import { domainsListRegex } from 'constants/validation';
import { useAppDispatch } from 'redux/store';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { typeSelector } from 'redux/selectors/curated-brand-sites.selector';
import {
  resetStoreCreation,
  setAllowedDomains,
  setType,
} from 'redux/slices/curated-brand-sites.slice';
import { ICommonPopupProps } from 'interfaces/popup.interface';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { CBSType } from 'constants/curated-brand-store';
import {
  CBS_CUSTOMER_INFORMATION_POPUP,
  CONFIRMATION_POPUP,
  OPEN_CLOSED_SITE_POPUP,
  UPLOAD_CBS_LOGO_POPUP,
} from '../_logic/popups-list';

import css from './open-closed-site.popup.module.scss';

const title = 'Access';

enum SITE_TYPE_LABEL {
  OPENED = 'Open',
  CLOSED = 'Closed',
}

const SITE_TYPES_OPTIONS = [
  { label: SITE_TYPE_LABEL.OPENED, value: CBSType.OPENED },
  { label: SITE_TYPE_LABEL.CLOSED, value: CBSType.CLOSED },
];

const FIELDS = {
  type: {
    label: 'Access',
    name: 'type',
  },
  allowedDomains: {
    label: 'Please enter the allowed domains separated by a comma',
    name: 'allowedDomains',
    placeholder: 'e.g. @fb.com, @facebook.com, @meta.com',
    rules: {
      required: 'Allowed domains are required',
      pattern: {
        value: domainsListRegex,
        message: "Domains you entered don't seem to be valid",
      },
    },
  },
};

export const CONTENT_ID = 'open-closed-container';

export const OpenClosedSitePopup = ({ isOpen }: ICommonPopupProps): JSX.Element => {
  const [rect, setRect] = useState<DOMRect | undefined>();
  const type = useSelector(typeSelector);
  const dispatch = useAppDispatch();
  const { control, formState, getValues, setValue, watch, clearErrors } =
    useForm<FieldValues>({
      mode: 'onBlur',
    });

  const getClientRect = (): DOMRect | undefined => {
    const container = document.getElementById(CONTENT_ID);

    return container?.getBoundingClientRect();
  };

  useEffect(() => {
    setRect(isOpen ? getClientRect() : undefined);
  }, [isOpen]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === FIELDS.allowedDomains.name) {
        dispatch(setAllowedDomains(value.allowedDomains));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onConfirm = () => {
    dispatch(
      openPopup({
        popupName: UPLOAD_CBS_LOGO_POPUP,
        popupProps: {},
      }),
    );
  };

  const onChange = (option: OptionType | MultiValue<OptionType | null> | null) => {
    if ((option as OptionType).value === CBSType.OPENED) {
      setValue(FIELDS.allowedDomains.name, '');
      clearErrors(FIELDS.allowedDomains.name);
    }

    dispatch(setType((option as OptionType).value as CBSType));
  };

  const onClose = () => {
    dispatch(
      openPopup({
        popupName: CONFIRMATION_POPUP,
        popupProps: {
          descriptionComponent: PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT,
          onConfirm: () => {
            dispatch(closePopup());
            dispatch(resetStoreCreation());
          },
          onCancel: () => {
            dispatch(
              openPopup({
                popupName: OPEN_CLOSED_SITE_POPUP,
                popupProps: {},
              }),
            );
          },
        },
      }),
    );
  };

  const onGoBack = () =>
    dispatch(openPopup({ popupName: CBS_CUSTOMER_INFORMATION_POPUP, popupProps: {} }));

  const { isValid } = formState;
  const allowedDomainsEntered = Boolean(getValues(FIELDS.allowedDomains.name)?.length);
  const isTypeClosed = type === CBSType.CLOSED;

  const isDisabled = isTypeClosed && (!isValid || !allowedDomainsEntered);

  const handleOpenMenu = () => {
    setRect(getClientRect());
  };

  const calculatePosition = ({ left, top }: Record<string, unknown>) => {
    return {
      left: (left as number) - (rect?.x || 0),
      top: (top as number) - (rect?.y || 0),
    };
  };

  const handleClearErrors = (path: string) => () => {
    clearErrors(path);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContentWrapper
        id={CONTENT_ID}
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIcon}>{title}</DialogTitle>
        <div className={cn(css.dialogBody, { [css.scrollableOverflow]: isTypeClosed })}>
          <DialogDescription>
            <Typography
              color={COLORS.colorTextSubdued}
              fontType="bodyMd"
              lineHeight="162.5%"
            >
              There are two different options when creating a curated brand site, open or
              closed. An open site means that anybody that has access to the link can
              order swag. A closed site means that you need to have an approved email
              domain in order to get access to the site.
            </Typography>
            <div className={css.inputForm}>
              <Select
                options={SITE_TYPES_OPTIONS}
                className={css.input}
                errorClassName={css.errorClassName}
                label={FIELDS.type.label}
                name={FIELDS.type.name}
                value={{ label: SITE_TYPE_LABEL[type], value: type }}
                onChange={onChange}
                menuPosition="fixed"
                menuPlacement="auto"
                menuShouldBlockScroll
                styles={{
                  menuPortal: (style) => ({
                    ...style,
                    ...calculatePosition(style),
                  }),
                }}
                onMenuOpen={handleOpenMenu}
              />
              {isTypeClosed && (
                <InputForm
                  autoComplete="off"
                  type="text"
                  label={FIELDS.allowedDomains.label}
                  errorClassName={css.errorClassName}
                  labelClassName={css.labelClassName}
                  name={FIELDS.allowedDomains.name}
                  placeholder={FIELDS.allowedDomains.placeholder}
                  rules={{
                    ...FIELDS.allowedDomains.rules,
                    onChange: handleClearErrors(FIELDS.allowedDomains.name),
                  }}
                  control={control}
                />
              )}
            </div>
          </DialogDescription>
        </div>

        <DialogFooter className={css.footer}>
          <span onClick={onGoBack} className={css.goBack}>
            <Typography
              fontType="bodyMdBold"
              lineHeight="162.5%"
              color={COLORS.colorPink}
              link
            >
              Go Back
            </Typography>
          </span>
          <SwagButton type="outlined" onClick={onClose} className={css.button}>
            Cancel
          </SwagButton>
          <SwagButton
            type="primary"
            onClick={onConfirm}
            disabled={isDisabled}
            className={css.button}
          >
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
