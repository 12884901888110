import {
  CellSize,
  CellTextColor,
  CellTextWeight,
  ICell,
  MultiOptionType,
} from '@ezetech/swag-space-x';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import { IAssignedForReseller } from './assignee.interface';

export enum CartTypeEnum {
  PRE_BUILT_LIMITED = 'PRE_BUILT_LIMITED',
  PRE_BUILT_REGULAR = 'PRE_BUILT_REGULAR',
  REGULAR = 'REGULAR',
}

export enum CartStatusEnum {
  PENDING = 'PENDING',
  ORDERED = 'ORDERED',
}

export interface ICreateACartReq {
  email: string;
  type: CartTypeEnum;
}

export const CART_FILTER_OPTIONS = [
  { label: 'My Carts', value: ASSIGNED_TO_ME_ENTITY },
  { label: 'Status: Pending', value: CartStatusEnum.PENDING },
  { label: 'Status: Ordered', value: CartStatusEnum.ORDERED },
];

export enum CartsFields {
  id = 'id',
  customerEmail = 'customerEmail',
  companyName = 'companyName',
  products = 'products',
  cartTotal = 'cartTotal',
  createdAt = 'createdAt',
  link = 'link',
  cartType = 'cartType',
  manage = 'manage',
  notes = 'notes',
  assignTo = 'assignTo',
}

export enum CartsActions {
  copy = 'copy',
  redirect = 'redirect',
  openNotes = 'openNotes',
}

export const CARTS_TABLE_HEADER = [
  { text: '#', id: CartsFields.id, size: CellSize.small },
  { text: 'Email', id: CartsFields.customerEmail, size: CellSize.large },
  { text: 'Company', id: CartsFields.companyName, size: CellSize.large },
  { text: 'Products', id: CartsFields.products, size: CellSize.large },
  {
    text: 'Cart total',
    id: CartsFields.cartTotal,
    size: CellSize.large,
    withSorting: true,
  },
  {
    text: 'Created',
    id: CartsFields.createdAt,
    size: CellSize.compact,
    withSorting: true,
  },
  { text: 'Link', id: CartsFields.link, size: CellSize.compact },
  {
    text: 'Cart Type',
    id: CartsFields.cartType,
    size: CellSize.medium,
  },
  { text: 'Notes', id: CartsFields.notes, size: CellSize.compact },
  { text: 'Assign to', id: CartsFields.assignTo, size: CellSize.extraLarge },
  { text: 'Manage', id: CartsFields.manage, size: CellSize.extraLarge },
];

export const CARTS_TABLE_ROW = {
  [CartsFields.id]: { size: CellSize.small, textWeight: CellTextWeight.extraBold },
  [CartsFields.customerEmail]: {
    size: CellSize.large,
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.pink,
    onClick: CartsActions.copy,
    actionable: true,
  },
  [CartsFields.companyName]: { size: CellSize.large, textColor: CellTextColor.pink },
  [CartsFields.products]: {
    size: CellSize.large,
    textColor: CellTextColor.pink,
    actionable: true,
  },
  [CartsFields.cartTotal]: {
    textWeight: CellTextWeight.bold,
    textColor: CellTextColor.primary,
    size: CellSize.large,
  },
  [CartsFields.createdAt]: { size: CellSize.compact },
  [CartsFields.link]: {
    size: CellSize.compact,
    textColor: CellTextColor.pink,
    onClick: CartsActions.redirect,
    actionable: true,
  },
  [CartsFields.cartType]: { size: CellSize.medium },
  [CartsFields.notes]: {
    size: CellSize.compact,
    textColor: CellTextColor.pink,
    onClick: CartsActions.openNotes,
    actionable: true,
  },
  [CartsFields.assignTo]: { size: CellSize.extraLarge },
  [CartsFields.manage]: { size: CellSize.extraLarge },
};

export interface IGetCartsRequest {
  search: string | null;
  page: number;
  perPage: number;
  sorting: string;
  sortingOrder: 'ASC' | 'DESC';
  dropdown: MultiOptionType;
}

export interface IGetCartsResponse {
  carts: ICart[];
  total: number;
  page: number;
  perPage: number;
}

export interface ICart {
  id: string;
  email: string;
  type: CartTypeEnum;
  cartTotal: number;
  hasNotes: boolean;
  createdAt: Date;
  items: IPrebuiltItem[];
  companyName: string | null;
  absoluteCartLinkToShare: string | null;
  placedCartOrderNum: string | null;
  assignee?: IAssignedForReseller | null;
}

export interface IPrebuiltItem {
  num: number;
  name: string;
  imageUrl: string;
  quantity: number;
  productName: string;
  variants: ICartItemVariant[];
  boxItemsId: string;
  itemPublicId: string;
  inventoryName: string;
  asRelatedItemId: string;
  productPublicId: string;
  inventoryPublicId: string;
  addedByPublicUserId: string;
  pricePerItem: number;
  productionTime: string;
  setupFee: number;
  estInHand: {
    from: string;
    to: string;
  };
  sizes?: string[];
  productType: string;
}

export interface ICartItemVariant {
  color: string;
  name: string;
  quantities: number[];
}

export interface ITransformCartCell extends Omit<ICell, 'onClick' | 'id'> {
  id: CartsFields;
  onClick?: CartsActions;
  isNonClickable?: boolean;
  assignedToUser?: { value: string; label: string } | null;
}

export interface ICartProductDetailsVariant {
  colorValue: string | null;
  colorName: string | null;
  quantity?: number;
  size?: string[];
}

export interface ICartProductDetailsCommon {
  id: string;
  name: string;
  quantity: number;
  pricePerItem?: string;
  productionTime?: string;
  estInHand?: string;
  image?: string;
  fullName?: string;
}

export interface ICartProductDetails extends ICartProductDetailsCommon {
  variants: ICartProductDetailsVariant[];
}

export interface INote {
  id: string;
  text: string;
  date: string;
}

export interface IProductNote extends INote {
  productName: string;
  img: string;
  notes: INote[];
  itemPublicId: string;
}

export interface ICartNotes {
  generalNotes: INote[];
  individualNotes: IProductNote[];
}
