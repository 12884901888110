import { IFieldConfig } from 'components/popups/edit-curated-brand-site/components/edit-cbs-form/types';
import { domainsListRegex } from 'constants/validation';
import { RestrictionLevel } from 'constants/curated-brand-store';
import { FormField } from 'components/popups/edit-curated-brand-site/components/edit-cbs-form/form.config';

export enum SITE_TYPE_LABEL {
  PUBLIC = 'Open',
  RESTRICTED = 'Closed',
}

export const SITE_TYPES_OPTIONS = [
  { label: SITE_TYPE_LABEL.PUBLIC, value: RestrictionLevel.PUBLIC },
  { label: SITE_TYPE_LABEL.RESTRICTED, value: RestrictionLevel.RESTRICTED },
];

export const storeRestrictionLevelFormConfig: Partial<Record<FormField, IFieldConfig>> = {
  [FormField.RestrictionLevel]: {
    name: FormField.RestrictionLevel,
    label: 'Access',
    dataField: 'restrictionLevel',
  },
  [FormField.AllowedDomains]: {
    name: FormField.AllowedDomains,
    label: 'Please enter the allowed domains separated by a comma',
    dataField: 'allowedDomains',
    placeholder: 'e.g. @fb.com, @facebook.com, @meta.com',
    rules: {
      required: 'Allowed domains are required',
      pattern: {
        value: domainsListRegex,
        message: "Domains you entered doesn't seem to be valid",
      },
    },
  },
};
