import { ReactElement, useEffect, useMemo } from 'react';
import { MultiValue } from 'react-select';
import { FieldValues, useForm } from 'react-hook-form';
import { DialogDescription, OptionType, Select } from '@ezetech/swag-space-x';

import { ExtractSelectOption } from 'interfaces/form.interface';
import { InputForm } from 'components/input-form';
import { MenuPortalStyles, useMenuPortalStyles } from 'hooks/use-menu-portal-styles.hook';
import {
  CBS_FILTER_OPTIONS,
  ICuratedBrandSite,
} from 'interfaces/curated-brand-site.interface';
import { useAppSelector } from 'redux/store';
import {
  faviconObjectSelector,
  logoObjectSelector,
  logoSelector,
} from 'redux/selectors/curated-brand-sites.selector';
import { RestrictionLevel } from 'constants/curated-brand-store';
import css from '../../edit-curated-brand-site.popup.module.scss';
import { editCBSFormConfig, FormField } from './form.config';
import { LogoUploadForm, StoreLinkInput, StoreRestrictionLevelForm } from './components';
import { selectEditFormFields } from './form-fields.selector';

import styles from './edit-cbs-form.module.scss';

interface IProps {
  rectId: string;
  data: ICuratedBrandSite;
  setIsValid: (isValid: boolean) => void;
  onSubmit: (data: FieldValues) => void;
}

export function EditCBSForm({
  rectId,
  data,
  onSubmit,
  setIsValid,
}: IProps): ReactElement {
  const selectStyles: MenuPortalStyles = useMenuPortalStyles(rectId);

  const defaultValues = useMemo(
    () => ({
      ...selectEditFormFields(data),
      [FormField.AllowedDomains]: Array.isArray(data?.allowedDomains)
        ? data.allowedDomains.join(',')
        : '',
    }),
    [data],
  );

  const statusValues = useMemo(
    () =>
      CBS_FILTER_OPTIONS.map((opt) => {
        return { ...opt, label: opt.label.split(': ')[1] };
      }),
    [CBS_FILTER_OPTIONS],
  );

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isValid },
    trigger,
  } = useForm<FieldValues>({
    mode: 'all',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  const logoUrl: string = useAppSelector(logoSelector) || data.logo.url;
  const logo = useAppSelector(logoObjectSelector);
  const favicon = useAppSelector(faviconObjectSelector);

  const [status, primaryColor, secondaryColor, storeLink]: string[] = watch([
    FormField.Status,
    FormField.PrimaryColor,
    FormField.SecondaryColor,
    FormField.StoreLink,
  ]);

  useEffect(() => void reset(selectEditFormFields(data)), [data, reset]);

  const statusValue = statusValues.find(({ value }) => value === status);
  const onStatusChange = (option: OptionType | MultiValue<OptionType | null> | null) => {
    setValue(FormField.Status, (option as ExtractSelectOption<typeof option>)?.value);
  };

  const getDefaultValue = (field?: string | null): string => {
    return field ? (data[field as keyof ICuratedBrandSite] as string) : '';
  };

  // TODO: Refactor fully
  const onPreSubmit = ({ logo: _, ...form }: FieldValues): void => {
    const allowedDomains = JSON.stringify(
      form[FormField.RestrictionLevel] === RestrictionLevel.RESTRICTED
        ? form.allowedDomains?.replace(/\s+/g, '')?.split(',')?.filter(Boolean) || []
        : [],
    );

    const submitData = {
      ...form,
      logo: JSON.stringify(logo),
      favicon: JSON.stringify(favicon),
      allowedDomains,
    };

    onSubmit(submitData);
  };

  return (
    <form id="form" className={styles.form} onSubmit={handleSubmit(onPreSubmit)}>
      <DialogDescription>
        <StoreRestrictionLevelForm
          showDescription={false}
          control={control}
          watch={watch}
          setValue={setValue}
          trigger={trigger}
          rectId={rectId}
        />

        <Select
          options={statusValues}
          className={css.input}
          label={'Status'}
          name={FormField.Status}
          value={statusValue}
          onChange={onStatusChange}
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll
          styles={selectStyles}
        />

        <div className={styles.row}>
          {[editCBSFormConfig[FormField.Email], editCBSFormConfig[FormField.Phone]].map(
            ({ dataField, ...field }) => (
              <InputForm
                {...field}
                key={field?.name}
                autoComplete="off"
                type="text"
                errorClassName={css.errorClassName}
                labelClassName={css.labelClassName}
                shouldTrimInputValue
                control={control}
                defaultValue={getDefaultValue(dataField)}
              />
            ),
          )}
        </div>

        <div className={styles.row}>
          {[editCBSFormConfig[FormField.CompanyName]].map(({ dataField, ...field }) => (
            <InputForm
              {...field}
              key={field?.name}
              autoComplete="off"
              type="text"
              errorClassName={css.errorClassName}
              labelClassName={css.labelClassName}
              control={control}
              defaultValue={getDefaultValue(dataField)}
            />
          ))}

          <StoreLinkInput curatedBrandSite={true} value={storeLink} control={control} />
        </div>

        <LogoUploadForm
          logoUrl={logoUrl}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          setValue={setValue}
        />
      </DialogDescription>
    </form>
  );
}
