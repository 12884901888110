import { Typography } from '@ezetech/swag-space-x';
import { pluralize } from 'utils/string.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ICartProductDetailsVariant } from 'interfaces/cart.interfaces';
import css from './products-list-tooltip.module.scss';

export const ProductListVariants = ({
  variants,
  name,
}: {
  variants: ICartProductDetailsVariant[];
  name?: string;
}) => {
  if (variants.length === 0) {
    return null;
  }

  return (
    <div className={css.row}>
      {name && (
        <Typography
          fontType="bodySmBold"
          lineHeight="143%"
          color={COLORS.colorTypographyHeadings}
          className={css.name}
        >
          {name}
        </Typography>
      )}
      {variants.map(({ colorValue, colorName, size, quantity }, index) => (
        <div key={`${colorValue}-${index}`} className={css.innerRow}>
          {!!colorValue && (
            <Typography
              fontType="bodySm"
              lineHeight="143%"
              color={COLORS.colorTypographyHeadings}
              className={css.colors}
            >
              Color:{' '}
              <span style={{ backgroundColor: colorValue }} className={css.colorIcon} />{' '}
              {colorName}
            </Typography>
          )}
          {!!size?.length && (
            <Typography
              fontType="bodySm"
              lineHeight="143%"
              color={COLORS.colorTypographyHeadings}
            >
              {pluralize(size.length, 'Size', 's', true)}: {size.join(', ')}
            </Typography>
          )}
          {!!quantity && (
            <Typography
              fontType="bodySm"
              lineHeight="143%"
              color={COLORS.colorTypographyHeadings}
            >
              Quantity: {quantity}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};
