import { FC, useEffect } from 'react';
import { Divider, Dropzone, IFile, Typography } from '@ezetech/swag-space-x';
import {
  PdfPresentationEditorPageFieldDefinitionComponentFuncProps,
  PdfPresentationEditorReviewBody,
} from 'interfaces/pdf-presentation';
import { deepGet } from 'utils/deep-get.util';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import {
  WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION,
  WHAT_WE_OFFER_IMAGE_MB_RESTRICTION,
} from 'constants/limits';
import { REVIEWS_LENGTH } from 'constants/pdf-presentation.constants';
import { useProcessFile } from '../../../../../hooks/use-process-file';
import classes from '../../edit-pdf-presentation-layout-popup.module.scss';
import { TextFieldWithHelpText } from '../../../../input-with-help-text';

export const ReviewsForm: FC<
  PdfPresentationEditorPageFieldDefinitionComponentFuncProps
> = ({ values, onChangeValueCreator, setIsExitBlocked }) => {
  const reviews =
    deepGet<Array<PdfPresentationEditorReviewBody | null>>(
      values as never,
      'reviews',
      Array.from<PdfPresentationEditorReviewBody | null>({ length: 6 }).fill(null),
    ) || [];
  const onChangeHandler = onChangeValueCreator('reviews');
  const { handleProcessFile, isLoading } = useProcessFile();
  const handleChangeReview = (index: number, text: string) => {
    const review = {
      ...(deepGet<PdfPresentationEditorReviewBody>(
        values as never,
        `reviews.${index}`,
      ) ?? {
        text: undefined,
        logo: null,
      }),
    };
    review.text = text;
    onChangeHandler(
      reviews.map((r, i) => {
        if (i === index) {
          return review;
        }
        return r;
      }),
    );
  };

  const handleChangeImage = (index: number, files: IFile[]) => {
    const file = files[0] ?? null;
    const review = {
      ...(deepGet<PdfPresentationEditorReviewBody>(
        values as never,
        `reviews.${index}`,
      ) ?? {
        text: undefined,
        logo: null,
      }),
    };
    review.logo = file;
    onChangeHandler(
      reviews.map((r, i) => {
        if (i === index) {
          return review;
        }
        return r;
      }),
    );
  };

  useEffect(() => {
    setIsExitBlocked?.(isLoading);
  }, [isLoading]);

  return (
    <div>
      <div className={classes.dropzoneWrapper}>
        {Array.from({ length: REVIEWS_LENGTH }).map((_, i) => {
          const review = deepGet<PdfPresentationEditorReviewBody>(
            values as never,
            `reviews.${i}`,
          ) ?? {
            text: undefined,
            logo: null,
          };
          return (
            <>
              <div className={classes.reviewContainer}>
                <div className={classes.dropzoneWrapper}>
                  <Typography
                    className={classes.label}
                    color={COLORS.colorPrimaryText}
                    lineHeight="142.857%"
                    fontType="bodySm"
                  >
                    Logo {i + 1}
                  </Typography>
                  <Dropzone
                    accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
                    maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
                    className={classes.dropzone}
                    buttonClassName={classes.dropzoneButton}
                    previewClassName={classes.dropzonePreview}
                    previewButtonsClassName={classes.dropzonePreviewButtons}
                    uploadTextWrapperClassName={classes.dropzoneUploadTextWrapper}
                    files={review.logo ? [review.logo] : []}
                    processFile={handleProcessFile}
                    onFilesChanged={(files) => handleChangeImage(i, files)}
                    additionalText="JPG or PNG, no more than 10MB"
                    helperText="Recommended size is 120 x 120 pixels"
                    helperTextClassName={classes.dropzoneHelperText}
                  />
                </div>
                <TextFieldWithHelpText
                  name={`review_${i + 1}`}
                  label={`Review ${i + 1}`}
                  placeholder="e.g. The biggest value of Jenny Promo is the hundreds of hours that my team has saved in sourcing and producing our custom swag with an expansive inventory, a simple to use platform, and unparalleled customer support."
                  value={review.text}
                  onChange={({ target: { value } }) =>
                    handleChangeReview(i, value.slice(0, 375))
                  }
                  helpText="Max 375 characters"
                  {...{ rows: 6 }}
                />
              </div>
              {i !== REVIEWS_LENGTH - 1 && <Divider />}
            </>
          );
        })}
      </div>
    </div>
  );
};
