import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/router';
import { EmptyState } from 'components/empty-state';

import css from './order-empty-state.module.scss';

export const OrderEmptyState = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className={css.emptyStateWrapper}>
      <EmptyState
        title="Woah, it’s quiet in here"
        description="Make your first sale and then come back here."
        button="Back to Dashboard"
        onClick={handleClick}
      />
    </div>
  );
};
