import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MultiOptionType,
  DateRange,
  OptionType,
  OptionsGroupedType,
} from '@ezetech/swag-space-x';

import {
  IOrderTypeParam,
  IOrderCSVExportRequestParams,
} from 'interfaces/orders.interface';
import { can, SUBJECTS } from 'boot/ability';
import { usePrevious } from 'hooks/use-previous.hook';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import { filtersRequestSelector } from 'redux/selectors/filters.selectors';
import { CLIENT_STATUSES } from 'enums/orders.enum';
import { setFilter } from 'redux/slices/filter.slice';
import { assignmentFlowEnabledSelector } from 'redux/selectors/settings.selectors';
import { useGetOrdersMutation } from 'redux/api/orders.api';

import {
  DEFAULT_PAGE,
  DEFAULT_SORTING_ORDER,
  MY_ORDERS,
  MY_ORDERS_LABEL,
  PER_PAGE_RECORDS,
} from 'constants/common';

const ALWAYS_EXISTED_FILTERS: OptionType[] | OptionsGroupedType[] = Object.values(
  CLIENT_STATUSES,
).map((status) => ({ label: `Status: ${status}`, value: status }));

const OPTIONAL_FILTERS: OptionType[] | OptionsGroupedType[] = [
  { label: MY_ORDERS_LABEL, value: MY_ORDERS },
];

type useMyOrdersProp = {
  type: IOrderTypeParam;
  csvFileUpload: (params: IOrderCSVExportRequestParams) => void;
};

export const useMyOrders = ({ type, csvFileUpload }: useMyOrdersProp) => {
  const canViewAllOrders = can(
    SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
    SUBJECTS.VIEW_ORDERS.value,
  );
  const location = useLocation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filtersRequestSelector);
  const isOrderAssignmentEnabled = useAppSelector(assignmentFlowEnabledSelector);
  const [searchValue, setSearchValue] = useState<string>('');
  const prevSearchValue = usePrevious({
    value: searchValue,
    shouldUpdate: true,
    initValue: '',
  });
  const [getOrders, { isLoading }] = useGetOrdersMutation();

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const action = urlParams.get('action');
  const orderNum = urlParams.get('orderNum');
  const orderId = urlParams.get('orderId');
  const isActionSearchByNum = action === ACTION_TOKEN_TYPES.GET_ORDER_BY_NUM_ACTION;

  const ORDER_FILTERS = [
    ...(isOrderAssignmentEnabled ? OPTIONAL_FILTERS : []),
    ...ALWAYS_EXISTED_FILTERS,
  ];

  useEffect(() => {
    if (canViewAllOrders) {
      getOrders({
        type,
        page: DEFAULT_PAGE,
        perPage: PER_PAGE_RECORDS,
        search: orderId ? orderId : '',
        sorting: '',
        sortingOrder: DEFAULT_SORTING_ORDER,
        dropdown: isOrderAssignmentEnabled
          ? [{ label: MY_ORDERS_LABEL, value: MY_ORDERS }]
          : [],
      });
    }
  }, [getOrders]);

  useEffect(() => {
    if (isActionSearchByNum) {
      dispatch(setFilter({ search: orderNum ?? '' }));
      setSearchValue(orderNum ?? '');
    }
  }, [isActionSearchByNum]);

  useEffect(() => {
    if (prevSearchValue !== searchValue) {
      getOrders({ ...filters, type, search: searchValue, page: 1 });
    }
  }, [searchValue, getOrders]);

  const handlePageClick = useCallback(
    (selectedPage: number) => {
      getOrders({ ...filters, type, page: selectedPage });
    },
    [getOrders],
  );

  const handleSearchChange = (searchVal: string) => {
    if (prevSearchValue !== searchVal) {
      setSearchValue(searchVal);
    }
  };

  const handleFilterClick = useCallback(
    (selected: MultiOptionType) => {
      getOrders({ ...filters, type, dropdown: selected, page: 1 });
    },
    [getOrders],
  );

  const handleExportCSV = useCallback(
    ([startDate, endDate]: DateRange) => {
      const { search, dropdown, sorting, sortingOrder } = filters;
      csvFileUpload({
        search,
        sorting,
        sortingOrder,
        dropdown,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      });
    },
    [csvFileUpload],
  );

  return {
    handlePageClick,
    handleSearchChange,
    handleFilterClick,
    handleExportCSV,
    ORDER_FILTERS,
    isLoading,
  };
};
