import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MultiValue } from 'react-select';
import { OptionType } from '@ezetech/swag-space-x';

import { RootState } from 'redux/store';
import { ReducerPath } from 'interfaces';
import {
  IActionOrderRequest,
  IApproveColorsRequest,
  IApproveMockupRequest,
  IOrder,
  IOrderRequest,
  IOrderResponse,
  IRejectMockupRequest,
  ORDER_PAYMENT_STATUS_CODE,
  IOrderTypeParam,
} from 'interfaces/orders.interface';
import { closePopup, openPopup } from 'redux/slices/modals.slice';
import { setMyOrdersCount } from 'redux/slices/carts.slice';
import { getDashboard } from 'redux/slices/dashboard.slice';
import {
  RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED,
  RESELLER_MOCKUP_APPROVED,
} from 'components/popups/_logic/popups-list';
import {
  getPropsForActionPopup,
  shouldOpenActionPopup,
  shouldShowSuccessNotification,
} from 'utils/action-token.util';
import { MY_ORDERS } from 'constants/common';

import { makeSuccessToastNotification, makeToastNotification } from 'utils/query.util';
import { patchOrder, setOrders, updateOrder } from '../slices/orders.slice';
import { updateCustomerCompanyItemStatus } from '../slices/customer-company.slice';
import { setFilter } from '../slices/filter.slice';
import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const ordersApi = createApi({
  reducerPath: ReducerPath.orders,
  baseQuery: fetchBaseQuery(baseQueryParams),
  endpoints: (builder) => ({
    getOrders: builder.mutation<IOrderResponse, IOrderRequest>({
      query: ({ dropdown, ...rest }) => {
        const filter: Record<string, string[]> = {};

        (dropdown as MultiValue<OptionType>).forEach(({ value }) => {
          if ([MY_ORDERS].includes(value)) {
            filter.orders = [value];
            return;
          }
          filter.status = [...(filter.status || []), value];
        });

        return {
          url: '/order/list',
          method: 'GET',
          params: { ...rest, filter: JSON.stringify(filter) },
        };
      },
      async onQueryStarted({ dropdown, type }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { list, page, ordersTotal, selectionTotal, perPage } = data;
            dispatch(setOrders({ list, total: ordersTotal }));
            dispatch(setFilter({ page, perPage, dropdown, totalCount: selectionTotal }));

            if (type === IOrderTypeParam.curatedBrandStore) {
              dispatch(setMyOrdersCount({ cbsOrders: selectionTotal }));
            } else {
              dispatch(setMyOrdersCount({ orders: selectionTotal }));
            }
          }
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getActionOrder: builder.query<IOrderResponse, IActionOrderRequest>({
      query: ({ action, orderId, itemId, token }) => ({
        url: '/order/action',
        method: 'GET',
        params: { action, token, orderId, itemId },
      }),
      async onQueryStarted(
        { action, orderId, itemId },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            const { list, page, ordersTotal, selectionTotal, perPage } = data;
            dispatch(setOrders({ list, total: ordersTotal }));
            dispatch(setMyOrdersCount({ orders: selectionTotal }));
            dispatch(setFilter({ page, perPage, totalCount: selectionTotal }));

            const statusCode = list?.[0]?.items?.find((i) => i.id === itemId)?.statusCode;

            if (!statusCode) {
              return;
            }

            if (shouldOpenActionPopup({ action, statusCode })) {
              const state = getState();
              const { popupName, popupProps } = getPropsForActionPopup({
                action: action || '',
                orderId: orderId || '',
                itemId: itemId || '',
                state: state as RootState,
              });

              dispatch(openPopup({ popupName, popupProps }));
            } else if (shouldShowSuccessNotification({ action, statusCode })) {
              dispatch(
                pushNotification(
                  makeSuccessToastNotification('This item has been approved'),
                ),
              );
            }
          }
        } catch (e) {
          if (
            (e as { status?: number }).status !== 401 ||
            (e as { statusCode?: number }).statusCode !== 401
          ) {
            return;
          }
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    approveMockup: builder.mutation<IOrder, IApproveMockupRequest>({
      query: ({ orderId, itemId, mockupId }) => ({
        url: '/order/mockup/approve',
        method: 'POST',
        body: { orderId, itemId, mockupId },
      }),
      async onQueryStarted(
        { orderId, itemId, isReviewMockupsByCustomerAllowed },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          dispatch(getDashboard());
          dispatch(closePopup());
          dispatch(updateOrder(data));
          dispatch(updateCustomerCompanyItemStatus(data));
          dispatch(
            openPopup({
              popupName: RESELLER_MOCKUP_APPROVED,
              popupProps: { isReviewMockupsByCustomerAllowed },
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    rejectMockup: builder.mutation<IOrder, IRejectMockupRequest>({
      query: (body) => ({
        url: '/order/mockup/reject',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(closePopup());
          dispatch(updateOrder(data));
          dispatch(getDashboard());
          dispatch(
            openPopup({
              popupName: RESELLER_MOCKUP_APPROVAL_REVISIONS_REQUESTED,
              popupProps: {},
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    approvePantoneColors: builder.mutation<IOrder, IApproveColorsRequest>({
      query: (payload) => ({
        url: '/order/approve-pantone-colors',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted({ orderId, itemId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOrder(data));
          dispatch(updateCustomerCompanyItemStatus(data));
          dispatch(getDashboard());
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    retryPayment: builder.mutation<void, IOrder['id']>({
      query: (orderId) => ({
        url: '/order/retry-payment',
        method: 'POST',
        body: { orderId },
      }),
      async onQueryStarted(orderId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            patchOrder({
              id: orderId,
              paymentStatusCode: ORDER_PAYMENT_STATUS_CODE.PAID,
            }),
          );
          dispatch(pushNotification(makeSuccessToastNotification('Payment successful')));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const {
  useGetOrdersMutation,
  useGetActionOrderQuery,
  useApprovePantoneColorsMutation,
  useApproveMockupMutation,
  useRejectMockupMutation,
  useRetryPaymentMutation,
} = ordersApi;
