import { createSelector } from 'reselect';

import { RootState } from '../store';

export const modalsSelector = (state: RootState) => state.modals;

export const popupsSelector = createSelector(modalsSelector, (state) => state.popups);

export const popupNameSelector = createSelector(
  popupsSelector,
  (state) => state.popupName,
);

export const popupSharedPropsSelector = createSelector(
  popupsSelector,
  (state) => state.popupSharedProps,
);

export const popupPropsSelector = createSelector(
  popupsSelector,
  (state) => state.popupProps,
);

export const redirectUrlAfterPopupCloseSelector = createSelector(
  popupsSelector,
  (state) => state.redirectUrlAfterPopupClose,
);
