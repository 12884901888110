export const FREE = 'FREE';
export const PER_PAGE_RECORDS = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_SORTING_ORDER = 'ASC';

export const PERMISSION_ERROR_TOOLTIP_TEXT = 'Permission required for this action.';
export const MISSING_MOCKUP_ERROR_TOOLTIP_TEXT = 'Production mockup is missing.';
export const PROGRESS_LOST_ON_EXIT_TEXT =
  'Are you sure you want to exit? All your progress will be lost if you proceed.';
export const PROGRESS_LOST_ON_EXIT_CBS_CREATION_TEXT =
  'Are you sure you want to exit? All your progress will be lost.';

export const MY_ORDERS = 'MY_ORDERS';
export const MY_ORDERS_LABEL = 'My Orders';

export const ASSIGNED_TO_ME_ENTITY = 'ASSIGNED_TO_ME_ENTITY';
