import { TabsNavigation, Typography } from '@ezetech/swag-space-x';
import { Outlet, useLocation, useNavigate, unstable_useBlocker } from 'react-router-dom';
import { useEffect } from 'react';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ROUTES } from 'constants/router';
import { useGetCompanySettingsMutation } from 'redux/api/settings.api';
import { INTERCOM_DEFAULT_PADDING, INTERCOM_VERTICAL_PADDING } from 'constants/intercom';
import { SaveTooltip } from '../save-tooltip';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  isAnySettingsEditedSelector,
  settingsLayoutTabsSelector,
} from '../../redux/selectors/settings.selectors';
import { closePopup, openPopup } from '../../redux/slices/modals.slice';
import { popupNameSelector } from '../../redux/selectors/modals.selectors';
import { CONFIRMATION_POPUP } from '../popups/_logic/popups-list';
import { useSave, useBeforeunload, useIntercomUpdate } from '../../hooks';

import css from './settings-layout.module.scss';

export function SettingsLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pathname = location.pathname.split('/').reverse()[0];
  const settingsChanged = useAppSelector(isAnySettingsEditedSelector);
  const tabs = useAppSelector(settingsLayoutTabsSelector);
  const popupName = useAppSelector(popupNameSelector);
  const { onSave } = useSave();
  const [getSettings] = useGetCompanySettingsMutation();

  useEffect(() => {
    getSettings();
  }, []);

  useBeforeunload((event: Event) => {
    if (settingsChanged) {
      event.preventDefault();
    }
  });

  const onConfirmSaveClick = ({
    nextRoute,
    currentRoute,
  }: {
    nextRoute: string;
    currentRoute: string;
  }) => {
    onSave({
      onSavedCallback: () => {
        dispatch(closePopup());

        if (nextRoute) {
          navigate(nextRoute);
        }
      },
      redirectFromRoute: currentRoute,
    });
  };

  unstable_useBlocker(({ nextLocation }) => {
    if (settingsChanged && popupName !== CONFIRMATION_POPUP) {
      dispatch(
        openPopup({
          popupName: CONFIRMATION_POPUP,
          popupProps: {
            title: 'You have unsaved changes',
            descriptionComponent: 'You will lose changes if you don’t press save.',
            confirmText: 'Save changes',
            onConfirm: () => {
              onConfirmSaveClick({
                nextRoute: nextLocation.pathname,
                currentRoute: pathname,
              });
            },
            onCancel: () => {
              dispatch(closePopup());
            },
          },
        }),
      );
      return true;
    }
    return false;
  });

  const handleChangeTab = (tab: string) => {
    const route = Object.values(ROUTES).find((r) => r.endsWith(tab)) || '';
    navigate(route);
  };

  const settings = useSave();
  const { updateIntercom } = useIntercomUpdate();

  useEffect(() => {
    const padding: number = settings.isShown
      ? INTERCOM_VERTICAL_PADDING
      : INTERCOM_DEFAULT_PADDING;

    updateIntercom({ vertical_padding: padding });
  }, [settings.isShown, updateIntercom]);

  return (
    <section>
      <div className={css.heading}>
        <Typography
          className={css.text}
          lineHeight="100%"
          fontType="HEADING3XL"
          color={COLORS.colorDarkBlue}
        >
          COMPANY SETTINGS
        </Typography>
      </div>
      <TabsNavigation
        className={css.tabs}
        selectedTab={pathname}
        onChangeTab={handleChangeTab}
        tabs={tabs}
      />
      <div className={css.outletWrapper}>
        <div className={css.outletWrapperInner}>
          <Outlet />
        </div>
      </div>
      <SaveTooltip {...settings} />
    </section>
  );
}
