import { FC, useEffect } from 'react';
import { Dropzone, IFile, Typography } from '@ezetech/swag-space-x';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { deepGet } from 'utils/deep-get.util';
import {
  WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION,
  WHAT_WE_OFFER_IMAGE_MB_RESTRICTION,
} from 'constants/limits';
import { SingleImageUploaderWithExampleProps } from '../../interfaces/components';
import classes from '../../edit-pdf-presentation-layout-popup.module.scss';
import { useProcessFile } from '../../../../../hooks/use-process-file';

export const SingleImageUploaderWithExample: FC<SingleImageUploaderWithExampleProps> = ({
  values,
  onChangeValueCreator,
  onProcess,
  label,
  keyName,
  placeholder = 'Upload a past works image that you are proud of. This image will give your presentation a pop of liveliness',
  helperText,
  exampleImage,
  setIsExitBlocked,
}) => {
  const file = deepGet<IFile | null>(values as never, keyName, null) || null;
  const { handleProcessFile, isLoading } = useProcessFile();
  const onChangeHandler = onChangeValueCreator(keyName);

  const handleChange = (files: IFile[]) => {
    const f = files[0] ?? null;
    onChangeHandler(f);
  };

  useEffect(() => {
    setIsExitBlocked?.(isLoading);
    onProcess && onProcess(isLoading);
  }, [isLoading]);

  return (
    <div className={classes.singleImageUploaderWithExample}>
      <div className={classes.dropzoneWrapper}>
        <Typography
          className={classes.label}
          color={COLORS.colorPrimaryText}
          lineHeight="142.857%"
          fontType="bodySm"
        >
          {label}
        </Typography>
        <Dropzone
          accept={WHAT_WE_OFFER_IMAGE_ACCEPT_RESTRICTION}
          maxSize={WHAT_WE_OFFER_IMAGE_MB_RESTRICTION}
          className={classes.dropzone}
          buttonClassName={classes.dropzoneButton}
          previewClassName={classes.dropzonePreview}
          previewButtonsClassName={classes.dropzonePreviewButtons}
          uploadTextWrapperClassName={classes.dropzoneUploadTextWrapper}
          files={file ? [file] : []}
          processFile={handleProcessFile}
          onFilesChanged={handleChange}
          additionalText={placeholder}
          helperText={helperText}
          helperTextClassName={classes.dropzoneHelperText}
        />
      </div>
      <div className={classes.exampleImageContainer}>
        <Typography
          className={classes.label}
          color={COLORS.colorPrimaryText}
          lineHeight="142.857%"
          fontType="bodySm"
        >
          Example image
        </Typography>
        <img src={exampleImage} alt="Example image" />
      </div>
    </div>
  );
};
