export enum ROUTES {
  INDEX = '/',
  DASHBOARD = '/',

  SALES_TOOLS = '/sales-tools',
  BUILD_A_CART = '/sales-tools/build-a-cart',
  PDF_PRESENTATION = '/sales-tools/pdf-presentation',
  INVOICE_GENERATION = '/sales-tools/invoice-generation',
  CURATED_BRAND_SITE = '/sales-tools/curated-brand-site',

  CBS_ORDERS = '/cbs-orders',
  MY_STORE_ORDERS = '/your-store-orders',
  ORDERS = '/your-store-orders/',

  CUSTOMERS = '/customers',
  CUSTOMER_PROFILE = '/customers/:id',
  COMPANY_PROFILE = '/company/:id',
  LEADS = '/leads',
  SIGNUP = '/signup',
  LOGIN = '/login',
  PROMOCODES = '/promocodes',
  ABANDONED_CHECKOUTS = '/abandoned-checkouts',

  CREATE_PASSWORD = '/create-password/:token',
  RESET_PASSWORD = '/reset-password',
  PASSWORD_RECOVERY = '/reset-password/:token',

  INVITATION_FORBIDDEN = '/invitation/forbidden',
  INVITATION_INACTIVE = '/invitation/inactive',

  STORE_CREATION_BRAND = '/store-creation/brand',
  STORE_CREATION_PAGE_LINK = '/store-creation/page-link',
  STORE_CREATION_PRODUCT_SELECTION = '/store-creation/product-selection',
  STORE_CREATION_COMMISSION = '/store-creation/commission',
  STORE_CREATION_CONTRACT = '/store-creation/contract',

  SETTINGS_COMPANY_AND_BRAND = '/settings/company-and-brand',
  SETTINGS_PRODUCTS_AND_COMMISSION = '/settings/products-and-commission',
  SETTINGS_PLAN_AND_PAYMENT = '/settings/plan-and-payment',
  SETTINGS_OPERATION_HOURS = '/settings/operation-hours',
  SETTINGS_USER_AND_PERMISSIONS = '/settings/users',

  TEAM_MEMBER_DETAILS_CREATE = '/team-member-details',
  TEAM_MEMBER_DETAILS_EDIT = '/team-member-details/:id',
  SIGN_AGREEMENT = '/sign-agreement',

  REFER_A_MEMBER = '/refer-a-member',
  PROFILE_SETTINGS = '/profile-settings',
  CUSTOMER_COMPANY = '/customer-company/:id',
}

export enum REDIRECT_ROUTES {
  OLD_MY_ORDERS = '/my-orders',
}

export enum ROUTES_NAMES {
  DASHBOARD = 'Dashboard',
  SALES_TOOLS = 'Sales Tools',
  MY_ORDERS = 'My Orders',
  YOUR_STORE = 'Your Store',
  CBS_ORDERS = 'Curated Brand Sites',
  DISTRIBUTIONS = 'Distributions',
  CUSTOMERS_AND_LEADS = 'Customers & Leads',
  ACTIVITY = 'Activity',
  PROMOCODES = 'Promocodes',
  ABANDONED_CHECKOUTS = 'Abandoned Checkouts',
  SETTINGS = 'Company Settings',
  SIGNUP = 'SWAG SPACE',
  LOGIN = 'SWAG SPACE',
  CREATE_PASSWORD = 'SWAG SPACE',
  RESET_PASSWORD = 'SWAG SPACE',
  PASSWORD_RECOVERY = 'SWAG SPACE',
  STORE_CREATION_BRAND = 'SWAG SPACE',
  STORE_CREATION_PAGE_LINK = 'SWAG SPACE',
  STORE_CREATION_PRODUCT_SELECTION = 'SWAG SPACE',
  STORE_CREATION_COMMISSION = 'SWAG SPACE',
  STORE_CREATION_CONTRACT = 'SWAG SPACE',
  SIGN_AGREEMENT = 'SWAG SPACE',
  TEAM_MEMBER_DETAILS_CREATE = 'ADD NEW TEAM MEMBER',
  TEAM_MEMBER_DETAILS_EDIT = 'EDIT TEAM MEMBER PERMISSIONS',
  REFER_A_MEMBER = 'Refer a Member',
  PROFILE_SETTINGS = 'Personal Profile',
  CUSTOMER_COMPANY = 'Customer company Profile',
}

export const PAGE_HEADING = {
  [ROUTES.INDEX]: '',
  [ROUTES.ORDERS]: ROUTES_NAMES.MY_ORDERS,
  [ROUTES.CBS_ORDERS]: ROUTES_NAMES.MY_ORDERS,
  [ROUTES.MY_STORE_ORDERS]: ROUTES_NAMES.MY_ORDERS,
  [ROUTES.SALES_TOOLS]: ROUTES_NAMES.SALES_TOOLS,
  [ROUTES.BUILD_A_CART]: ROUTES_NAMES.SALES_TOOLS,
  [ROUTES.PDF_PRESENTATION]: ROUTES_NAMES.SALES_TOOLS,
  [ROUTES.INVOICE_GENERATION]: ROUTES_NAMES.SALES_TOOLS,
  [ROUTES.CURATED_BRAND_SITE]: ROUTES_NAMES.SALES_TOOLS,
  [ROUTES.CUSTOMERS]: ROUTES_NAMES.CUSTOMERS_AND_LEADS,
  [ROUTES.LEADS]: ROUTES_NAMES.CUSTOMERS_AND_LEADS,
  [ROUTES.CUSTOMER_PROFILE]: '',
  [ROUTES.COMPANY_PROFILE]: '',
  [ROUTES.SETTINGS_COMPANY_AND_BRAND]: '',
  [ROUTES.SETTINGS_PRODUCTS_AND_COMMISSION]: '',
  [ROUTES.SETTINGS_PLAN_AND_PAYMENT]: '',
  [ROUTES.SETTINGS_OPERATION_HOURS]: '',
  [ROUTES.SETTINGS_USER_AND_PERMISSIONS]: '',
  [ROUTES.SIGNUP]: ROUTES_NAMES.SIGNUP,
  [ROUTES.LOGIN]: ROUTES_NAMES.LOGIN,
  [ROUTES.CREATE_PASSWORD]: ROUTES_NAMES.CREATE_PASSWORD,
  [ROUTES.RESET_PASSWORD]: ROUTES_NAMES.RESET_PASSWORD,
  [ROUTES.PASSWORD_RECOVERY]: ROUTES_NAMES.PASSWORD_RECOVERY,

  [ROUTES.STORE_CREATION_BRAND]: ROUTES_NAMES.STORE_CREATION_BRAND,
  [ROUTES.STORE_CREATION_PAGE_LINK]: ROUTES_NAMES.STORE_CREATION_PAGE_LINK,
  [ROUTES.STORE_CREATION_PRODUCT_SELECTION]:
    ROUTES_NAMES.STORE_CREATION_PRODUCT_SELECTION,
  [ROUTES.STORE_CREATION_COMMISSION]: ROUTES_NAMES.STORE_CREATION_COMMISSION,
  [ROUTES.STORE_CREATION_CONTRACT]: ROUTES_NAMES.STORE_CREATION_CONTRACT,
  [ROUTES.PROMOCODES]: ROUTES_NAMES.PROMOCODES,
  [ROUTES.ABANDONED_CHECKOUTS]: ROUTES_NAMES.ABANDONED_CHECKOUTS,
  [ROUTES.SIGN_AGREEMENT]: ROUTES_NAMES.SIGN_AGREEMENT,
  [ROUTES.TEAM_MEMBER_DETAILS_CREATE]: ROUTES_NAMES.TEAM_MEMBER_DETAILS_CREATE,
  [ROUTES.TEAM_MEMBER_DETAILS_EDIT]: ROUTES_NAMES.TEAM_MEMBER_DETAILS_EDIT,
  [ROUTES.INVITATION_FORBIDDEN]: '',
  [ROUTES.INVITATION_INACTIVE]: '',
  [ROUTES.REFER_A_MEMBER]: ROUTES_NAMES.REFER_A_MEMBER,
  [ROUTES.PROFILE_SETTINGS]: ROUTES_NAMES.PROFILE_SETTINGS,
  [ROUTES.CUSTOMER_COMPANY]: ROUTES_NAMES.CUSTOMER_COMPANY,
};

export const NAV_OPTIONS = [
  {
    label: ROUTES_NAMES.DASHBOARD,
    link: ROUTES.DASHBOARD,
  },
  {
    label: ROUTES_NAMES.SALES_TOOLS,
    link: ROUTES.INVOICE_GENERATION,
  },
  {
    label: ROUTES_NAMES.MY_ORDERS,
    link: ROUTES.MY_STORE_ORDERS,
  },
  {
    label: ROUTES_NAMES.CUSTOMERS_AND_LEADS,
    link: ROUTES.CUSTOMERS,
  },
  {
    label: ROUTES_NAMES.ABANDONED_CHECKOUTS,
    link: ROUTES.ABANDONED_CHECKOUTS,
  },
  {
    label: ROUTES_NAMES.PROMOCODES,
    link: ROUTES.PROMOCODES,
  },
];

export const NON_JSON_ENDPOINTS = ['/company-settings/logo'];
