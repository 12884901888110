import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PopupListNames,
  PopupPropsList,
  PopupSharedPropsList,
} from 'interfaces/popup.interface';
import { submitCustomer as submitCustomer } from './customer-details.slice';

const SLICE_NAME = 'modals';

interface IPopups {
  popupName: PopupListNames | null;
  redirectUrlAfterPopupClose: string | null;
  popupProps: PopupPropsList;
  popupSharedProps: PopupSharedPropsList;
}

export interface IModalsSlice {
  popups: IPopups;
}

const initialState: IModalsSlice = {
  popups: {
    popupName: null,
    redirectUrlAfterPopupClose: null,
    popupProps: {},
    popupSharedProps: {},
  },
};

export const modals = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    closePopup: (state) => {
      state.popups.popupName = null;
      state.popups.redirectUrlAfterPopupClose = null;
      state.popups.popupProps = initialState.popups.popupProps;
      state.popups.popupSharedProps = initialState.popups.popupSharedProps;
    },
    closePopupAndRedirectTo: (state, action: PayloadAction<{ route: string }>) => {
      state.popups.popupName = initialState.popups.popupName;
      state.popups.popupProps = initialState.popups.popupProps;
      state.popups.redirectUrlAfterPopupClose = action.payload.route;
      state.popups.popupSharedProps = initialState.popups.popupSharedProps;
    },
    openPopup: (
      state,
      action: PayloadAction<{
        popupName: IPopups['popupName'];
        popupProps: IPopups['popupProps'];
        popupSharedProps?: IPopups['popupSharedProps'];
      }>,
    ) => {
      state.popups.popupName = action.payload.popupName;
      state.popups.popupProps = action.payload.popupProps;
      state.popups.redirectUrlAfterPopupClose = null;
      state.popups.popupSharedProps = {
        ...(state.popups.popupSharedProps || {}),
        ...action.payload.popupSharedProps,
      };
    },
    updateProps: (state, action: PayloadAction<IPopups['popupProps']>) => {
      state.popups.popupProps = action.payload;
    },
    patchProps: (state, action: PayloadAction<IPopups['popupProps']>) => {
      state.popups.popupProps = { ...state.popups.popupProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      submitCustomer.fulfilled,
      (state, action: PayloadAction<{ closePopup: boolean }>) => {
        if (action.payload.closePopup) {
          state.popups.popupName = null;
          state.popups.redirectUrlAfterPopupClose = null;
          state.popups.popupProps = initialState.popups.popupProps;
          state.popups.popupSharedProps = initialState.popups.popupSharedProps;
        }
      },
    );
  },
});

export const modalsReducer = modals.reducer;
export const { openPopup, closePopup, closePopupAndRedirectTo, updateProps, patchProps } =
  modals.actions;
