import { createRef, ReactElement, useEffect } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import cn from 'classnames';
import { Typography } from '@ezetech/swag-space-x';
import { Controller } from 'react-hook-form';

import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { MAX_LINK_CHARACTERS } from 'constants/store-creation.constants';

import { FormField } from '../../form.config';
import { CBS_ROOT_SPACE_DOMAIN, ROOT_SPACE_DOMAIN, STORE_LINK_RULES } from './config';
import styles from './store-link-input.module.scss';

interface IProps {
  value: string;
  control: Control<FieldValues>;
  curatedBrandSite?: boolean;
}

export function StoreLinkInput({
  value,
  control,
  curatedBrandSite = false,
}: IProps): ReactElement {
  const labelRef = createRef<HTMLLabelElement>();

  useEffect(() => {
    const elem = labelRef.current;
    if (elem) {
      elem.dataset.value = (value || 'Enter brand') as string;
    }
  }, [value]);

  return (
    <div>
      <Controller
        name={FormField.StoreLink}
        control={control}
        rules={STORE_LINK_RULES}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className={styles.label}>Store Link*</div>
            <Typography
              className={styles.storeName}
              lineHeight="100%"
              fontType="bodyMd"
              color={COLORS.colorTypographyBodyGreyed}
            >
              <label className={styles.inputSizer} ref={labelRef}>
                <input
                  {...field}
                  type="text"
                  maxLength={MAX_LINK_CHARACTERS + 1}
                  className={cn(styles.storePrefixName, styles.inputEllipsis)}
                  size={1}
                  placeholder="Enter brand"
                />
              </label>
              {curatedBrandSite ? CBS_ROOT_SPACE_DOMAIN : ROOT_SPACE_DOMAIN}
            </Typography>

            {error && <div className={styles.errorMessage}>{error.message}</div>}
          </>
        )}
      />
    </div>
  );
}
