import { ChangeEvent, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import classNames from 'classnames';
import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@ezetech/swag-space-x';

import { usePrevious } from 'hooks/use-previous.hook';
import { useAppDispatch } from 'redux/store';
import { useApproveMockupMutation, useRejectMockupMutation } from 'redux/api/orders.api';
import { closePopup } from 'redux/slices/modals.slice';
import { useIsMobileView } from 'hooks/is-mobile-view.hook';

import { ResellerMockupApprovalPopupProps } from 'interfaces/popup.interface';

import { Buttons } from './buttons';
import { POPUP_MODES, getRevisionsData } from './reseller-mockup-approval-popup.utils';
import { CompareView } from './compare-view';
import { ContentView } from './content-view';
import { PaginationBlock } from './pagination-block';
import css from './reseller-mockup-approval-popup.module.scss';

const animationClasses = {
  enter: css.enter,
  enterActive: css.enterActive,
  exit: css.exit,
  exitActive: css.exitActive,
};

const DELAY_TIME = 350;
const BEHAVIOR = 'smooth';
const TEXTAREA_PLACEHOLDER = 'Write your revisions here...';

export const ResellerMockupApprovalPopup = ({
  productName,
  orderNum,
  orderId,
  itemId,
  customerMockups,
  productionMockup,
  isReviewMockupsByCustomerAllowed,
  isOpen,
}: ResellerMockupApprovalPopupProps): JSX.Element => {
  const [lastPage, setLastPage] = useState<number>(0);
  const [customerMockupPage, setCustomerMockupPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [mode, setMode] = useState<string>(POPUP_MODES.NORMAL);
  const prevMode = usePrevious<string>({
    value: mode,
    shouldUpdate: mode !== POPUP_MODES.COMPARE,
  });
  const [requestText, setRequestText] = useState<string>('');
  const textRevisionRef = useRef<HTMLDivElement>(null);
  const [approveMockup, { isLoading }] = useApproveMockupMutation();
  const [rejectMockup, { isLoading: isRejectLoading }] = useRejectMockupMutation();
  const isMobileView = useIsMobileView(768);
  const dispatch = useAppDispatch();

  const customPageIndicator = `Page ${page} of ${lastPage}`;
  const customerMockupData = customerMockups?.[customerMockupPage - 1];
  const customerMockupImg = customerMockupData?.mockupPreview ?? '';
  const maxCustomerMockupPage = customerMockups?.length ?? 0;
  const maxCustomerMockupPageIndicator = `${customerMockupData?.location}`;
  const isPDF = !!productionMockup?.fileName?.endsWith('.pdf');
  const isSinglePageCustomerMockup = maxCustomerMockupPage === 1;

  const onBtnCloseClick = () => {
    dispatch(closePopup());
  };

  const onTextRevisionMounted = () => {
    if (textRevisionRef?.current) {
      textRevisionRef.current.scrollIntoView({ behavior: BEHAVIOR });
    }
  };

  const handlePageClick = (nextPage: number): void => {
    setPage(nextPage);
  };

  const onCustomerMockupPage = (nextPage: number): void => {
    setCustomerMockupPage(nextPage);
  };

  const onRequestTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRequestText(e?.target?.value ?? '');
  };

  const {
    primaryBtnText,
    secondaryBtnText,
    shouldRenderPrimaryBtn,
    shouldRenderCompareLink,
    onSecondaryBtnClick,
    onPrimaryBtnClick,
    isPrimaryBtnDisabled,
    isSecondaryBtnDisabled,
  } = getRevisionsData({
    mode,
    requestText,
    orderId: orderId || '',
    productionMockup,
    itemId: itemId || '',
    setMode,
    setRequestText,
    prevMode: prevMode || '',
    rejectMockup,
    approveMockup,
    isReviewMockupsByCustomerAllowed,
  });

  const isCompareMode = mode === POPUP_MODES.COMPARE;

  const shouldRenderPaginationBlock =
    !isMobileView && (isPDF || !isSinglePageCustomerMockup);

  return (
    <Dialog open={isOpen} onOpenChange={onBtnCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName}>
          Approve Mockup
        </DialogTitle>
        <DialogDescription className={css.dialogContentWrapper}>
          <SwitchTransition>
            <CSSTransition
              key={String(isCompareMode)}
              timeout={DELAY_TIME}
              classNames={animationClasses}
              unmountOnExit
            >
              <>
                {isCompareMode ? (
                  <CompareView
                    isPDF={isPDF}
                    isCompareMode={isCompareMode}
                    productionMockup={productionMockup}
                    page={page}
                    isMobile={isMobileView}
                    setLastPage={setLastPage}
                    customerMockupImg={customerMockupImg}
                    customerMockupPage={customerMockupPage}
                    onCustomerMockupPage={onCustomerMockupPage}
                    maxCustomerMockupPage={maxCustomerMockupPage}
                    maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
                    lastPage={lastPage}
                    isSinglePageCustomerMockup={isSinglePageCustomerMockup}
                    customPageIndicator={customPageIndicator as string}
                    handlePageClick={handlePageClick}
                  />
                ) : (
                  <ContentView
                    isPDF={isPDF}
                    productionMockup={productionMockup}
                    productName={productName || ''}
                    orderNum={orderNum as string}
                    mode={mode}
                    isMobile={isMobileView}
                    setMode={setMode}
                    onTextRevisionMounted={onTextRevisionMounted}
                    textRevisionRef={textRevisionRef}
                    onRequestTextChange={onRequestTextChange}
                    requestText={requestText}
                    animationClasses={animationClasses}
                    page={page}
                    setLastPage={setLastPage}
                    delayTime={DELAY_TIME}
                    placeholder={TEXTAREA_PLACEHOLDER}
                    customerMockupPage={customerMockupPage}
                    onCustomerMockupPage={onCustomerMockupPage}
                    maxCustomerMockupPage={maxCustomerMockupPage}
                    maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
                    lastPage={lastPage}
                    customPageIndicator={customPageIndicator as string}
                    isReviewMockupsByCustomerAllowed={Boolean(
                      isReviewMockupsByCustomerAllowed,
                    )}
                    handlePageClick={handlePageClick}
                  />
                )}
                {shouldRenderPaginationBlock && (
                  <PaginationBlock
                    hidePMPagination={!isPDF}
                    isSinglePageCustomerMockup={isSinglePageCustomerMockup}
                    isCompareMode={isCompareMode}
                    customerMockupPage={customerMockupPage}
                    onCustomerMockupPage={onCustomerMockupPage}
                    maxCustomerMockupPage={maxCustomerMockupPage}
                    maxCustomerMockupPageIndicator={maxCustomerMockupPageIndicator}
                    lastPage={lastPage}
                    customPageIndicator={customPageIndicator as string}
                    handlePageClick={handlePageClick}
                    page={page}
                    wrapperClassName={classNames(css.paginationBlock, {
                      [css.rightSidePaginationBlock]: !isPDF,
                    })}
                  />
                )}
              </>
            </CSSTransition>
          </SwitchTransition>
        </DialogDescription>

        <DialogFooter className={css.footerButtons}>
          <Buttons
            delayTime={DELAY_TIME}
            primaryBtnText={primaryBtnText}
            secondaryBtnText={secondaryBtnText}
            shouldRenderSecondaryBtn={shouldRenderPrimaryBtn}
            shouldRenderPrimaryBtn={shouldRenderPrimaryBtn}
            shouldRenderCompareLink={shouldRenderCompareLink}
            onSecondaryBtnClick={onSecondaryBtnClick}
            onPrimaryBtnClick={onPrimaryBtnClick}
            isPrimaryBtnDisabled={isPrimaryBtnDisabled}
            isSecondaryBtnDisabled={isSecondaryBtnDisabled}
            animationClasses={animationClasses}
            isLoading={isLoading}
            isRejectLoading={isRejectLoading}
            setMode={setMode}
          />
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
