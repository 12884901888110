/* eslint-disable  spellcheck/spell-checker */
import { createSelector } from 'reselect';

import { RootState } from 'redux/store';
import { monthNames, PER_PAGE_CARTS } from 'constants/carts.constant';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { can } from 'boot/ability';
import { SUBJECTS } from 'constants/ability.constant';
import { ASSIGNED_TO_ME_ENTITY } from 'constants/common';
import {
  IPresentation,
  ITransformPresentationCell,
  PresentationFields,
  PRESENTATIONS_TABLE_ROW,
  PRESENTATION_TABLE_HEADER,
  IPDFCellAdditional,
  PRESENTATION_FILTER_OPTIONS,
} from 'interfaces/pdf-presentation';
import { mapItemIntoDetailedViewProduct } from 'utils/cart.utils';
import { pluralize } from 'utils/string.util';
import { pageFiltersSelector } from './filters.selectors';
import { assignmentFlowEnabledSelector } from './settings.selectors';

export const presentationsSelector = (state: RootState) => state.presentations;

export const presentationsListSelector = createSelector(
  presentationsSelector,
  (state) => state.presentations,
);

export const presentationsHeaderSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    if (!isOrderAssignmentEnabled) {
      return PRESENTATION_TABLE_HEADER.filter(
        (field) => field.id !== PresentationFields.assignTo,
      );
    }
    return PRESENTATION_TABLE_HEADER;
  },
);

export const presentationsRowsSelector = createSelector(
  presentationsListSelector,
  pageFiltersSelector,
  presentationsHeaderSelector,
  (list, page, presentationsHeader) => {
    const startIndexingFrom = (page - 1) * PER_PAGE_CARTS;
    const rows: { cells: ITransformPresentationCell[]; index: number }[] = [];

    list.forEach((presentation: IPresentation, position: number) => {
      const cells: (ITransformPresentationCell & IPDFCellAdditional)[] = [];

      presentationsHeader.forEach((cell) => {
        const common = PRESENTATIONS_TABLE_ROW[cell.id] || {};
        const cellParams = { ...common, rowId: presentation.id };
        const sentAt = presentation.sentAt;
        const customerEmail = presentation.email;
        const cartId = presentation.cartId;
        const hasInvoice = !!presentation.invoiceId;
        const hasCart = !!presentation.prebuiltCartId;
        const isDownloadPDFDisabled = !presentation.items.length;
        const isOrderPlaced = !!presentation.orderNum;
        const isCreateCartLinkDisabled: boolean = hasInvoice || isOrderPlaced;
        const isCreateInvoiceDisabled: boolean = hasCart || isOrderPlaced;
        const assignedToUser = presentation?.assignee
          ? {
              value: presentation.assignee.id,
              label: `${presentation?.assignee.firstName} ${presentation?.assignee.lastName}`,
            }
          : null;

        if (cell.id === PresentationFields.id) {
          cells.push({
            ...cellParams,
            id: cell.id,
            text: `#${position + startIndexingFrom + 1}`,
          });
        }
        if (cell.id === PresentationFields.email) {
          cells.push({
            ...cellParams,
            text: presentation.email,
            id: PresentationFields.email,
          });
        }
        if (cell.id === PresentationFields.products) {
          cells.push({
            ...cellParams,
            text: `${presentation.items.length} ${pluralize(
              presentation.items.length,
              'Product',
              's',
              true,
            )}`,
            id: PresentationFields.products,
          });
        }
        if (cell.id === PresentationFields.createdAt) {
          cells.push({
            ...cellParams,
            text: presentation.createdAt
              ? getTimeMmmDdYyyy(new Date(presentation.createdAt), {
                  day: 'numeric',
                  month: 'short',
                })
              : 'n/a',
            id: PresentationFields.createdAt,
          });
        }
        if (cell.id === PresentationFields.emailStatus) {
          cells.push({
            ...cellParams,
            text: 'email status',
            id: PresentationFields.emailStatus,
            sentAt,
          });
        }
        if (cell.id === PresentationFields.invoice) {
          cells.push({
            ...cellParams,
            id: PresentationFields.invoice,
            text: hasInvoice ? 'Go to' : 'Create',
            customerEmail,
            cartId,
            hasInvoice,
            isCreateInvoiceDisabled,
            isOrderPlaced,
            hasCart,
          });
        }
        if (cell.id === PresentationFields.downloadPDF) {
          cells.push({
            ...cellParams,
            id: PresentationFields.downloadPDF,
            text: '',
            isDownloadPDFDisabled,
          });
        }
        if (cell.id === PresentationFields.assignTo) {
          cells.push({
            ...cellParams,
            id: PresentationFields.assignTo,
            text: '',
            isDownloadPDFDisabled,
            assignedToUser,
          });
        }
        if (cell.id === PresentationFields.manage) {
          cells.push({
            ...cellParams,
            text: 'manage',
            orderNum: presentation.orderNum,
            id: PresentationFields.manage,
          });
        }
        if (cell.id === PresentationFields.createCart) {
          cells.push({
            ...cellParams,
            id: PresentationFields.createCart,
            text: '',
            customerEmail,
            hasCart,
            isCreateCartLinkDisabled,
            isOrderPlaced,
            hasInvoice,
          });
        }
      });

      rows.push({
        cells,
        index: position + 1,
      });
    });

    return rows;
  },
);

export const isPaginationPresentationsVisibleSelector = createSelector(
  presentationsListSelector,
  (list) => list.length > 0,
);

export const selectedPresentationProductsSelector = (presentationId?: string) =>
  createSelector(presentationsListSelector, (presentations) => {
    const presentation = presentations.find((item) => item.id === presentationId);

    if (!presentation) {
      return [];
    }

    return presentation.items.map(mapItemIntoDetailedViewProduct);
  });

export function getFormattedSentDate(date: Date) {
  const day = date.getDate();
  const suffix =
    day >= 11 && day <= 13
      ? 'th'
      : day % 10 === 1
        ? 'st'
        : day % 10 === 2
          ? 'nd'
          : day % 10 === 3
            ? 'rd'
            : 'th';

  const month = monthNames[date.getMonth()];

  return `${month} ${day}${suffix}`;
}

export const getPresentationByEmail = (customerEmail?: string) =>
  createSelector(presentationsListSelector, (presentations): string => {
    const presentation = presentations.find((item) => item.email === customerEmail);

    if (!presentation) {
      return '';
    }

    return presentation.cartId;
  });

export const presentationsFilterOptionsSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForPresentation = can(
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.ASSIGN_PDF_PRESENTATION,
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
    );

    if (!isOrderAssignmentEnabled && isAbleToBeAssignedForPresentation) {
      return PRESENTATION_FILTER_OPTIONS.filter(
        (option) => option.value !== ASSIGNED_TO_ME_ENTITY,
      );
    }
    return PRESENTATION_FILTER_OPTIONS;
  },
);

export const initialPresentationsFiltersSelector = createSelector(
  assignmentFlowEnabledSelector,
  (isOrderAssignmentEnabled) => {
    const isAbleToBeAssignedForPresentation = can(
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.actions.ASSIGN_PDF_PRESENTATION,
      SUBJECTS.CREATE_EDIT_PDF_PRESENTATION.value,
    );

    if (isOrderAssignmentEnabled && isAbleToBeAssignedForPresentation) {
      return PRESENTATION_FILTER_OPTIONS.filter(
        (option) => option.value === ASSIGNED_TO_ME_ENTITY,
      );
    }
    return [];
  },
);
