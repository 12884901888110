import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReducerPath, IDashboardData } from 'interfaces';
import { makeToastNotification } from 'utils/query.util';
import { setMyOrdersCount } from 'redux/slices/carts.slice';

import { setDashboard } from '../slices/dashboard.slice';
import { pushNotification } from '../slices/notifications.slice';
import { baseQueryParams } from './helpers';

export const dashboardApi = createApi({
  reducerPath: ReducerPath.dashboard,
  baseQuery: fetchBaseQuery({
    ...baseQueryParams,
  }),
  endpoints: (builder) => ({
    getDashboard: builder.query<IDashboardData, void>({
      query: () => ({
        url: '/dashboard',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDashboard(data));
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),

    getMyOrdersCounts: builder.query<
      {
        carts: number;
        orders: number;
        presentations: number;
        invoices: number;
        curatedBrandStores: number;
        curatedBrandStoreOrders: number;
      },
      void
    >({
      query: () => ({
        url: '/dashboard/my-orders-counts',
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            setMyOrdersCount({
              carts: data.carts,
              orders: data.orders,
              presentations: data.presentations,
              invoices: data.invoices,
              curatedBrandStores: data.curatedBrandStores,
              cbsOrders: data.curatedBrandStoreOrders,
            }),
          );
        } catch (e) {
          dispatch(pushNotification(makeToastNotification(e)));
        }
      },
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
