import { storeNameRegexp } from 'constants/validation';
import { MAX_LINK_CHARACTERS } from 'constants/store-creation.constants';

export const ROOT_SPACE_DOMAIN = '.swag.space';
export const CBS_ROOT_SPACE_DOMAIN = '.swag.site';

export const STORE_LINK_RULES = {
  required: 'Store Link is required',
  pattern: {
    value: storeNameRegexp,
    message: 'Link can include alphanumeric characters (a-z, 0-9) and dashes (-).',
  },
  maxLength: {
    value: MAX_LINK_CHARACTERS,
    message: `Maximum ${MAX_LINK_CHARACTERS} characters`,
  },
};
