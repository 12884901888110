import { ACTION_TOKEN_TYPES } from 'constants/action-tokens.constant';
import {
  RESELLER_MOCKUP_PRE_APPROVAL,
  REVIEW_COLORS_POPUP,
} from 'components/popups/_logic/popups-list';
import {
  getInfoForReviewColors,
  itemDataForProductionMockup,
  isOrderReviewPantonesByCustomerAllowedSelector,
  isCuratedBrandStoreOrderSelector,
} from 'redux/selectors/orders.selectors';

import { PopupListNames } from 'interfaces/popup.interface';
import { RootState } from 'redux/store';
import { ITEM_STATUS_CODE } from 'constants/item.constants';
import { can, SUBJECTS } from 'boot/ability';

interface IReturn {
  popupName: PopupListNames;
  popupProps: Record<string, unknown>;
}

interface IProps {
  action: string;
  orderId: string;
  itemId: string;
  state: RootState;
}

export const getPropsForActionPopup = ({
  orderId,
  itemId,
  action,
  state,
}: IProps): IReturn => {
  if (action === ACTION_TOKEN_TYPES.COLORS_REVIEW_BY_RESELLER) {
    const isCBSOrder = isCuratedBrandStoreOrderSelector({ orderId })(state);
    const item = getInfoForReviewColors({ orderId, itemId })(state);
    const isReviewPantonesByCustomerAllowed =
      isOrderReviewPantonesByCustomerAllowedSelector(orderId)(state);

    return {
      popupName: REVIEW_COLORS_POPUP,
      popupProps: {
        orderId,
        item,
        isReviewPantonesByCustomerAllowed,
        isCBSOrder,
      },
    };
  }

  const { orderNum, productName, productionMockup, customerMockups } =
    itemDataForProductionMockup({ itemId, orderId })(state);

  return {
    popupName: RESELLER_MOCKUP_PRE_APPROVAL,
    popupProps: {
      itemId,
      orderId,
      orderNum,
      productName,
      customerMockups,
      productionMockup,
    },
  };
};

export function shouldOpenActionPopup({
  action,
  statusCode,
}: {
  action: string | null;
  statusCode: string;
}): boolean {
  const canApprovePantones = can(
    SUBJECTS.APPROVE_PANTONES.actions.APPROVE_PANTONES,
    SUBJECTS.APPROVE_PANTONES.value,
  );

  const canApproveMockups = can(
    SUBJECTS.APPROVE_MOCKUPS.actions.APPROVE_MOCKUPS,
    SUBJECTS.APPROVE_MOCKUPS.value,
  );

  if (action === ACTION_TOKEN_TYPES.COLORS_REVIEW_BY_RESELLER && canApprovePantones) {
    return statusCode === ITEM_STATUS_CODE.COLORS_REVIEW_BY_RESELLER;
  }

  if (action === ACTION_TOKEN_TYPES.MOCKUP_APPROVAL_BY_RESELLER && canApproveMockups) {
    return statusCode === ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_RESELLER;
  }

  return false;
}

export const shouldShowSuccessNotification = ({
  action,
  statusCode,
}: {
  action: string | null;
  statusCode: string;
}): boolean => {
  return (
    (action === ACTION_TOKEN_TYPES.MOCKUP_APPROVAL_BY_RESELLER &&
      statusCode !== ITEM_STATUS_CODE.MOCKUP_APPROVAL_BY_RESELLER) ||
    (action === ACTION_TOKEN_TYPES.COLORS_REVIEW_BY_RESELLER &&
      statusCode !== ITEM_STATUS_CODE.COLORS_REVIEW_BY_RESELLER)
  );
};
