import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICart, ICartNotes } from 'interfaces/cart.interfaces';
import { IAssignedForReseller } from 'interfaces/assignee.interface';

interface ICartsState {
  carts: ICart[];
  total: number;
  myOrdersCount: {
    carts: number;
    orders: number;
    presentations: number;
    invoices: number;
    curatedBrandStores: number;
    cbsOrders: number;
  };
  cartNotes: ICartNotes;
}

const initialState: ICartsState = {
  carts: [],
  total: 0,
  myOrdersCount: {
    carts: 0,
    orders: 0,
    presentations: 0,
    invoices: 0,
    curatedBrandStores: 0,
    cbsOrders: 0,
  },
  cartNotes: {
    generalNotes: [],
    individualNotes: [],
  },
};

export const cartsSlice = createSlice({
  initialState,
  name: 'cartsSlice',
  reducers: {
    reset: () => initialState,
    setCarts: (
      state,
      action: PayloadAction<{ carts: ICartsState['carts']; total: ICartsState['total'] }>,
    ) => {
      if (!action.payload) {
        return state;
      }

      state.carts = action.payload.carts;
      state.total = action.payload.total;
    },

    setMyOrdersCount: (
      state,
      action: PayloadAction<{
        carts?: number;
        orders?: number;
        presentations?: number;
        invoices?: number;
        curatedBrandStores?: number;
        cbsOrders?: number;
      }>,
    ) => {
      if ('carts' in action.payload) {
        state.myOrdersCount.carts = action.payload.carts as number;
      }
      if ('orders' in action.payload) {
        state.myOrdersCount.orders = action.payload.orders as number;
      }
      if ('presentations' in action.payload) {
        state.myOrdersCount.presentations = action.payload.presentations as number;
      }
      if ('invoices' in action.payload) {
        state.myOrdersCount.invoices = action.payload.invoices as number;
      }
      if ('curatedBrandStores' in action.payload) {
        state.myOrdersCount.curatedBrandStores = action.payload
          .curatedBrandStores as number;
      }
      if ('cbsOrders' in action.payload) {
        state.myOrdersCount.cbsOrders = action.payload.cbsOrders as number;
      }
    },

    setCartNotes: (state, action: PayloadAction<ICartNotes>) => {
      state.cartNotes = action.payload;
    },

    unsetCartNotes: (state) => {
      state.cartNotes = initialState.cartNotes;
    },

    setAssigneeToCartById: (
      state,
      action: PayloadAction<{ entityId: string; assignee: IAssignedForReseller | null }>,
    ) => {
      const { entityId, assignee } = action.payload;

      const index = state.carts.findIndex((cart) => cart.id === entityId);

      if (index !== -1) {
        state.carts[index].assignee = assignee;
      }
    },
  },
});

export const cartsReducer = cartsSlice.reducer;
export const {
  reset,
  setCarts,
  setMyOrdersCount,
  setCartNotes,
  unsetCartNotes,
  setAssigneeToCartById,
} = cartsSlice.actions;
