import { createSelector } from 'reselect';
import { getTimeMmmDdYyyy } from 'utils/date/date-formatter.util';
import { CustomerCompanyStatisticFieldsType } from 'interfaces/customer-company.interface';
import { SUBJECTS } from 'constants/ability.constant';
import { can } from 'boot/ability';
import { mapItemIntoDetailedViewProduct } from 'utils/cart.utils';
import { Price } from 'utils/price.util';

import { RootState } from '../store';

export const customerCompanySelector = (state: RootState) => {
  return state.customerCompany;
};

export const customerCompanyOrdersSelector = createSelector(
  customerCompanySelector,
  (state) => state.orders,
);

export const customerCompanyUsersSelector = createSelector(
  customerCompanySelector,
  (state) => state.users,
);

export const customerCompanyNameSelector = createSelector(
  customerCompanySelector,
  (state) => state.name,
);

export const selectedCustomerCompanyItemSelector = (itemId?: string) =>
  createSelector(customerCompanyOrdersSelector, (orders) => {
    const selectedOrder = orders.list.find((order) => order.item.id === itemId);
    if (!selectedOrder?.item) {
      return undefined;
    }
    let sizes: string[] = [];
    if (typeof selectedOrder.item.sizes === 'string') {
      try {
        sizes = JSON.parse(selectedOrder.item.sizes);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    } else if (Array.isArray(selectedOrder.item.sizes)) {
      sizes = selectedOrder.item.sizes;
    }
    return mapItemIntoDetailedViewProduct({ ...selectedOrder.item, imageUrl: '', sizes });
  });

export const customerCompanyStatisticSelector = createSelector(
  customerCompanySelector,
  (state): CustomerCompanyStatisticFieldsType => {
    const { avgOrderValue, lastOrderAt, totalSpent, totalOrders } = state.statistic;
    const viewOrdersAccess = can(
      SUBJECTS.VIEW_ORDERS.actions.VIEW_ALL_ORDERS,
      SUBJECTS.VIEW_ORDERS.value,
    );
    if (!viewOrdersAccess) {
      return {
        totalOrders: 'N/A',
        avgOrderValue: 'N/A',
        totalSpent: 'N/A',
        lastOrderAt: 'N/A',
      };
    }
    return {
      totalOrders,
      avgOrderValue: Price.formatPrice(Number(avgOrderValue)),
      totalSpent: Price.formatPrice(Number(totalSpent)),
      lastOrderAt: lastOrderAt
        ? getTimeMmmDdYyyy(new Date(lastOrderAt), {
            day: 'numeric',
            month: 'short',
            year: '2-digit',
          })
        : 'N/A',
    };
  },
);
