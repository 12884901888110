export enum ReducerPath {
  auth = 'authApi',
  user = 'userApi',
  order = 'orderApi',
  storeSettings = 'storeSettingsApi',
  application = 'applicationApi',
  dashboard = 'dashboardApi',
  settings = 'settingsApi',
  orders = 'ordersApi',
  products = 'productsApi',
  withdraw = 'withdrawApi',
  promocodes = 'promocodesApi',
  technicalData = 'technicalDataApi',
  cart = 'cartApi',
  presentation = 'presentatioApi',
  invoice = 'invoiceApi',
  abandonedCheckoutsCart = 'abandonedCheckoutsCartsApi',
  resellerCertificates = 'resellerCertificatesApi',
  invitations = 'invitationsApi',
  referrals = 'referralsApi',
  curatedBrandSites = 'curatedBrandSitesApi',
  customerCompany = 'customerCompanyApi',
}

export type GenericResponseType = {
  status: string;
  message: string;
};

export interface IErrorResponse {
  data: {
    error: string;
    message: string[];
    statusCode: number;
  };
  status: number;
}
