import {
  Dialog,
  DialogContentWrapper,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  SwagButton,
  Typography,
} from '@ezetech/swag-space-x';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closePopup, openPopup, patchProps } from 'redux/slices/modals.slice';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { InvoiceCreationReadyPopupProps } from 'interfaces/popup.interface';
import { API_URL } from 'redux/api/helpers';
import { getPresentationByEmail } from 'redux/selectors/presentations.selector';
import { ROUTES } from 'constants/router';
import { filterEmptyProperties } from 'utils/filter-empty-properties.util';
import { usePreCreateInvoiceMutation } from 'redux/api/invoice.api';
import { BUTTONS, DESCRIPTIONS, HEADERS } from 'constants/assignment';
import {
  popupNameSelector,
  popupSharedPropsSelector,
} from 'redux/selectors/modals.selectors';
import { CONFIRMATION_POPUP } from '../_logic/popups-list';
import css from './invoice-creation-ready-popup.module.scss';

export const InvoiceCreationReadyPopup = ({
  isOpen,
  customerData,
}: InvoiceCreationReadyPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [preCreateInvoice, { isLoading }] = usePreCreateInvoiceMutation();
  const popupSharedProps = useAppSelector(popupSharedPropsSelector);
  const cartId =
    popupSharedProps.cartId ||
    useAppSelector(getPresentationByEmail(customerData?.customerEmail));

  const onCloseClick = () => {
    if (isLoading) {
      return;
    }
    dispatch(closePopup());
  };
  const currentPopupName = useAppSelector(popupNameSelector);
  const [forcedLoading, setForcedLoading] = useState(false);

  const fromPresentationPage = location.pathname === ROUTES.PDF_PRESENTATION;

  const handler = (reassign = false) => {
    dispatch(closePopup());

    const customerDetails = {
      customerEmail: customerData?.customerEmail || '',
      customerCompanyName: customerData?.companyName || '',
      customerCountry: customerData?.country || '',
      customerCity: customerData?.city || '',
      customerZip: customerData?.zip || '',
      customerState: customerData?.state || '',
      customerAddress: customerData?.address || '',
    };

    const queryParams: URLSearchParams = new URLSearchParams(
      filterEmptyProperties<string>(customerDetails),
    );

    if (fromPresentationPage) {
      queryParams.set('cartId', cartId || '');
    }

    if (reassign) {
      queryParams.set('reassign', 'true');
    }

    document.location.replace(`${API_URL}/invoice/create?${queryParams.toString()}`);
  };

  const onBtnContinueClick = async () => {
    if (isLoading) {
      return;
    }

    const result = await preCreateInvoice({
      ...filterEmptyProperties<string>({
        customerEmail: customerData?.customerEmail || '',
        customerCompanyName: customerData?.companyName || '',
        customerCountry: customerData?.country || '',
        customerCity: customerData?.city || '',
        customerZip: customerData?.zip || '',
        customerState: customerData?.state || '',
        customerAddress: customerData?.address || '',
        cartId: cartId || '',
      }),
    });
    if ('data' in result) {
      const hasReassignProposals = result.data?.length;
      const hasAssignment = result.data?.every((assignment) =>
        Boolean(assignment.currentAssignmentResellerId),
      );

      const descriptionComponent = hasAssignment
        ? DESCRIPTIONS.HAS_ASSIGNMENT
        : DESCRIPTIONS.HAS_NOT_ASSIGNMENT;
      const headerComponent = hasAssignment
        ? HEADERS.HAS_ASSIGNMENT
        : HEADERS.HAS_NOT_ASSIGNMENT;
      const buttonComponent = hasAssignment
        ? BUTTONS.HAS_ASSIGNMENT
        : BUTTONS.HAS_NOT_ASSIGNMENT;
      if (hasReassignProposals) {
        dispatch(
          openPopup({
            popupName: CONFIRMATION_POPUP,
            popupProps: {
              title: headerComponent,
              descriptionComponent,
              onXClick: () => {
                dispatch(closePopup());
              },
              onCancel: () => {
                setForcedLoading(true);
                handler();
              },
              onConfirm: () => {
                setForcedLoading(true);
                handler(true);
              },
              confirmText: buttonComponent,
              cancelText: 'Keep it as it is',
            },
          }),
        );
        return;
      }
    }

    handler();
  };

  useEffect(() => {
    if (currentPopupName === CONFIRMATION_POPUP) {
      const loadingInProcess = isLoading || forcedLoading;
      dispatch(
        patchProps({
          isPopupLoading: loadingInProcess,
          onXClick: () => !loadingInProcess && dispatch(closePopup()),
        }),
      );
    }
  }, [isLoading, forcedLoading]);

  return (
    <Dialog isLoading={isLoading} open={isOpen} onOpenChange={onCloseClick}>
      <DialogContentWrapper
        className={css.dialogContentWrapperClassName}
        overlayClassName={css.dialogOverlayClassName}
      >
        <DialogTitle closeIconClassName={css.closeIconClassName} className={css.title}>
          Ready to go!
        </DialogTitle>
        <DialogDescription className={css.textWrapper}>
          <Typography color={COLORS.colorTextSubdued} fontType="bodyMd">
            Amazing! Press continue and you will be able to browse our full catalog of
            products, find what you are looking for, add all the details (logos, color and
            quantity), and be able to easily create an invoice for{' '}
            {customerData?.customerEmail}. You will then be able to find this invoice on
            your Invoices tab.
          </Typography>
        </DialogDescription>

        <DialogFooter className={css.footer}>
          <SwagButton type="outlined" onClick={onCloseClick} className={css.btn}>
            Go Back
          </SwagButton>
          <SwagButton type="primary" onClick={onBtnContinueClick} className={css.btn}>
            Continue
          </SwagButton>
        </DialogFooter>
      </DialogContentWrapper>
    </Dialog>
  );
};
