import cn from 'classnames';
import { Tooltip, Typography, Image } from '@ezetech/swag-space-x';
import { ICartProductDetailsVariant } from 'interfaces/cart.interfaces';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import { ProductListVariants } from 'components/products-list-tooltip';
import css from './cart-products-preview-popup.module.scss';

interface IProps {
  id: string;
  name: string;
  fullName?: string;
  quantity: number;
  variants: ICartProductDetailsVariant[];
  showVariants: boolean;
  imageUrl?: string | null;
}

export const ProductPreviewItem = ({
  name,
  quantity,
  variants,
  showVariants,
  imageUrl,
  fullName,
}: IProps) => {
  return (
    <div className={cn(css.block, { [css.blockWithImage]: !!imageUrl })}>
      <div className={css.column}>
        {imageUrl && <Image wrapperClassName={css.image} src={imageUrl} alt={name} />}
        <Tooltip
          disabled={!name.includes('...')}
          content={
            <Typography
              fontType="bodyMdBold"
              lineHeight="162.5%"
              color={COLORS.colorTypographyHeadings}
              className={cn(css.title, {
                [css.titleWithImage]: !!imageUrl,
                [css.pointer]: name.includes('...'),
              })}
            >
              {name}
            </Typography>
          }
        >
          <Typography fontType="bodySm" lineHeight="143%" color={COLORS.colorBlack}>
            {fullName}
          </Typography>
        </Tooltip>
        {!!quantity && (
          <Typography
            fontType="bodySm"
            lineHeight="143%"
            color={COLORS.colorTypographyHeadings}
          >
            Quantity: {quantity}
          </Typography>
        )}
      </div>
      {showVariants && variants.length > 0 && (
        <Tooltip
          content={
            <Typography fontType="bodySm" lineHeight="143%" color={COLORS.colorPink} link>
              See breakdown
            </Typography>
          }
        >
          <ProductListVariants name={name} variants={variants} />
        </Tooltip>
      )}
    </div>
  );
};
