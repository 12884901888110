import {
  ColorsSelector,
  ImageCropTools,
  ImageUpload,
  Typography,
  useImageCropTools,
} from '@ezetech/swag-space-x';
import { useEffect } from 'react';
import COLORS from 'constants/styles/colors-js.constant.module.scss';
import css from 'components/popups/upload-cbs-logo/upload-cbs-logo.popup.module.scss';
import { useUploadLogoMutation } from 'redux/api/curated-brand-sites.api';

interface IProps {
  logoUrl: string;
  primaryColor: string;
  secondaryColor: string;
  setValue: (name: string, value: string) => void;
}

export function LogoUploadForm({
  logoUrl,
  primaryColor,
  secondaryColor,
  setValue,
}: IProps) {
  const [uploadLogo] = useUploadLogoMutation();

  useEffect(() => {
    if (!logoUrl) {
      return;
    }
    imageCropSetSrc(logoUrl);
  }, [logoUrl]);

  const {
    toolsOnSaveHandler,
    toolsOnCloseHandler,
    toolsOnScaleHandler,
    toolsOnRevertHandler,

    imageCropOnCroppedHandler,
    imageCropOnLoadHandler,
    imageCropSetSrc,

    setIsVisibleImageCrop,
    onSaved,

    fitScale,
    scale,
    blobCropped,
    isShowImageCrop,
    src,
    isSaving,
  } = useImageCropTools(logoUrl);

  const handleUpload = (files: File[]): void => {
    if (!files.length) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const formData = new FormData();
      formData.set('file', files[0]);

      uploadLogo(formData);
    };
    fileReader.readAsDataURL(files[0]);
  };

  const toolsOnSave = async () => {
    if (blobCropped) {
      toolsOnSaveHandler();
      const formData = new FormData();
      formData.set('file', blobCropped);
      await uploadLogo(formData);
      onSaved();
      toolsOnCloseHandler();
    }
  };

  const toolsOnRevert = (newSrc: string) => {
    toolsOnRevertHandler(newSrc);
  };

  return (
    <>
      <Typography color={COLORS.colorTextSubdued} fontType="bodyMd" lineHeight="162.5%">
        Drag and drop your customers logo and add select their colors.
      </Typography>

      <ImageUpload
        wrapperClassName={css.imageUpload}
        previewClassName={css.previewImage}
        url={src}
        onUpload={handleUpload}
        buttonText={logoUrl ? 'Re-Upload' : 'Select File'}
        additionalText="JPG, PNG or JPEG, no more than 1MB"
        buttonClassName={css.uploadBtn}
        controlClassName={css.uploadControl}
        imageCropProps={{
          src: src || '',
          onLoad: imageCropOnLoadHandler,
          onImageCropped: imageCropOnCroppedHandler,
          scale,
        }}
        withImageCrop={true}
        isShowImageCrop={isShowImageCrop}
        onShowImageCrop={setIsVisibleImageCrop}
      />
      {isShowImageCrop && (
        <ImageCropTools
          wrapperClassName={css.imageUploadTools}
          src={src || ''}
          scale={scale}
          onRevert={toolsOnRevert}
          onScale={toolsOnScaleHandler}
          fitScale={fitScale}
          onSave={toolsOnSave}
          onClose={toolsOnCloseHandler}
          wrapInWhiteBox
          maxScalePercent={200}
          isSaving={isSaving}
        />
      )}

      {!isShowImageCrop && (
        <ColorsSelector
          primaryColorPickerWrapperClassName={css.primaryColorPickerWrapperClassName}
          secondaryColorPickerWrapperClassName={css.secondaryColorPickerWrapperClassName}
          className={css.colors}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          primaryColorLabel="Select your primary color"
          secondaryColorLabel="Select your secondary color"
          onPrimaryChange={(color) => setValue('primaryColor', color)}
          onSecondaryChange={(color) => setValue('secondaryColor', color)}
        />
      )}
    </>
  );
}
