export const pluralize = (
  count: number,
  word: string,
  suffix: string = 's',
  withoutNumber = false,
): string => `${withoutNumber ? '' : count} ${word}${count !== 1 ? suffix : ''}`.trim();

export const capitalize = (str: string | null = ''): string => {
  return (str || '')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const truncateString = (string: string, maxLength: number): string => {
  if (string.length > maxLength) {
    return string.slice(0, maxLength) + '...';
  }

  return string;
};
