export enum RestrictionLevel {
  PUBLIC = 'PUBLIC',
  RESTRICTED = 'RESTRICTED',
}

export enum CuratedBrandStoreStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
}

export enum CBSType {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}
